/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
import { A3dScene } from '@a3d-viewer/scene';
import '@a3d-ui/button';
import { PointerModes } from '@a3d-viewer/renderer-types';
let A3dToolbar = class A3dToolbar extends LitElement {
    constructor() {
        super(...arguments);
        this.selection = this.getA3dScene()?.pointerMode || PointerModes.ORBIT;
    }
    isA3dScene(object) {
        return object && object instanceof A3dScene;
    }
    getA3dScene() {
        const element = this.parentElement?.querySelector('a3d-scene');
        return this.isA3dScene(element) ? element : null;
    }
    buttonTemplate(innerHTML, value, selected) {
        const handleClick = () => {
            this.selection = value;
            const a3dScene = this.getA3dScene();
            if (a3dScene)
                a3dScene.pointerMode = value;
        };
        return html `<a3d-button
      value=${value}
      @click=${handleClick}
      ?selected=${selected}
    >
      ${innerHTML}
    </a3d-button>`;
    }
    render() {
        const orbitIcon = html `<svg
      slot="icon"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <path
        d="M13.8785,6.9355A3.9915,3.9915,0,0,0,6.35,4.374c-.331-.0255-.659-.045-.974-.045C2.6525,4.329.5785,5.2745.1,7c-.52,1.8845,1.019,4.186,3.678,5.973L2.3545,14.6805A.1905.1905,0,0,0,2.5,14.993H8.95L6.045,10.635a.191.191,0,0,0-.305-.0165L4.7555,11.8c-2.25-1.471-3.5-3.25-3.1855-4.3935.261-.944,1.756-1.554,3.8085-1.554.2055,0,.421.018.633.0305C6.0115,5.923,6,5.96,6,6a3.9925,3.9925,0,0,0,7.2385,2.332c2.2,1.4605,3.4045,3.214,3.091,4.345-.2605.944-1.7555,1.554-3.807,1.554-.283,0-.5745-.0155-.87-.041a.3805.3805,0,0,0-.41571.34168l-.00179.03382v.766a.386.386,0,0,0,.353.3835c.3185.025.631.0395.9345.0395,2.725,0,4.8-.9455,5.276-2.671C18.3365,11.1395,16.694,8.7445,13.8785,6.9355Z"
      />
    </svg>`;
        const moveIcon = html `<svg
      slot="icon"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <path
        d="M17,9a.25.25,0,0,0-.0565-.158L16,8.0145V8h-.0165L14.927,7.0735A.245.245,0,0,0,14.75,7a.25.25,0,0,0-.25.25V8H10V3.5h.75A.25.25,0,0,0,11,3.25a.24448.24448,0,0,0-.0735-.175L10,2.0165V2H9.9855L9.158,1.0565a.25.25,0,0,0-.316,0L8.0145,2H8v.0165L7.0735,3.073A.24449.24449,0,0,0,7,3.25a.25.25,0,0,0,.25.25H8V8H3.5V7.25A.25.25,0,0,0,3.25,7a.245.245,0,0,0-.175.0735L2.0165,8H2v.0145l-.9435.8275a.25.25,0,0,0,0,.316L2,9.9855V10h.0165l1.0565.926A.24552.24552,0,0,0,3.25,11a.25.25,0,0,0,.25-.25V10H8v4.5H7.25a.25.25,0,0,0-.25.25.24352.24352,0,0,0,.0735.175L8,15.9835V16h.0145l.8275.9435a.25.25,0,0,0,.316,0L9.9855,16H10v-.0165l.9265-1.057A.24349.24349,0,0,0,11,14.75a.25.25,0,0,0-.25-.25H10V10h4.5v.75a.25.25,0,0,0,.25.25.24549.24549,0,0,0,.175-.074L15.9835,10H16V9.9855l.9435-.8275A.25.25,0,0,0,17,9Z"
      />
    </svg>`;
        const dollyIcon = html `<svg
      slot="icon"
      xmlns="http://www.w3.org/2000/svg"
      height="18"
      viewBox="0 0 18 18"
      width="18"
    >
      <path
        d="M15.4205,12H12L10.182,4h2.792a.1875.1875,0,0,0,.1185-.333L9,.325,4.9075,3.667A.1875.1875,0,0,0,5.026,4H7.818L6,12H2.5795a.375.375,0,0,0-.2325.669L9,17.925l6.653-5.256A.375.375,0,0,0,15.4205,12Z"
      />
    </svg>`;
        const buttons = [
            this.buttonTemplate(orbitIcon, PointerModes.ORBIT, this.selection === PointerModes.ORBIT),
            this.buttonTemplate(moveIcon, PointerModes.PAN, this.selection === PointerModes.PAN),
            this.buttonTemplate(dollyIcon, PointerModes.ZOOM, this.selection === PointerModes.ZOOM),
        ];
        return html `<div class="toolbar">
      <slot name="left"></slot>
      ${buttons.map((button) => button)}
      <slot name="right"></slot>
    </div>`;
    }
    connectedCallback() {
        super.connectedCallback();
        this.slot = 'toolbar';
    }
};
A3dToolbar.styles = css `
    :host {
      position: absolute;
      left: 50%;
      bottom: 10px;
      transform: translate(-50%, 0);
    }

    .toolbar {
      background: var(--spectrum-alias-background-color-default);
      padding: 0.5em 1rem;
      border-radius: 16px;
      box-shadow: 0px 4px 16px 0px #00000014;
      display: flex;
      gap: 13px;
    }
  `;
__decorate([
    state()
], A3dToolbar.prototype, "selection", void 0);
A3dToolbar = __decorate([
    customElement('a3d-toolbar')
], A3dToolbar);
export { A3dToolbar };
