/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Camera, Vector3 } from '@babylonjs/core';
import { isUserMesh } from '../resources';
/**
 * Get the camera target from the closest mesh in the scene
 * Copied and edited from https://git.corp.adobe.com/3di/web-viewer
 * @param camera the camera to get the direction from
 * @param scene the scene to get the closest mesh from
 * @returns the target of the camera
 */
export function getCameraTargetFromClosestMesh(camera, scene, rayMeshSelector = isUserMesh) {
    const ray = camera.getForwardRay(50, camera.getWorldMatrix(), camera.globalPosition);
    const closestPoint = new Vector3();
    let closestPointDistanceAlongLine = 0;
    let extent;
    const pickResult = scene.pickWithRay(ray, rayMeshSelector, false);
    if (pickResult?.hit && pickResult.pickedPoint) {
        closestPoint.copyFrom(pickResult.pickedPoint);
        const distance = closestPoint.subtract(camera.globalPosition).length();
        if (pickResult.pickedMesh) {
            const bounds = pickResult.pickedMesh.getHierarchyBoundingVectors(true);
            const size = new Vector3();
            bounds.max.subtractToRef(bounds.min, size);
            extent = size.length() * 4;
            const fovSize = Math.tan(camera.fov / 2.0) * distance;
            extent = Math.max(fovSize, extent);
            closestPoint.copyFrom(pickResult.pickedPoint);
            closestPointDistanceAlongLine = distance;
        }
    }
    else {
        const direction = ray.direction.clone();
        let closestDistanceSquared = Number.MAX_VALUE;
        const cameraPosition = camera.globalPosition;
        const workVector = new Vector3();
        const workPoint = cameraPosition.clone();
        direction.scaleAndAddToRef(0.1, workPoint);
        const roots = scene.meshes.filter(rayMeshSelector);
        roots.forEach((mesh) => {
            const children = mesh.getChildren((node) => node instanceof Camera);
            if (children.length === 1) {
                return;
            }
            const meshPosition = mesh.getAbsolutePosition();
            meshPosition.subtractToRef(cameraPosition, workVector);
            let distance = Vector3.Dot(workVector, direction);
            if (distance < 0.01) {
                return;
            }
            direction.scaleToRef(distance, workPoint);
            workPoint.addInPlace(cameraPosition);
            workPoint.subtractToRef(meshPosition, workVector);
            const distanceSquared = workVector.lengthSquared();
            if (distanceSquared < closestDistanceSquared) {
                closestDistanceSquared = distanceSquared;
                closestPoint.copyFrom(workPoint);
                closestPointDistanceAlongLine = distance;
                extent = Math.max(3, closestDistanceSquared * 4);
            }
        });
    }
    return {
        target: closestPoint,
        targetDistance: closestPointDistanceAlongLine,
    };
}
