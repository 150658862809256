/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { A3dElement, Group } from '@a3d-viewer/core';
import { PointerModes, RenderLevel } from '@a3d-viewer/renderer-types';
let A3dScene = class A3dScene extends A3dElement {
    constructor() {
        super(...arguments);
        /** @internal */
        this.group = Group.SCENES;
        /** @internal */
        this._renderLevel = RenderLevel.QUALITY;
        /**
         * Background color of the scene.
         * @attr
         */
        this.color = '#ffffff';
        /**
         * Show a grid on the ground.
         * Helpful for composting the scene.
         */
        this.grid = false;
        this.depthMap = false;
        this.antialiasing = false;
        /**
         * The pointer mode of the camera.
         */
        this.pointerMode = PointerModes.ORBIT;
    }
    /**
     * The render level of the scene.
     * Value can be either 'quality', 'average' or 'performance'.
     * @attr
     */
    set renderLevel(val) {
        if ([
            RenderLevel.QUALITY,
            RenderLevel.AVERAGE,
            RenderLevel.PERFORMANCE,
        ].indexOf(val) === -1) {
            console.warn(`Invalid value for render-level. Defaulting to "${RenderLevel.QUALITY}"`);
            this._renderLevel = RenderLevel.QUALITY;
        }
        else
            this._renderLevel = val;
    }
    get renderLevel() {
        return this._renderLevel;
    }
    /** @internal */
    exposedData() {
        return {
            color: this.color,
            shadows: this.shadows,
            grid: this.grid,
            depthMap: this.depthMap,
            antialiasing: this.antialiasing,
            renderLevel: this._renderLevel,
            ...(this.pointerMode !== null && { pointerMode: this.pointerMode }),
        };
    }
    render() {
        return html `<slot></slot>`;
    }
};
A3dScene.styles = css `
    :host {
      position: absolute;
      top: 0;
      left: 0;
    }
  `;
__decorate([
    property({ type: String })
], A3dScene.prototype, "color", void 0);
__decorate([
    property({ type: String })
], A3dScene.prototype, "shadows", void 0);
__decorate([
    property({ type: Boolean })
], A3dScene.prototype, "grid", void 0);
__decorate([
    property({ type: Boolean, attribute: 'depth-map' })
], A3dScene.prototype, "depthMap", void 0);
__decorate([
    property({ type: Boolean })
], A3dScene.prototype, "antialiasing", void 0);
__decorate([
    property({
        type: String,
        attribute: 'pointer-mode',
        reflect: true,
    })
], A3dScene.prototype, "pointerMode", void 0);
__decorate([
    property({
        type: String,
        attribute: 'render-level',
    })
], A3dScene.prototype, "renderLevel", null);
A3dScene = __decorate([
    customElement('a3d-scene')
], A3dScene);
export { A3dScene };
