/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import _find from 'lodash/find';
import { ArcRotateCamera } from '@babylonjs/core';
import { getCameraTargetFromClosestMesh } from './cameraTargetHeuristic';
export function getRootPositionFromCamera(camera) {
    const worldMatrix = camera.getWorldMatrix().clone();
    const globalPosition = worldMatrix.getTranslation();
    return globalPosition;
}
export function generateUniqueCameraIds(cameras, uniqueIdGenerator = generateUniqueId) {
    const sceneCameras = [];
    if (!cameras) {
        return;
    }
    for (let i = 0; i < cameras.length; i++) {
        const camera = cameras[i];
        if (!camera) {
            continue;
        }
        const cameraId = camera.uniqueId;
        const name = camera.name;
        const parentName = camera.parent?.id;
        const meshId = camera.parent?.parent?.metadata.id;
        if (!meshId || !parentName) {
            continue;
        }
        const uniqueId = uniqueIdGenerator(meshId, parentName);
        sceneCameras.push({
            id: cameraId,
            uniqueId,
            parentName,
            name,
            type: camera instanceof ArcRotateCamera ? 'ArcRotateCamera' : 'FreeCamera',
        });
    }
    return sceneCameras;
}
/**
 * Generate a unique id for a camera
 * @param meshId
 * @param cameraName
 * @returns the unique id
 */
export function generateUniqueId(meshId, cameraName) {
    return meshId + '.' + cameraName;
}
/**
 * Find a camera by name and meshId
 * @param cameras
 * @param cameraName
 * @param meshId
 * @param uniqueIdGenerator
 * @returns the camera
 */
export function findCameraByNameAndMeshId(cameras, cameraName, meshId, uniqueIdGenerator = generateUniqueId) {
    const camera = _find(cameras, (c) => {
        if (meshId) {
            const uniqueId = uniqueIdGenerator(meshId, cameraName);
            if (uniqueId === c.id) {
                return true;
            }
        }
        else {
            if (cameraName === c.parent?.id) {
                return true;
            }
        }
        return false;
    });
    return camera;
}
/**
 * Find a camera by name or id
 */
export function findCameraByNameOrParentName(cameras, name) {
    return cameras.find((camera) => {
        if (camera.parent) {
            return camera.parent.id === name || camera.id === name;
        }
        return camera.id === name;
    });
}
/**
 * Create a new ArcRotateCamera from a fixed non controlable TargetCamera
 */
export function newRotateCameraFromTargetCamera(targetCamera, camera) {
    if (!targetCamera || !camera) {
        return undefined;
    }
    const globalPosition = getRootPositionFromCamera(targetCamera);
    const computedCameraTarget = getCameraTargetFromClosestMesh(targetCamera, targetCamera.getScene());
    camera.target = computedCameraTarget.target;
    camera.position = globalPosition;
    camera.fov = targetCamera.fov;
    return camera;
}
export function cloneRotateCamera(targetCamera, camera) {
    const globalPosition = getRootPositionFromCamera(targetCamera);
    camera.target = targetCamera.target;
    camera.position = globalPosition;
    camera.fov = targetCamera.fov;
    return camera;
}
