/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import classNames from 'classnames'
import { HTMLAttributes } from 'react'

type RenderCardProps = {
  url: string
}

function RenderCard({
  url,
  className,
}: RenderCardProps & HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={classNames(
        'border border-solid border-gray-300 rounded-sm',
        className
      )}
    >
      <a key={url} href={url} target="_blank">
        <img src={url} width={'100%'} />
      </a>
      <div className="border-0 border-t border-solid border-gray-300">
        success
      </div>
    </div>
  )
}

export default RenderCard
