/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
  Heading,
  Item,
  Key,
  Picker,
  Switch,
  Text,
  ToggleButton,
} from '@adobe/react-spectrum'
import { useAtom } from 'jotai'
import _map from 'lodash/map'

import { MeshInputAnimationStatus } from '@a3d-viewer/renderer-types'
import { FormItem } from '../components/FormItem'
import { viewerAtom } from '../config'

import Pause from '@spectrum-icons/workflow/Pause'
import Play from '@spectrum-icons/workflow/Play'

function AnimationsPanel() {
  const [atom, setAtom] = useAtom(viewerAtom)

  const handleAnimationSelected = (key: Key) => {
    const animation = key as string
    setAtom({
      ...atom,
      mesh: {
        ...atom.mesh,
        selectedAnimation: animation,
      },
    })
  }

  const handleStatusUpdate = (value: MeshInputAnimationStatus) => {
    setAtom({
      ...atom,
      mesh: {
        ...atom.mesh,
        animationStatus: value,
      },
    })
  }

  const handleDisabledAnimationsChange = (value: boolean) => {
    setAtom({
      ...atom,
      mesh: {
        ...atom.mesh,
        disabledAnimations: value,
      },
    })
  }

  return (
    <>
      <Heading UNSAFE_className="my-0">Animations</Heading>
      <FormItem>
        <Switch
          isSelected={atom.mesh.disabledAnimations}
          onChange={handleDisabledAnimationsChange}
        >
          Disable animations
        </Switch>
      </FormItem>
      <FormItem>
        <Picker
          label="Choose an animation"
          onSelectionChange={handleAnimationSelected}
          selectedKey={atom.mesh.selectedAnimation}
          width={'100%'}
        >
          {_map(atom.mesh.animations, (animation) => (
            <Item key={animation}>{animation}</Item>
          ))}
        </Picker>
      </FormItem>
      <FormItem>
        <ToggleButton
          isSelected={
            atom.mesh.animationStatus === MeshInputAnimationStatus.PLAY
          }
          onPress={() => handleStatusUpdate(MeshInputAnimationStatus.PLAY)}
          aria-label="Play"
        >
          <Play />
          <Text>Play</Text>
        </ToggleButton>
        <ToggleButton
          isSelected={
            atom.mesh.animationStatus === MeshInputAnimationStatus.PAUSE
          }
          onPress={() => handleStatusUpdate(MeshInputAnimationStatus.PAUSE)}
          aria-label="Pause"
        >
          <Pause />
          <Text>Pause</Text>
        </ToggleButton>
      </FormItem>
    </>
  )
}

export { AnimationsPanel }
