/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import { A3dElement, Group, HotspotEvents } from '@a3d-viewer/core';
import './a3d-hotspot-ui';
let A3dHotspot = class A3dHotspot extends A3dElement {
    constructor() {
        super(...arguments);
        /** @internal */
        this.group = Group.HOTSPOT;
        /**
         * The position of this hotspot.
         */
        this.position = null;
        /**
         * The surface info of this hotspot.
         */
        this.surface = null;
        /** @internal */
        this.detail = null;
    }
    /** @internal */
    exposedData() {
        const position = this.position
            ? this.position.split(' ').map((p) => parseFloat(p))
            : null;
        const surface = this.surface ? this.surface.split(' ') : null;
        return {
            ...(this.position !== null && { position }),
            ...(this.surface !== null && { surface }),
            ...(this.detail !== null && { detail: this.detail }),
        };
    }
    setVisibility(visible) {
        const firstChild = this.firstChild;
        visible
            ? firstChild?.removeAttribute('hidden')
            : firstChild?.setAttribute('hidden', '');
    }
    /** @internal */
    handleChange(event) {
        event.stopPropagation();
        this.detail = event.detail;
    }
    deleteContainer(e) {
        e.stopImmediatePropagation();
        this.dispatchEvent(new CustomEvent(HotspotEvents.HOTSPOT_DELETE_CONTAINER, {
            bubbles: true,
            composed: true,
            detail: { id: this.id, target: this },
        }));
    }
    firstUpdated() {
        this.addEventListener(HotspotEvents.HOTSPOT_STATE_CHANGE, this.handleChange);
        this.addEventListener(HotspotEvents.HOTSPOT_DELETE, this.deleteContainer);
    }
    disconnectedCallback() {
        this.removeEventListener(HotspotEvents.HOTSPOT_STATE_CHANGE, this.handleChange);
        this.removeEventListener(HotspotEvents.HOTSPOT_DELETE_CONTAINER, this.deleteContainer);
        super.disconnectedCallback();
    }
    render() {
        return html `<slot></slot>`;
    }
};
A3dHotspot.styles = css `
    :host {
      width: 1px;
      height: 1px;
      position: absolute;
      overflow: visible;
    }
  `;
__decorate([
    property({ type: String, reflect: true })
], A3dHotspot.prototype, "position", void 0);
__decorate([
    property({ type: String, reflect: true })
], A3dHotspot.prototype, "surface", void 0);
__decorate([
    state()
], A3dHotspot.prototype, "detail", void 0);
A3dHotspot = __decorate([
    customElement('a3d-hotspot')
], A3dHotspot);
export { A3dHotspot };
