/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, PostEffect } from '@a3d-viewer/renderer-types';
import { SSAO2RenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/ssao2RenderingPipeline';
class SSAOPost extends PostEffect {
    constructor(scene) {
        super();
        this.type = POST_EFFECTS.SSAO;
        this.scene = scene;
        this.SSAO2 = new SSAO2RenderingPipeline('ssao2', this.scene, 1, [
            this.scene.activeCamera,
        ]);
        this.process();
    }
    suspend() { }
    process() {
        this.SSAO2.samples = 32;
        this.SSAO2.minZAspect = 0.13;
        this.SSAO2.radius = 0.15;
        this.SSAO2.totalStrength = 1.4;
    }
    dispose() {
        console.debug('SSAO dispose');
        this.SSAO2.dispose();
    }
}
export default SSAOPost;
