/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Heading, Switch } from '@adobe/react-spectrum'
import { useAtom } from 'jotai'
import { FormItem } from '../components/FormItem'
import { viewerAtom } from '../config'

function SettingsPanel() {
  const [atom, setAtom] = useAtom(viewerAtom)

  const setToolbarEnable = (value: boolean) => {
    setAtom({ ...atom, toolbar: { ...atom.toolbar, enable: value } })
  }

  const setInfoPanelEnable = (value: boolean) => {
    setAtom({ ...atom, info: { ...atom.info, enable: value } })
  }

  const setDepthPass = (value: boolean) => {
    setAtom({ ...atom, passes: { ...atom.passes, depth: value } })
  }

  const setAntialiasing = (value: boolean) => {
    setAtom({ ...atom, passes: { ...atom.passes, antialiasing: value } })
  }

  return (
    <>
      <Heading level={1}>Viewer Settings</Heading>
      <FormItem>
        <Switch isSelected={atom.toolbar.enable} onChange={setToolbarEnable}>
          Show toolbar
        </Switch>
      </FormItem>
      <FormItem>
        <Switch isSelected={atom.info.enable} onChange={setInfoPanelEnable}>
          Show info panel
        </Switch>
      </FormItem>
      <FormItem>
        <Switch
          isSelected={atom.passes.antialiasing}
          onChange={setAntialiasing}
        >
          Antialiasing
        </Switch>
      </FormItem>
      <FormItem>
        <Switch isSelected={atom.passes.depth} onChange={setDepthPass}>
          Depth map
        </Switch>
      </FormItem>
    </>
  )
}

export { SettingsPanel }
