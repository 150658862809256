/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { defaultTheme, Provider } from '@adobe/react-spectrum'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'

import '@a3d-ui/button'
import '@a3d-ui/info'
import '@a3d-ui/separator'
import '@a3d-ui/toolbar'
import '@a3d-viewer/assets'
import '@a3d-viewer/camera'
import '@a3d-viewer/core'
import '@a3d-viewer/environment'
import '@a3d-viewer/hotspot'
import '@a3d-viewer/light'
import '@a3d-viewer/loading-screen'
import '@a3d-viewer/mesh'
import { A3dMesh } from '@a3d-viewer/mesh'
import '@a3d-viewer/node'
import '@a3d-viewer/scene'
import '@a3d-viewer/transform'
import '@a3d-viewer/viewer'
import { A3dViewer } from '@a3d-viewer/viewer'

import { SHADOWS, ViewerEvents } from '@a3d-viewer/core'
import { MeshType } from '../App'
import DropZone from '../components/DropZone'
import { Theme, themeAtom, viewerAtom } from '../config'
import { getGlobalMeshTransformMatrix } from '@a3d-lib/node-transform'
import { matrixTransform } from '@a3d-lib/camera-transform'

function Render() {
  const [viewer] = useAtom(viewerAtom)
  const [theme] = useAtom(themeAtom)
  const [mesh, setMesh] = useState<MeshType | null>(null)
  const meshRef = useRef<A3dMesh>(null)
  const viewerRef = useRef<A3dViewer>(null)
  const [_, setState] = useState<string | undefined>()

  useEffect(() => {
    if (
      theme === Theme.dark ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      localStorage.theme = Theme.dark
      document.documentElement.classList.add('dark')
    } else {
      localStorage.theme = Theme.light
      document.documentElement.classList.remove('dark')
    }
  }, [theme])

  const modelUrl = useMemo(() => {
    const search = window.location.search
    const queryParams = new URLSearchParams(search)
    return queryParams.get('modelUrl')
  }, [window.location.search])

  const viewerUpdated = async (e: Event) => {
    const { detail } = e as CustomEvent

    const json = JSON.stringify(detail, null, 2)

    setState(json)

    const globalMatrix = getGlobalMeshTransformMatrix(detail, 'render-id')
    console.debug('Nodes computed matrix', globalMatrix)
    const transform = matrixTransform(detail.camera.matrix)
    console.debug('Camera matrix', transform.matrix)
  }

  useEffect(() => {
    if (modelUrl) {
      setMesh({ fileAsDataUrl: modelUrl })
    }
  }, [modelUrl])

  useEffect(() => {
    if (!mesh || !viewerRef.current) return undefined
    const viewerEle = viewerRef.current

    viewerEle.addEventListener(ViewerEvents.VIEWER_UPDATED, viewerUpdated)

    return () => {
      viewerEle.removeEventListener(ViewerEvents.VIEWER_UPDATED, viewerUpdated)
    }
  }, [mesh, viewerRef.current])

  const handleDrop = ({ fileAsDataUrl, extension, file }: MeshType) => {
    setMesh({ file, fileAsDataUrl, extension })
  }

  return (
    <Provider
      theme={defaultTheme}
      colorScheme={theme}
      id="provider"
      UNSAFE_className={`flex flex-1 flex-col ${theme === 'light' ? 'bg-white' : 'bg-black'}`}
      scale="medium"
    >
      <DropZone onDrop={handleDrop} showDraggingText={mesh === null}>
        {mesh && (
          <a3d-viewer className="h-full" ref={viewerRef}>
            <a3d-scene
              id="test"
              color={'#ffffff'}
              shadows={SHADOWS.PERFORMANCE}
            >
              <a3d-camera-perspective
                yFov={1}
                limit-disabled="true"
                active
              ></a3d-camera-perspective>
              <a3d-environment src={viewer.envSrc}></a3d-environment>
              <a3d-node>
                <a3d-transform
                  position={`0 0 0`}
                  rotation={`1.2 0 0`}
                ></a3d-transform>
                <a3d-mesh
                  id={'render-id'}
                  ref={meshRef}
                  src={mesh?.fileAsDataUrl}
                  loader={mesh?.extension}
                ></a3d-mesh>
              </a3d-node>
            </a3d-scene>
          </a3d-viewer>
        )}
      </DropZone>
    </Provider>
  )
}

export default Render
