/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, PostEffect } from '@a3d-viewer/renderer-types';
import { ShadowGenerator } from '@babylonjs/core/Lights/Shadows/shadowGenerator';
// required as side effect to register the component
import '@babylonjs/core/Lights/Shadows/shadowGeneratorSceneComponent';
import { SpotLight } from '@babylonjs/core/Lights/spotLight';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { ShadowOnlyMaterial } from '@babylonjs/materials';
class ShadowsPost extends PostEffect {
    constructor(scene) {
        super();
        this.type = POST_EFFECTS.SHADOWS;
        this.scene = scene;
        this.light = new SpotLight('spotLight', new Vector3(0, 7, 10), new Vector3(0, -1, -1), Math.PI / 5, 30, this.scene);
        this.generator = new ShadowGenerator(2048, this.light);
        this.scene.meshes.forEach((mesh) => {
            this.generator.addShadowCaster(mesh, true);
            mesh.receiveShadows = true;
        });
        this.shadowGround = MeshBuilder.CreatePlane('ground', { size: 1000 }, this.scene);
        // Improve performance by only rendering the shadow map once
        // RenderTargetTexture.REFRESHRATE_RENDER_ONEVERYFRAME
        // RenderTargetTexture.REFRESHRATE_RENDER_ONCE
        const shadowMap = this.generator.getShadowMap();
        if (shadowMap) {
            // Once every 4 frames
            shadowMap.refreshRate = 4;
        }
        const shadowMaterial = new ShadowOnlyMaterial('mat', this.scene);
        shadowMaterial.activeLight = this.light;
        this.shadowGround.rotation.x = Math.PI / 2;
        this.shadowGround.receiveShadows = true;
        this.shadowGround.material = shadowMaterial;
        this.process();
    }
    suspend() { }
    process() {
        // Activate to have a light gizmo
        // var lightPointGizmo = new LightGizmo()
        // lightPointGizmo.light = lightShadowBlur
        this.generator.setDarkness(0.95);
        this.generator.setTransparencyShadow(true);
        this.generator.usePercentageCloserFiltering = true;
        this.generator.filteringQuality = ShadowGenerator.QUALITY_MEDIUM;
        this.generator.bias = -0.00001;
        this.generator.normalBias = 0.0001;
        this.generator.useContactHardeningShadow = false;
        // this.generator.contactHardeningLightSizeUVRatio = 0.1
        this.generator.useExponentialShadowMap = false;
        this.generator.useBlurExponentialShadowMap = false;
        this.generator.useKernelBlur = false;
        // this.generator.blurScale = 0.5
        // this.generator.blurKernel = 10
        console.log('ShadowsPost processed');
    }
    dispose() {
        console.debug('Dispose shadows');
        this.generator.dispose();
        this.scene.removeMesh(this.shadowGround);
    }
}
export default ShadowsPost;
