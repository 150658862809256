/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { MeshLoader, WARN_RESOURCE_NOT_INITIALIZED, MeshInputAnimationStatus, } from '@a3d-viewer/renderer-types';
import '@babylonjs/core/Animations/animatable';
import { SceneLoader, } from '@babylonjs/core/Loading/sceneLoader';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { BoundingInfo } from '@babylonjs/core/Culling/boundingInfo';
import _each from 'lodash/each';
import { meshHasAnimations, isTransformNode } from '../../util';
import { BabylonResource } from '../BabylonResource';
import { MeshLoadingError, MeshLoadingErrorMessages } from '@a3d-viewer/core';
import { getTransformFromNode } from '../../util/trs';
export const USER_MESH_TAG = 'user-mesh';
const isUserMesh = (mesh) => {
    return mesh.metadata && mesh.metadata.type === USER_MESH_TAG;
};
/**
 * Mesh resource class for Babylon resolver
 */
class MeshResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
        /** The loaded meshes */
        this.resource = null;
        this.hasAnimations = false;
        this.triangleCount = 0;
        this.verticesCount = 0;
        this.physicalSize = [0, 0, 0];
        /**
         * Animation groups for the mesh
         */
        this.animationGroups = [];
        this.node = new TransformNode('transform-node' + this.id, this.resolver.scene);
    }
    disposeMesh() {
        const meshes = this.resource;
        _each(meshes, (mesh) => {
            mesh.dispose();
            this.resolver.scene?.removeMesh(mesh);
        });
        this.resource = null;
    }
    dispose() {
        this.disposeMesh();
        this.resolver.meshDisposed(this.id);
    }
    /**
     * Import the mesh using the loader
     * @param src source of the mesh
     * @param loader loader to use
     * @returns Promise mesh loaded
     */
    async importMesh(base64EncodedUrl, loader) {
        const result = await SceneLoader.ImportMeshAsync(null, '', base64EncodedUrl, this.resolver.scene, (progressEvent) => {
            console.debug(`Loading, on progress using ${loader} loader`);
            this.resolver.meshProgress(this.id, progressEvent.loaded / progressEvent.total);
        }, `.${loader?.toString()?.toLowerCase()}`);
        if (result &&
            this.resolver.onViewerModelLoadedObservable &&
            loader?.toString()?.toLowerCase() === 'ply') {
            const rootModel = result.meshes[0];
            this.resolver.onViewerModelLoadedObservable.notifyObservers(rootModel);
        }
        return result;
    }
    /**
     * Load the mesh
     * @param src source of the mesh
     * @returns Promise mesh loaded
     */
    async loadMesh(src, loader) {
        const fileExtension = src.split('.').pop()?.toLowerCase();
        const loaderToUse = loader || fileExtension;
        if (!loaderToUse) {
            throw new MeshLoadingError(MeshLoadingErrorMessages.LOADER_NOT_PROVIDED);
        }
        else if (loaderToUse &&
            !Object.values(MeshLoader).includes(loaderToUse)) {
            throw new MeshLoadingError(`${MeshLoadingErrorMessages.LOADER_NOT_SUPPORTED} ${loaderToUse}`);
        }
        return await this.importMesh(src, loaderToUse);
    }
    async update(config) {
        let meshUpdated = false;
        if (!config.src) {
            console.error('MeshResource update no src');
            return;
        }
        if (this.src !== config.src) {
            if (this.resource)
                this.disposeMesh();
            // TODO : Will need to use it to have access to cameras
            // SceneLoader.ShowLoadingScreen = false
            // const mesh = await SceneLoader.AppendAsync(
            //   config.src,
            //   '',
            //   this.resolver.scene,
            //   undefined,
            //   '.glb'
            // )
            const mesh = await this.loadMesh(config.src, config.loader);
            // If no mesh is loaded, return
            // (looking for meshes as some loader does not throw error when failing to load a mesh)
            if (!mesh || mesh.meshes.length < 1) {
                throw new MeshLoadingError(MeshLoadingErrorMessages.LOADER_EMPTY_MESHES);
            }
            this.resource = mesh.meshes;
            this.src = config.src;
            this.loader = config.loader;
            // animations
            this.hasAnimations = meshHasAnimations(mesh);
            if (this.hasAnimations) {
                this.animationGroups = mesh.animationGroups;
                this.animationGroups.forEach((animationGroup) => {
                    animationGroup.name = `${this.id}-${animationGroup.name}`;
                    animationGroup.metadata = {
                        ...animationGroup.metadata,
                        ...{ meshId: this.id },
                    };
                    if (animationGroup.isStarted) {
                        this.selectedAnimation = animationGroup;
                        if (animationGroup.isPlaying) {
                            this.animationStatus = MeshInputAnimationStatus.PLAY;
                        }
                        else {
                            this.animationStatus = MeshInputAnimationStatus.PAUSE;
                        }
                    }
                });
            }
            let min = this.resource[0].getBoundingInfo().boundingBox.minimumWorld;
            this.resource.forEach((mesh) => {
                // Attach all meshes to the TransformNode
                mesh.setParent(this.node);
                const meshTrianglesCount = mesh.getTotalIndices() / 3;
                const meshVerticesCount = mesh.getTotalVertices();
                mesh.metadata = {
                    ...mesh.metadata,
                    type: USER_MESH_TAG,
                    hasAnimations: this.hasAnimations,
                    animationStatus: this.animationStatus,
                    trianglesCount: meshTrianglesCount,
                    verticesCount: meshVerticesCount,
                    // Add the node id to the mesh to retrieve it later
                    id: this.id,
                };
                this.triangleCount += meshTrianglesCount;
                this.verticesCount += meshVerticesCount;
                let meshMin = mesh.getBoundingInfo().boundingBox.minimumWorld;
                min = Vector3.Minimize(min, meshMin);
            });
            // Calculate the bounding info of the node
            this.nodeBoundingInfo = this.getNodeBoundingInfo(this.node);
            this.node.translate(new Vector3(0, 1, 0), Math.abs(min._y));
            const boundingVectors = this.node?.getHierarchyBoundingVectors();
            const boundingBox = boundingVectors.max.subtract(boundingVectors.min);
            this.physicalSize = [boundingBox.x, boundingBox.y, boundingBox.z];
            this.resolver.meshImported(this.id, this.hasAnimations);
            meshUpdated = true;
        }
        if (this.selectedAnimation?.name !== config.selectedAnimation ||
            this.animationStatus !== config.animationStatus) {
            meshUpdated = true;
        }
        this.applyAnimation(config);
        if (meshUpdated) {
            this.resolver.meshUpdated(this.id, this.state);
        }
    }
    getObjectResource() {
        return this.resource;
    }
    setRelation(parent) {
        if (!this.resource) {
            console.warn('Setting parent', WARN_RESOURCE_NOT_INITIALIZED);
            return;
        }
        // If a node is present use it as the reference
        if (this.node && isTransformNode(parent.getObjectResource())) {
            this.node.setParent(parent.getObjectResource());
            this.resolver.meshUpdated(this.id, this.state);
            return;
        }
        // Use all the meshes as the reference
        const meshes = this.resource;
        _each(meshes, (mesh) => {
            if (this.node)
                mesh.parent = this.node;
        });
    }
    getAnimationsName() {
        let animationsName;
        if (this.animationGroups.length > 0) {
            animationsName = this.animationGroups.map((animation) => animation.name);
        }
        return animationsName;
    }
    getAnimationByName(name) {
        let animationGroup;
        this.animationGroups.forEach((a) => {
            if (a.name === name) {
                animationGroup = a;
                return false;
            }
        });
        return animationGroup;
    }
    applyAnimation(config) {
        if (config.disabledAnimations) {
            this.animationGroups.forEach((animationGroup) => {
                if (animationGroup.isStarted) {
                    animationGroup.reset();
                    animationGroup.stop();
                }
            });
        }
        if (config.selectedAnimation && config.animationStatus) {
            // selected animation change
            if (this.selectedAnimation?.name !== config.selectedAnimation) {
                // stop previous animation
                if (this.selectedAnimation?.isStarted) {
                    this.selectedAnimation?.reset();
                    this.selectedAnimation?.stop();
                    this.resource?.forEach((mesh) => {
                        mesh.metadata = {
                            ...mesh.metadata,
                            ...{ animationStatus: MeshInputAnimationStatus.PAUSE },
                        };
                    });
                }
                // set new animation
                this.selectedAnimation = this.getAnimationByName(config.selectedAnimation);
            }
            // set new animation status
            this.animationStatus = config.animationStatus;
            // apply animation
            if (!config.disabledAnimations &&
                this.selectedAnimation &&
                (this.animationStatus === MeshInputAnimationStatus.PLAY ||
                    this.animationStatus === MeshInputAnimationStatus.PAUSE)) {
                if (!this.selectedAnimation.isPlaying) {
                    this.selectedAnimation.play(true);
                    this.resource?.forEach((mesh) => {
                        mesh.metadata = {
                            ...mesh.metadata,
                            ...{ animationStatus: this.animationStatus },
                        };
                    });
                }
                if (this.animationStatus === MeshInputAnimationStatus.PAUSE) {
                    this.selectedAnimation?.pause();
                    this.resource?.forEach((mesh) => {
                        mesh.metadata = {
                            ...mesh.metadata,
                            ...{ animationStatus: this.animationStatus },
                        };
                    });
                }
            }
        }
    }
    get state() {
        if (!this.resource || !this.node) {
            return {};
        }
        const animationsName = this.getAnimationsName();
        const transform = getTransformFromNode(this.node);
        return {
            src: this.src,
            parent: this.node.parent?.id,
            loader: this.loader,
            hasAnimations: this.hasAnimations,
            trianglesCount: this.triangleCount,
            verticesCount: this.verticesCount,
            boundingBox: {
                maximum: this.nodeBoundingInfo?.boundingBox.maximumWorld.asArray(),
                minimum: this.nodeBoundingInfo?.boundingBox.minimumWorld.asArray(),
                size: this.nodeBoundingInfo?.boundingBox.extendSizeWorld
                    .scale(2)
                    .asArray(),
                center: this.nodeBoundingInfo?.boundingBox.centerWorld.asArray(),
            },
            physicalSize: this.physicalSize,
            ...(animationsName && { animations: animationsName }),
            ...(this.selectedAnimation && {
                selectedAnimation: this.selectedAnimation?.name,
            }),
            ...(this.animationStatus && { animationStatus: this.animationStatus }),
            groundScene: true,
            transform: transform,
        };
    }
    /**
     * Calculate the bounding info of a TransformNode.
     * @param node The TransformNode to calculate the bounding info for.
     * @returns The BoundingInfo object for the TransformNode.
     */
    getNodeBoundingInfo(node) {
        // Initialize variables for the min and max values of the bounding box
        let min = new Vector3();
        let max = new Vector3();
        // Iterate over each child mesh to get their bounding boxes
        node.getChildMeshes().forEach((mesh) => {
            let boundingInfo = mesh.getBoundingInfo();
            let boundingBox = boundingInfo.boundingBox;
            // Update min and max to encompass all child meshes
            min = Vector3.Minimize(min, boundingBox.minimumWorld);
            max = Vector3.Maximize(max, boundingBox.maximumWorld);
        });
        // Create a new BoundingInfo object with the min and max values for the TransformNode
        return new BoundingInfo(min, max);
    }
}
export { MeshResource, isUserMesh };
