/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useState } from 'react'

import html from 'prettier/plugins/html'
import prettier from 'prettier/standalone'

import { Provider, defaultTheme } from '@adobe/react-spectrum'

import { MeshLoader } from '@a3d-viewer/renderer-types'

import Article from './components/Layout/Article'
import Footer from './components/Layout/Footer'
import Header from './components/Layout/Header'
import MainContent from './components/Layout/MainContent'
import Viewer from './components/Viewer'
import useTheme from './hooks/useTheme'
import { A3dViewer } from '@a3d-viewer/viewer'

export type MeshType = {
  fileAsDataUrl: string
  extension?: MeshLoader
  file?: File
}

function App() {
  const { theme } = useTheme()
  const [code, setCode] = useState<string | undefined>()
  const [state, setState] = useState<string | undefined>()

  const handleDomChange = async (elt: A3dViewer | null) => {
    if (!elt) return undefined

    const newCode = await prettier.format(elt.outerHTML, {
      htmlWhitespaceSensitivity: 'ignore',
      proseWrap: 'always',
      parser: 'html',
      plugins: [html],
    })

    setCode(newCode)
  }

  const handleStateChange = (newState: string) => {
    setState(newState)
  }

  return (
    <Provider
      theme={defaultTheme}
      colorScheme={theme}
      id="provider"
      UNSAFE_className={`flex flex-1 flex-col ${theme === 'light' ? 'bg-white' : 'bg-black'}`}
      scale="medium"
    >
      <Header />
      <MainContent className="pr-4 pl-4 flex-col">
        <Viewer
          onStateChange={handleStateChange}
          onDomChange={handleDomChange}
        />

        <div className="whitespace-nowrap mt-[14px] flex flex-col lg:flex-row gap-5">
          <Article className="flex-1 flex overflow-hidden bg-gray-300 dark:bg-gray-900 p-4">
            <div className="flex-1 overflow-x-auto">
              <h2>Input - Viewer Web Component Code</h2>
              <pre className="text-xs">{code}</pre>
            </div>
          </Article>
          <Article className="flex-1 flex overflow-hidden bg-gray-300 dark:bg-gray-900 p-4">
            <div className="flex-1 overflow-x-auto">
              <h2>Output - Viewer Events State</h2>
              <pre className="text-xs">{state}</pre>
            </div>
          </Article>
        </div>
      </MainContent>
      <Footer id="footer" className="flex justify-center w-full">
        Adobe 2024
      </Footer>
    </Provider>
  )
}

export default App
