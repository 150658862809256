/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Hotspot } from '@a3d-viewer/core';
import '@babylonjs/core/Culling/ray';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math.vector';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import _find from 'lodash/find';
import _isArray from 'lodash/isArray';
import { biasForZFighting, normalizeBetweenCameraDepth } from '../util';
import { BabylonResource } from './BabylonResource';
export class HotspotResource extends BabylonResource {
    constructor(id, uuid, resolver, onDispose) {
        super(id, uuid, resolver);
        this.onDispose = () => { };
        this.detail = null;
        this.facePositions = [];
        this.onDispose = onDispose;
        this.node = new TransformNode(id);
        this.resource = new Hotspot(this.id);
    }
    dispose() {
        if (this.resource)
            this.resource.remove();
        this.resolver.hotspotDeleted(this.id);
        this.onDispose(this.id);
    }
    findSubMesh(mesh, subMeshId) {
        const meshes = mesh.getObjectResource();
        const matchMesh = _find(meshes, (m) => {
            return m.id === subMeshId;
        });
        if (matchMesh) {
            return matchMesh;
        }
        else
            return undefined;
    }
    findMatchingMesh(meshId, subMeshId) {
        const mesh = this.resolver.meshesById.get(meshId);
        if (mesh) {
            return this.findSubMesh(mesh, subMeshId);
        }
        else {
            console.warn(`Mesh ${meshId} not found, trying to find closest match`);
            let subMesh = undefined;
            for (const [_, m] of this.resolver.meshesById) {
                if (subMesh)
                    return;
                const matchMesh = this.findSubMesh(m, subMeshId);
                if (matchMesh) {
                    subMesh = matchMesh;
                }
            }
            return subMesh;
        }
    }
    async update(config) {
        if (_isArray(config.position)) {
            this.node.position = new Vector3(config.position[0], config.position[1], config.position[2]);
        }
        else if (config.surface) {
            const [meshId, subMeshId, faceId, bu, bv] = config.surface;
            // Find the right subMesh
            const matchMesh = this.findMatchingMesh(meshId, subMeshId);
            // Save the mesh
            if (matchMesh) {
                this.mesh = matchMesh;
                matchMesh.computeWorldMatrix(true);
            }
            else
                throw new Error(`Could not attach hotspot to mesh ${meshId}, mesh not found`);
            this.surfaceInfo = {
                meshId: meshId,
                subMeshId: subMeshId,
                faceId: parseInt(faceId),
                barycentricU: parseFloat(bu),
                barycentricV: parseFloat(bv),
            };
        }
        this.resolver.hotspotUpdated(this.id, this.state);
    }
    getObjectResource() {
        return this.resource;
    }
    setRelation(_parent) {
        this.node.parent = _parent.getObjectResource();
    }
    get state() {
        return {
            ...(this.node.position !== null && {
                position: this.node.position.asArray(),
            }),
            ...(this.detail !== null && { detail: this.detail }),
        };
    }
    /**
     * Check if the hotspot is visible from the camera.
     * For now, draw a ray from the camera to the hotspot and check if it intersects with the mesh.
     * Will be improved in the future by checking the depth buffer.
     * @returns true if the hotspot is visible, false otherwise, null if the resource is not loaded
     */
    isVisible(scene, camera, depthValue) {
        const posInView = Vector3.TransformCoordinates(this.worldPosition, scene.getViewMatrix());
        const z = normalizeBetweenCameraDepth(posInView.z, camera.minZ, camera.maxZ);
        // Compute a dynamic bias to avoid z fighting
        const bias = biasForZFighting(z);
        // We avoid negative values for the z
        const zSafeValue = Math.abs(z);
        // -------- Second test is to add a little of bias to the z value
        return zSafeValue - bias <= depthValue;
    }
    /**
     * Calculate the screen coordinates of the hotspot
     * Will be call for each frame, need to be light and optimized,
     * if needed store values in the resource update method
     * @returns The screen coordinates of the hotspot or null if none
     */
    calculateScreenCoords(engine, scene) {
        const mesh = this.mesh;
        if (mesh && this.surfaceInfo) {
            const { faceId } = this.surfaceInfo;
            // Get the mesh positions updated by the GPU (animation, morph targets, etc.)
            const positions = mesh.getPositionData(true, true);
            const indices = mesh.getIndices();
            const hasIndices = indices && indices.length > 0;
            // Use of mesh indices if present
            // Learn more : https://gamedev.stackexchange.com/a/68840
            if (hasIndices) {
                let faceIndex = faceId * 3;
                let faceIndices = [
                    indices[faceIndex],
                    indices[faceIndex + 1],
                    indices[faceIndex + 2],
                ];
                this.facePositions = faceIndices.map((i) => new Vector3(positions[i * 3], positions[i * 3 + 1], positions[i * 3 + 2]));
            }
            else {
                let faceIndex = faceId * 3;
                this.facePositions = [
                    new Vector3(positions[faceIndex * 3], positions[faceIndex * 3 + 1], positions[faceIndex * 3 + 2]),
                    new Vector3(positions[faceIndex * 3 + 3], positions[faceIndex * 3 + 4], positions[faceIndex * 3 + 5]),
                    new Vector3(positions[faceIndex * 3 + 6], positions[faceIndex * 3 + 7], positions[faceIndex * 3 + 8]),
                ];
            }
            // Compute the point position in the face using barycentric coordinates
            const localPointPosition = Vector3.Zero();
            const bu = this.surfaceInfo.barycentricU;
            const bv = this.surfaceInfo.barycentricV;
            const bw = 1 - bu - bv;
            localPointPosition.addInPlace(this.facePositions[0].scale(bu));
            localPointPosition.addInPlace(this.facePositions[1].scale(bv));
            localPointPosition.addInPlace(this.facePositions[2].scale(bw));
            const pointPosition = Vector3.Zero();
            pointPosition.addInPlace(this.facePositions[0].scale(bu));
            pointPosition.addInPlace(this.facePositions[1].scale(bv));
            pointPosition.addInPlace(this.facePositions[2].scale(bw));
            const x = pointPosition.x;
            const y = pointPosition.y;
            const z = pointPosition.z;
            this.worldPosition = new Vector3(x, y, z);
            const worldMatrix = mesh.getWorldMatrix();
            this.worldPosition = Vector3.TransformCoordinates(this.worldPosition, worldMatrix);
            this.screenCoords = Vector3.Project(this.worldPosition, Matrix.IdentityReadOnly, scene.getTransformMatrix(), scene.activeCamera.viewport.toGlobal(this.resolver.engine.getRenderWidth(), this.resolver.engine.getRenderHeight()));
            return this.screenCoords;
        }
        this.worldPosition = this.node.getAbsolutePosition();
        const posInViewProj = Vector3.TransformCoordinates(this.worldPosition, scene.getTransformMatrix());
        this.screenCoords = posInViewProj
            .multiplyByFloats(0.5, -0.5, 1.0)
            .add(new Vector3(0.5, 0.5, 0.0))
            .multiplyByFloats(engine.getRenderWidth(), engine.getRenderHeight(), 1);
        return this.screenCoords;
    }
    getPosition() {
        return this.worldPosition;
    }
}
