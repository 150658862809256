/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { customElement, property } from 'lit/decorators.js';
import { A3dCamera, A3dCameraErrorNames, A3dCameraErrorMessages, } from './a3d-camera';
import _isFinite from 'lodash/isFinite';
import _every from 'lodash/every';
let A3dCameraPerspective = class A3dCameraPerspective extends A3dCamera {
    constructor() {
        super(...arguments);
        /**
         * The camera target.
         * Either the name of the element that this camera is targeting.
         * Or the position in world space that this camera is targeting.
         */
        this.target = null;
        /**
         * This camera position.
         */
        this.position = null;
        /**
         * Whether or not this camera is active.
         */
        this.active = false;
    }
    /**
     * @internal
     */
    exposedData() {
        const cameraPosition = this.position
            ? this.position.split(' ').map((p) => parseFloat(p))
            : null;
        const cameraTarget = this.target
            ? this.target.split(' ').map((p) => parseFloat(p))
            : null;
        return {
            fov: this.yFov,
            ...this.cameraData(),
            ...(this.target !== null && { target: cameraTarget }),
            ...(this.position !== null && { position: cameraPosition }),
        };
    }
    /**
     * @internal
     */
    validateYFov() {
        if (!this.yFov) {
            return true;
        }
        const isValid = _isFinite(this.yFov) && this.yFov < Math.PI;
        if (!isValid) {
            this.dispatchErrorEvent(A3dCameraErrorNames.BAD_YFOV, A3dCameraErrorMessages[A3dCameraErrorNames.BAD_YFOV]);
        }
        return isValid;
    }
    validateThreeNumbersString(value, errorName) {
        let isValid = false;
        let valueList = [];
        if (value) {
            valueList = value.split(' ').map((p) => parseFloat(p));
        }
        if (valueList.length === 3 && _every(valueList, _isFinite)) {
            isValid = true;
        }
        else if (value === null) {
            isValid = true;
        }
        if (!isValid) {
            this.dispatchErrorEvent(errorName, A3dCameraErrorMessages[errorName]);
        }
        return true;
    }
    /**
     * @internal
     */
    validate() {
        const isValidYFov = this.validateYFov();
        const isValidPosition = this.validateThreeNumbersString(this.position, A3dCameraErrorNames.BAD_POSITION);
        const isValidTarget = this.validateThreeNumbersString(this.target, A3dCameraErrorNames.BAD_TARGET);
        return super.validate() && isValidYFov && isValidPosition && isValidTarget;
    }
};
__decorate([
    property({ type: String, reflect: true })
], A3dCameraPerspective.prototype, "target", void 0);
__decorate([
    property({ type: String, reflect: true })
], A3dCameraPerspective.prototype, "position", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], A3dCameraPerspective.prototype, "active", void 0);
__decorate([
    property({
        type: Number,
        attribute: 'y-fov',
    })
], A3dCameraPerspective.prototype, "yFov", void 0);
A3dCameraPerspective = __decorate([
    customElement('a3d-camera-perspective')
], A3dCameraPerspective);
export { A3dCameraPerspective };
