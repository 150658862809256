/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BabylonResource } from './BabylonResource';
import { ROOT_NODE } from './SceneResource';
import { getTransformFromNode } from '../util/trs';
class TransformResource extends BabylonResource {
    constructor() {
        super(...arguments);
        this.position = [0, 0, 0];
        this.rotation = [0, 0, 0];
    }
    get state() {
        const transformNode = this.node?.getObjectResource();
        if (!transformNode) {
            return {};
        }
        const parentId = transformNode?.parent?.id;
        const transform = getTransformFromNode(transformNode);
        return {
            ...(parentId !== ROOT_NODE && { parent: parentId }),
            transform: transform,
        };
    }
    getObjectResource() {
        return this.node?.getObjectResource();
    }
    /**
     * Translate the given element
     * @param resource the element to translate
     * @param position the element to translate
     */
    setElementPosition(node, position) {
        node.position = new Vector3(...position);
    }
    /**
     * Set the rotation of the given element
     * @param resource the element to rotate
     * @param rotation the rotation to apply
     */
    setElementRotation(node, rotation) {
        node.rotation = new Vector3(...rotation);
    }
    async update(config) {
        const { position, rotation, node } = config;
        this.node = node;
        if (position) {
            this.position = position;
            this.setElementPosition(node.getObjectResource(), position);
        }
        if (rotation) {
            this.rotation = rotation;
            this.setElementRotation(node.getObjectResource(), rotation);
        }
        this.node.getObjectResource().computeWorldMatrix(true);
        this.resolver.nodeUpdated(node.id, this.state);
    }
    dispose() {
        const stateNodes = this.state?.nodes;
        // check if node isn't already disposed
        if (this.node && stateNodes?.[this.node.id])
            this.update({
                position: [0, 0, 0],
                rotation: [0, 0, 0],
                node: this.node,
            });
        this.resolver.transformDisposed(this.id);
    }
    setRelation(parent) {
        this.node?.setRelation(parent);
    }
}
export { TransformResource };
