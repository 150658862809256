/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { SceneLoader } from '@babylonjs/core/Loading/sceneLoader';
import { BabylonResource } from '../BabylonResource';
/**
 * Preloaded mesh resource class for Babylon resolver
 */
class PreloadedMeshResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
    }
    dispose() {
        if (!this.resource) {
            return;
        }
        console.debug('dispose assets container');
        this.resource.removeAllFromScene();
        this.resource.dispose();
    }
    async update(config) {
        if (!this.resource && config.src) {
            const assetContainer = await SceneLoader.LoadAssetContainerAsync(config.src, '', this.resolver.scene);
            this.resource = assetContainer;
        }
    }
    getObjectResource() {
        return this.resource;
    }
    setRelation(_parent) {
        console.warn('Setting parent on an asset container has no effect');
    }
    get state() {
        if (!this.resource) {
            return {};
        }
        return { src: 'testsrc' };
    }
}
export { PreloadedMeshResource };
