/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { HotspotEvents, ViewerEvents } from '@a3d-viewer/core'
import { ActionButton, TextField } from '@adobe/react-spectrum'
import SaveIcon from '@spectrum-icons/workflow/Checkmark'
import CancelIcon from '@spectrum-icons/workflow/Close'
import RemoveIcon from '@spectrum-icons/workflow/Delete'
import EditIcon from '@spectrum-icons/workflow/Edit'
import { useRef, useState } from 'react'

type HotSpotProps = {
  label: string
  onChange?: (label: string) => void
}

function HotSpot({ label, onChange }: HotSpotProps) {
  const [editMode, setEditMode] = useState(false)
  const [showLabel, setShowLabel] = useState(false)
  const [labelValue, setLabelValue] = useState(label)
  const hotSpotRef = useRef<HTMLDivElement>(null)

  const handleButtonClick = (event: any) => {
    if (event.altKey && event.button === 0) {
      hotSpotRef.current?.dispatchEvent(
        new CustomEvent(ViewerEvents.HOTSPOT_FOCUS, {
          bubbles: true,
          composed: true,
          detail: { id: hotSpotRef.current.parentElement?.id },
        })
      )
    } else {
      setShowLabel(!showLabel)
    }
  }

  const handleEditPress = () => {
    setEditMode(true)
  }

  const handleRemovePress = () => {
    if (!hotSpotRef.current) return
    hotSpotRef.current.dispatchEvent(
      new CustomEvent(HotspotEvents.HOTSPOT_DELETE, {
        bubbles: true,
        composed: true,
      })
    )
  }

  const handleCancelPress = () => {
    setLabelValue(label)
    setEditMode(false)
  }

  const handleSavePress = () => {
    onChange?.(label)
    setEditMode(false)
  }

  return (
    <div className="hot-spot-wrapper" ref={hotSpotRef}>
      <button className="hot-spot-button" onClick={handleButtonClick}></button>
      {showLabel && (
        <>
          <div className="hot-spot-label-container">
            <div className="hot-spot-toolbar">
              {!editMode && (
                <>
                  <ActionButton isQuiet onPress={handleEditPress}>
                    <EditIcon size="XS" />
                  </ActionButton>
                  <ActionButton isQuiet onPress={handleRemovePress}>
                    <RemoveIcon size="XS" />
                  </ActionButton>
                </>
              )}
              {editMode && (
                <>
                  <ActionButton isQuiet onPress={handleSavePress}>
                    <SaveIcon size="XS" />
                  </ActionButton>
                  <ActionButton isQuiet onPress={handleCancelPress}>
                    <CancelIcon size="XS" />
                  </ActionButton>
                </>
              )}
            </div>
            {!editMode && <div className="hot-spot-label">{labelValue}</div>}
            {editMode && (
              <TextField
                defaultValue={labelValue}
                onChange={setLabelValue}
                autoFocus
              />
            )}
          </div>
        </>
      )}
    </div>
  )
}

export default HotSpot
