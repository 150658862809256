/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import React, { HTMLAttributes, ReactNode, useMemo, useState } from 'react'
import classNames from 'classnames'
import _map from 'lodash/map'

import { ToggleButton, View } from '@adobe/react-spectrum'
import { motion, AnimatePresence } from 'framer-motion'
import uniqueId from 'lodash/uniqueId'

type MenuItem = {
  key: string
  icon: ReactNode
  component: ReactNode
}

type MenuContainerProps = {
  top: Array<MenuItem[]>
  bottom?: MenuItem
}

const Frame = function Frame({
  className,
  children,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <View
      backgroundColor="gray-50"
      UNSAFE_className={classNames(
        `my-[0.5rem] ml-[0.5rem] mr-[0] py-[0.5rem]  rounded-xl shadow-lg flex flex-col justify-between overflow-y-auto`,
        className
      )}
    >
      {children}
    </View>
  )
}

export default function MenuContainer({
  top,
  bottom,
}: MenuContainerProps & HTMLAttributes<HTMLDivElement>) {
  const [topItem, setTopItem] = useState<MenuItem | undefined>(undefined)
  const [bottomOpen, setBottomOpen] = useState(false)

  const topItems = useMemo(() => {
    return _map(top, (items, index) => {
      return (
        <React.Fragment key={uniqueId()}>
          <div className="flex flex-col items-center">
            {items.map((menuItem: MenuItem) => {
              const { key, icon } = menuItem

              return (
                <ToggleButton
                  key={key}
                  isQuiet
                  onPress={() => {
                    setTopItem(topItem?.key === key ? undefined : menuItem)
                  }}
                  UNSAFE_className="w-[32px]"
                  isSelected={topItem?.key === key}
                >
                  {icon}
                </ToggleButton>
              )
            })}
          </div>
          {top[index + 1] && <a3d-separator horizontal></a3d-separator>}
        </React.Fragment>
      )
    })
  }, [topItem, top])

  function toggleBottom() {
    setBottomOpen(!bottomOpen)
  }

  return (
    <>
      <div className={`viewer-menu h-full absolute top-0 left-0 flex flex-col`}>
        <div className="flex flex-1 flex-row h-full">
          <Frame className="w-[48px] items-center">
            <div className="flex flex-1 flex-col items-center gap-[0.5rem]">
              {topItems}
            </div>
            {bottom && (
              <ToggleButton
                isQuiet
                onPress={toggleBottom}
                UNSAFE_className="w-[32px]"
                isSelected={bottomOpen}
              >
                {bottom.icon}
              </ToggleButton>
            )}
          </Frame>
          <div className="flex flex-col justify-between max-h-full max-w-[350px]">
            {topItem ? (
              <Frame className="min-w-[270px] px-[1rem]">
                {topItem.component}
              </Frame>
            ) : (
              <div className="flex flex-1"></div>
            )}

            <AnimatePresence>
              {bottomOpen && (
                <motion.div
                  className={classNames('flex ')}
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  exit={{ opacity: 0 }}
                  transition={{ duration: 0.15 }}
                >
                  <Frame className="min-w-[270px] px-[1rem]">
                    {bottom?.component}
                  </Frame>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
    </>
  )
}
