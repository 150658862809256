/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var MeshLoadingErrorMessages;
(function (MeshLoadingErrorMessages) {
    MeshLoadingErrorMessages["LOADER_NOT_PROVIDED"] = "loader-not-provided";
    MeshLoadingErrorMessages["LOADER_NOT_SUPPORTED"] = "loader-not-supported";
    MeshLoadingErrorMessages["LOADER_EMPTY_MESHES"] = "loader-empty-meshes";
})(MeshLoadingErrorMessages || (MeshLoadingErrorMessages = {}));
var EnvErrorMessages;
(function (EnvErrorMessages) {
    EnvErrorMessages["UPDATE_FAILED"] = "update-failed";
})(EnvErrorMessages || (EnvErrorMessages = {}));
class CustomError extends Error {
    constructor(message) {
        super(message);
        this.name = this.constructor.name;
        Error.captureStackTrace(this, this.constructor);
    }
}
class MeshLoadingError extends CustomError {
    constructor(message) {
        super(message);
    }
}
class EnvError extends CustomError {
    constructor(message) {
        super(message);
    }
}
export { EnvError, EnvErrorMessages, MeshLoadingError, MeshLoadingErrorMessages, };
