/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { View } from '@adobe/react-spectrum'
import classNames from 'classnames'
import { HTMLAttributes } from 'react'

function Article({
  style,
  className,
  children,
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <View
      elementType={'article'}
      backgroundColor="gray-200"
      UNSAFE_style={style}
      UNSAFE_className={classNames(
        `drop-shadow-[0_0px_2px_rgba(0,0,0,0.25)] rounded-2xl ml-2 mt-2 mr-2 mb-5`,
        className
      )}
    >
      {children}
    </View>
  )
}

export default Article
