/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import _isFunc from 'lodash/isFunction';
const isPBRMaterial = (material) => {
    return material.getClassName() === 'PBRMaterial';
};
const isTransformNode = (object) => {
    return (_isFunc(object.getClassName) && object.getClassName() === 'TransformNode');
};
/**
 * Check if a mesh has animations or skeleton with animations
 * @param mesh the scene loaded mesh
 * @returns true if the mesh has animations
 */
function meshHasAnimations(mesh) {
    if (mesh.animationGroups) {
        return mesh.animationGroups.length > 0;
    }
    if (mesh.skeletons) {
        for (let skeleton of mesh.skeletons) {
            return (skeleton.getAnimatables().length > 0 ||
                skeleton.animationPropertiesOverride !== undefined);
        }
    }
    return false;
}
export * from './bias';
export * from './normalize';
export { isPBRMaterial, isTransformNode, meshHasAnimations };
