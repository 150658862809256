/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { CameraResource } from './CameraResource';
/**
 * Camera resource class for Babylon resolver
 */
export class MeshCameraResource extends CameraResource {
    constructor(id, uuid, resolver, meshCamera) {
        super(id, uuid, resolver);
        this.camera = meshCamera;
    }
    async update() { }
    dispose() {
        this.camera.dispose();
    }
    setRelation(parent) {
        this.camera.parent = parent.getObjectResource();
    }
    setObjectResource(resource) {
        this.camera = resource;
    }
    getObjectResource() {
        return this.camera;
    }
    reset() { }
    get state() {
        return {
            id: this.id,
            uuid: this.uuid,
            type: 'FixCamera',
        };
    }
}
