/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { AlertDialog, DialogContainer } from '@adobe/react-spectrum'
import { useEffect, useState } from 'react'

type ErrorDialogProps = {
  isOpen: boolean
  onDismiss: () => void
}

export default function ErrorDialog({ isOpen, onDismiss }: ErrorDialogProps) {
  const [open, setOpen] = useState(isOpen)

  useEffect(() => {
    setOpen(isOpen)
  }, [isOpen])

  return (
    <DialogContainer onDismiss={onDismiss}>
      {open && (
        <AlertDialog
          title="Error"
          variant="error"
          primaryActionLabel="close"
          onPrimaryAction={onDismiss}
        >
          <p>Error while loading the mesh.</p>
          <p>The viewer currently accept only .fbx and .ply files.</p>
        </AlertDialog>
      )}
    </DialogContainer>
  )
}
