/***************************************************************************
 * ADOBE CONFIDENTIAL
 * ___________________
 *
 * Copyright 2023 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { ArcRotateCameraPointersInput } from '@babylonjs/core/Cameras/Inputs/arcRotateCameraPointersInput';
import { PointerModes } from '@a3d-viewer/renderer-types';
export class A3dArcRotateCameraPointersInput extends ArcRotateCameraPointersInput {
    constructor(camera, pointerMode = PointerModes.ORBIT) {
        super();
        this.camera = camera;
        this.pointerMode = pointerMode;
        this._overridePanClick = false;
    }
    /**
     * Handle the different modes of the camera
     */
    onButtonDown(event) {
        // Always pan on middle and right click, and on left click if the chosen mode is pan
        if (this.pointerMode === PointerModes.PAN || event.button != 0) {
            this._overridePanClick = true;
        }
        else if (this.pointerMode === PointerModes.ZOOM && event.button == 0) {
            // Mode zoom
            this.zoomDragStart = {
                y: event.clientY,
                radius: this.camera.radius,
            };
        }
    }
    /**
     * Reset the zoomDragStart and _overridePanClick flags
     */
    onButtonUp(_evt) {
        super.onButtonUp(_evt);
        this.zoomDragStart = undefined;
        this._overridePanClick = false;
    }
    /**
     * Properly handle the different modes of the camera based on the selected mode and the result of onButtonDown()
     */
    onTouch(point, offsetX, offsetY) {
        if (this.panningSensibility !== 0 &&
            ((this._ctrlKey && this.camera._useCtrlForPanning) ||
                this._overridePanClick)) {
            // _overridePanClick, as set by onButtonDown(), indicates we are panning
            this.camera.inertialPanningX += -offsetX / this.panningSensibility;
            this.camera.inertialPanningY += offsetY / this.panningSensibility;
        }
        else if (this.zoomDragStart) {
            // zoomDragStart being defined indicates we are zooming
            if (point) {
                // wheelPrecision is calculate based on the world size
                const wheelPrecision = this.camera.wheelPrecision;
                const delta = (point.y - this.zoomDragStart.y) / wheelPrecision;
                this.camera.radius = this.zoomDragStart.radius + delta;
            }
        }
        else if (this.angularSensibilityX !== 0 &&
            this.angularSensibilityY !== 0) {
            // this.pointerMode === PointerModes.ORBIT
            this.camera.inertialAlphaOffset -= offsetX / this.angularSensibilityX;
            this.camera.inertialBetaOffset -= offsetY / this.angularSensibilityY;
        }
    }
}
