/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

interface ColorBoxProps {
  // color in hex format
  color: string
  // callback when the box is clicked with color in hex format
  onClick?: (color: string) => void
}

export default function ColorBox({ color, onClick }: ColorBoxProps) {
  return (
    <div
      className="h-[32px] w-[32px] rounded box-border ml-2 aspect-square border-gray-400 border-solid border-1"
      style={{
        backgroundColor: color,
      }}
      {...(onClick && {
        onClick: () => {
          onClick(color)
        },
      })}
    ></div>
  )
}
