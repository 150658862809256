/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { RendererEvents } from '@a3d-viewer/renderer-types';
import _each from 'lodash/each';
import _once from 'lodash/once';
/**
 * Check if the camera is being moved
 * Compare the 2 positions of the camera
 */
export function isCameraBeingMoved(currentPosition, lastPosition) {
    return !currentPosition.equals(lastPosition);
}
/**
 * Create a function that will suspend the effects only once
 * @returns {Function} A function that will suspend the effects
 */
export function createSuspendEffectsOnce() {
    return _once((effects) => {
        _each(effects, (effect) => {
            effect.suspend();
        });
    });
}
/**
 * Resume the effects
 * @param effects The effects to resume
 */
export function resumePostEffects(effects) {
    _each(effects, (effect) => {
        effect.process();
    });
}
export function createCameraRenderObservable(camera, effects) {
    let cameraMovedOnce = createSuspendEffectsOnce();
    let lastPosition = camera.position.clone();
    let isCameraMoved = false;
    return (cameraPostion) => {
        if (isCameraBeingMoved(cameraPostion, lastPosition)) {
            cameraMovedOnce(effects);
            isCameraMoved = true;
        }
        else if (isCameraMoved) {
            cameraMovedOnce = createSuspendEffectsOnce();
            resumePostEffects(effects);
            isCameraMoved = false;
        }
        lastPosition = cameraPostion.clone();
    };
}
/**
 * Suspend / resume post effects when the camera is moving
 * @param canvas the canvas
 * @param scene the scene
 * @param effects the effects to suspend / resume
 */
export function postEffectsOnCameraMove(canvas, scene, effects) {
    canvas.addEventListener(RendererEvents.DEFAULT_CAMERA_CREATED, () => {
        const activeCamera = scene.activeCamera;
        if (!activeCamera) {
            return;
        }
        const cameraRenderObservable = createCameraRenderObservable(activeCamera, effects);
        // will be called at every render loop
        scene.onAfterCameraRenderObservable.add(() => {
            cameraRenderObservable(activeCamera.position);
        });
    }, { once: true });
}
