/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let A3dLoadingScreen = class A3dLoadingScreen extends LitElement {
    constructor() {
        super();
        this.loadingText = 'Loading...';
    }
    connectedCallback() {
        super.connectedCallback();
    }
    disconnectedCallback() {
        super.disconnectedCallback();
    }
    render() {
        if (this.hasChildNodes()) {
            return html `<slot></slot>`;
        }
        return html `<div class="a3dviewer-loading">
      <div class="a3dviewer-loading__spinner-container">
        <svg
          class="a3dviewer-loading__spinner-svg"
          width="24"
          height="24"
          stroke="white"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <style></style>
          <g>
            <circle cx="12" cy="12" r="9.5" stroke-width="3"></circle>
          </g>
        </svg>
      </div>
      <div class="a3dviewer-loading__text">${this.loadingText}</div>
    </div>`;
    }
};
A3dLoadingScreen.styles = css `
    :host {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    :host > .a3dviewer-loading {
      display: flex;
      height: 100%;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: var(
        --spectrum-dialog-underlay-background-color,
        var(--spectrum-alias-background-color-modal-overlay)
      );
    }

    .a3dviewer-loading__spinner-svg {
      transform-origin: center;
      animation: spinner_zKoa 2s linear infinite;
    }
    .a3dviewer-loading__spinner-svg circle {
      stroke-linecap: round;
      animation: spinner_YpZS 1.5s ease-in-out infinite;
      fill: none;
    }
    @keyframes spinner_zKoa {
      100% {
        transform: rotate(360deg);
      }
    }
    @keyframes spinner_YpZS {
      0% {
        stroke-dasharray: 0 150;
        stroke-dashoffset: 0;
      }
      47.5% {
        stroke-dasharray: 42 150;
        stroke-dashoffset: -16;
      }
      95%,
      100% {
        stroke-dasharray: 42 150;
        stroke-dashoffset: -59;
      }
    }
  `;
__decorate([
    property({
        reflect: true,
        attribute: 'loading-text',
    })
], A3dLoadingScreen.prototype, "loadingText", void 0);
A3dLoadingScreen = __decorate([
    customElement('a3d-loading-screen')
], A3dLoadingScreen);
export { A3dLoadingScreen };
