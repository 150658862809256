/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { ActionButton, Header as SpectrumHeader } from '@adobe/react-spectrum'

import substanceLogo from '../../assets/substance.svg'
import useTheme from '../../hooks/useTheme'

function Header() {
  const { theme, toggleTheme } = useTheme()

  return (
    <SpectrumHeader UNSAFE_className="h-14 flex flex-row justify-between items-center w-full box-border px-4">
      <div className="flex items-center">
        <img src={substanceLogo} className="h-8 mr-3" alt="Substance logo" />
        <span className="font-black">A3d Viewer</span>
      </div>
      <div>
        <ActionButton onPress={toggleTheme}>
          {theme === 'light' ? 'Dark Theme' : 'Light Theme'}
        </ActionButton>
      </div>
    </SpectrumHeader>
  )
}

export default Header
