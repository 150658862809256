/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import ThreeDMaterialsIcon from '@spectrum-icons/workflow/3DMaterials'
import FullScreenIcon from '@spectrum-icons/workflow/FullScreen'
import FullScreenExitIcon from '@spectrum-icons/workflow/FullScreenExit'
import LightIcon from '@spectrum-icons/workflow/Light'
import EffectIcon from '@spectrum-icons/workflow/Effects'
import MovieCameraIcon from '@spectrum-icons/workflow/MovieCamera'
import ReplayIcon from '@spectrum-icons/workflow/Replay'
import SettingsIcon from '@spectrum-icons/workflow/Settings'
import TargetIcon from '@spectrum-icons/workflow/Target'

import { cameraCurrentAtom, Theme, viewerAtom } from '../config'

import '@a3d-ui/button'
import '@a3d-ui/info'
import '@a3d-ui/separator'
import '@a3d-ui/toolbar'
import '@a3d-viewer/assets'
import '@a3d-viewer/camera'
import '@a3d-viewer/core'
import { ViewerEvents } from '@a3d-viewer/core'
import '@a3d-viewer/environment'
import '@a3d-viewer/hotspot'
import '@a3d-viewer/light'
import '@a3d-viewer/loading-screen'
import '@a3d-viewer/mesh'
import { A3dMesh } from '@a3d-viewer/mesh'
import '@a3d-viewer/node'
import {
  MeshInputAnimationStatus,
  SceneCamera,
} from '@a3d-viewer/renderer-types'
import '@a3d-viewer/scene'
import '@a3d-viewer/transform'
import '@a3d-viewer/viewer'

import _isArray from 'lodash/isArray'
import _isEqual from 'lodash/isEqual'

import { A3dViewer } from '@a3d-viewer/viewer'
import { useAtom, useSetAtom } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'
import _once from 'lodash/once'
import { MeshType } from '../App'
import useTheme from '../hooks/useTheme'
import { AnimationsPanel } from '../panels/AnimationsPanel'
import { CameraPanel } from '../panels/CameraPanel'
import { EnvironmentPanel } from '../panels/EnvironmentPanel'
import { HotspotsPanel } from '../panels/HotspotsPanel'
import { ModelPanel } from '../panels/ModelPanel'
import { SettingsPanel } from '../panels/SettingsPanel'
import HotSpot from './HotSpot'
import MenuContainer from './MenuContainer'
import { EffectsPanel } from '../panels/EffectsPanel'

type ViewerProps = {
  mesh: MeshType | null
  toggleFullscreen?: () => void
  onDomChange?: (elt: A3dViewer) => void
  onStateChange?: (newState: string) => void
  onError?: (e: Event) => void
}

function ViewerWC({
  mesh,
  toggleFullscreen,
  onDomChange,
  onStateChange,
  onError,
}: ViewerProps) {
  const { theme } = useTheme()

  const [viewer, setViewer] = useAtom(viewerAtom)
  const saveCameraPosition = useSetAtom(cameraCurrentAtom)
  const viewerRef = useRef<A3dViewer>(null)
  const meshRef = useRef<A3dMesh>(null)
  const [animations, setAnimations] = useState<string[] | undefined>()
  const [hotSpots, setHotSpots] = useState<any[]>([])
  const [meshCameras, setMeshCameras] = useState<SceneCamera[]>([])

  // TODO : maybe something to put directly in the a-3d-viewer component ?
  const stopWheelPropagation = (e: Event) => {
    e.preventDefault()
    e.stopPropagation()
  }

  useEffect(() => {
    if (!viewerRef.current) return undefined
    onDomChange?.(viewerRef.current)
  })

  const createMeshCameras = _once((cameras: any[]) => {
    setMeshCameras(cameras)
  })

  const viewerUpdated = async (e: Event) => {
    const { detail } = e as CustomEvent

    createMeshCameras(detail.cameras)

    const json = JSON.stringify(detail, null, 2)

    if (detail.camera) {
      saveCameraPosition({
        position: {
          x: parseFloat(detail.camera.position[0]),
          y: parseFloat(detail.camera.position[1]),
          z: parseFloat(detail.camera.position[2]),
        },
        target: {
          x: parseFloat(detail.camera.target[0]),
          y: parseFloat(detail.camera.target[1]),
          z: parseFloat(detail.camera.target[2]),
        },
      })
    }

    onStateChange?.(json)
    populateAnimations(detail)
  }

  const handleReset = () => {
    viewerRef.current?.dispatchEvent(new CustomEvent(ViewerEvents.RESET_CAMERA))
  }

  // dblclick event listener
  useEffect(() => {
    if (!viewerRef.current) return undefined
    const viewerEle = viewerRef.current

    viewerEle.addEventListener('dblclick', handleDblClick, false)

    return () => {
      viewerEle.removeEventListener('dblclick', handleDblClick)
    }
  }, [viewerRef.current, viewer.hotspot.addHotspotOnDblClick])

  // click event listener
  useEffect(() => {
    if (!viewerRef.current) return undefined
    const viewerEle = viewerRef.current

    viewerEle.addEventListener('click', handleClick, false)

    return () => {
      viewerEle.removeEventListener('click', handleClick)
    }
  }, [viewerRef.current, viewer.hotspot.addHotspotOnNextClick])

  const color = useMemo(() => {
    if (viewer.color) {
      return viewer.color
    }
    return theme === Theme.dark ? '#bebeb9' : '#F3F3F3'
  }, [viewer.color, theme])

  const handleMeshImported = () => {
    setViewer({
      ...viewer,
      meshImported: true,
      camera: { ...viewer.camera, positionOn: false, targetOn: false },
    })
  }

  function cameraMoved() {
    setViewer((oldViewer) => {
      return {
        ...oldViewer,
        camera: { ...oldViewer.camera, selectedCamera: undefined },
      }
    })
  }

  function handleError(e: Event) {
    onError?.(e)
  }

  function populateAnimations(state: any) {
    if (!meshRef.current) return

    const animationsFromState =
      state.meshes && state.meshes[meshRef.current.id]?.animations

    setAnimations(animationsFromState)
  }

  useEffect(() => {
    if (!viewerRef.current) return undefined
    const viewerEle = viewerRef.current

    viewerEle.addEventListener('wheel', stopWheelPropagation, {
      passive: false,
    })

    viewerEle.addEventListener(ViewerEvents.VIEWER_UPDATED, viewerUpdated)

    viewerEle.addEventListener(ViewerEvents.CAMERA_MOVED, cameraMoved)

    viewerEle.addEventListener(ViewerEvents.RESOURCE_LOADED, handleMeshImported)

    viewerEle.addEventListener(ViewerEvents.READY, handleReadyEvent)

    viewerEle.addEventListener(
      ViewerEvents.HOTSPOT_NEW,
      handleNewHotSpot as EventListener
    )
    viewerEle.addEventListener(
      ViewerEvents.HOTSPOT_DELETE,
      handleHotSpotDelete as EventListener
    )

    viewerEle.addEventListener('error', handleError)

    return () => {
      viewerEle.removeEventListener('wheel', stopWheelPropagation)

      viewerEle.removeEventListener(ViewerEvents.VIEWER_UPDATED, viewerUpdated)
      viewerEle.removeEventListener(ViewerEvents.CAMERA_MOVED, cameraMoved)
      viewerEle.removeEventListener(
        ViewerEvents.RESOURCE_LOADED,
        handleMeshImported
      )

      viewerEle.removeEventListener(ViewerEvents.READY, handleReadyEvent)

      viewerEle.removeEventListener(
        ViewerEvents.HOTSPOT_NEW,
        handleNewHotSpot as EventListener
      )
      viewerEle.removeEventListener(
        ViewerEvents.HOTSPOT_DELETE,
        handleHotSpotDelete as EventListener
      )
      viewerEle.removeEventListener('error', handleError)
    }
  }, [viewerRef.current])

  const handleReadyEvent = () => {
    setViewer({
      ...viewer,
      sceneReady: true,
    })
  }

  const handleClick = (event: MouseEvent) => {
    if (viewer.hotspot.addHotspotOnNextClick) {
      viewerRef.current?.dispatchEvent(
        new CustomEvent(ViewerEvents.HOTSPOT_ADD)
      )

      // reset the addHotspotOnNextClick to false
      setViewer({
        ...viewer,
        hotspot: {
          ...viewer.hotspot,
          addHotspotOnNextClick: false,
        },
      })
    } else if (event.altKey && event.button === 0) {
      viewerRef.current?.dispatchEvent(new CustomEvent(ViewerEvents.MESH_FOCUS))
    }
  }

  const handleDblClick = () => {
    if (viewer.hotspot.addHotspotOnDblClick) {
      viewerRef.current?.dispatchEvent(
        new CustomEvent(ViewerEvents.HOTSPOT_ADD)
      )
    }
  }

  const handleHotSpotDelete = (e: CustomEvent) => {
    console.debug(`Removing hot spot #${e.detail.id}`, e)
    setHotSpots((prev) =>
      prev.filter((hotSpot: any) => hotSpot.id !== e.detail.id)
    )
  }

  const handleNewHotSpot = (e: CustomEvent) => {
    console.debug('New hotspot to create', e)
    if (e.detail) {
      // due to too much render we need to use the previous state
      // to avoid one of the previous hotspots to be removed
      setHotSpots((prev) => [
        ...prev,
        { ...e.detail, id: `hotSpot-${prev.length}` },
      ])
    }
  }

  const handleHotSpotChange = (id: string, label: string) => {
    setHotSpots((prev) =>
      prev.map((hotSpot) => {
        if (hotSpot.id === id) {
          hotSpot.label = label
        }
        return hotSpot
      })
    )
  }

  // init viewer with animations
  if (
    _isArray(animations) &&
    animations?.length > 0 &&
    !_isEqual(animations, viewer.mesh.animations)
  ) {
    setViewer({
      ...viewer,
      mesh: {
        ...viewer.mesh,
        animations,
        selectedAnimation: animations[0],
        animationStatus: MeshInputAnimationStatus.PLAY,
      },
    })
  }

  const switchToViewportCamera =
    viewer.camera.positionOn || viewer.camera.targetOn

  return (
    <>
      {mesh?.fileAsDataUrl && (
        <a3d-viewer key="1" ref={viewerRef}>
          <a3d-scene
            id="test"
            color={color}
            {...(viewer.shadow && { shadows: viewer.shadow })}
            {...(viewer.grid && { grid: true })}
            {...(viewer.passes.depth && { 'depth-map': true })}
            {...(viewer.passes.antialiasing && { antialiasing: true })}
          >
            <a3d-camera-perspective
              {...(switchToViewportCamera && {
                active: true,
              })}
              {...(viewer.camera.positionOn && {
                position: `${viewer.camera.position.x} ${viewer.camera.position.y} ${viewer.camera.position.z}`,
              })}
              {...(viewer.camera.targetOn && {
                target: `${viewer.camera.target.x} ${viewer.camera.target.y} ${viewer.camera.target.z}`,
              })}
              // zoom-min={viewer.camera.limitMin}
              // zoom-max={viewer.camera.limitMax}
              // {...(viewer.camera.limitOff && { 'limit-disabled': true })}
            ></a3d-camera-perspective>
            {meshCameras &&
              meshCameras.map((camera) => {
                return (
                  <a3d-camera-perspective
                    key={camera.name}
                    camera-id={camera.name}
                    {...(camera.name ===
                      viewer.camera.selectedCamera?.toString() && {
                      active: true,
                    })}
                  ></a3d-camera-perspective>
                )
              })}
            <a3d-environment
              src={viewer.envSrc}
              rotation-y={viewer.envRotation}
              intensity={viewer.envIntensity}
              {...(viewer.envBlurLevel > 0 && {
                'blur-level': viewer.envBlurLevel,
              })}
              {...(viewer.envVisibility && { visible: true })}
            ></a3d-environment>
            <a3d-node>
              <a3d-transform
                position={`${viewer.mesh.translation.x} ${viewer.mesh.translation.y} ${viewer.mesh.translation.z}`}
                rotation={`${viewer.mesh.rotation.x} ${viewer.mesh.rotation.y} ${viewer.mesh.rotation.z}`}
              ></a3d-transform>
              <a3d-mesh
                ref={meshRef}
                src={mesh?.fileAsDataUrl}
                loader={mesh?.extension}
                selected-animation={viewer.mesh.selectedAnimation}
                animation-status={viewer.mesh.animationStatus}
                {...(viewer.mesh.disabledAnimations && {
                  'disabled-animations': true,
                })}
              ></a3d-mesh>

              {viewer.sceneReady &&
                hotSpots.map((hotSpot: any) => (
                  <a3d-hotspot
                    key={hotSpot.id}
                    id={hotSpot.id}
                    {...hotSpot.attributes}
                  >
                    <HotSpot
                      label={hotSpot.label || 'default label'}
                      onChange={(label) => {
                        handleHotSpotChange(hotSpot.id, label)
                      }}
                    />
                  </a3d-hotspot>
                ))}
            </a3d-node>
          </a3d-scene>

          {viewer.info.enable && <a3d-info></a3d-info>}

          <a3d-loading-screen slot="loadingScreen"></a3d-loading-screen>
        </a3d-viewer>
      )}

      {viewer.sceneReady && viewer.toolbar.enable && (
        <a3d-toolbar>
          <slot slot="right">
            <a3d-separator></a3d-separator>
            <a3d-button onClick={() => toggleFullscreen?.()}>
              {viewer.fullscreen ? <FullScreenExitIcon /> : <FullScreenIcon />}
            </a3d-button>
          </slot>
        </a3d-toolbar>
      )}

      {viewer.sceneReady && (
        <MenuContainer
          top={[
            [
              {
                key: 'General',
                icon: <ThreeDMaterialsIcon />,
                component: <ModelPanel />,
              },
              {
                key: 'Camera',
                icon: <MovieCameraIcon />,
                component: (
                  <CameraPanel
                    handleReset={handleReset}
                    cameras={meshCameras}
                  />
                ),
              },
              {
                key: 'Environment',
                icon: <LightIcon />,
                component: <EnvironmentPanel />,
              },
              {
                key: 'Effects',
                icon: <EffectIcon />,
                component: <EffectsPanel />,
              },
            ],
            [
              {
                key: 'Hotspots',
                icon: <TargetIcon />,
                component: <HotspotsPanel />,
              },
              ...(animations
                ? [
                    {
                      key: 'Animations',
                      icon: <ReplayIcon />,
                      component: <AnimationsPanel />,
                    },
                  ]
                : []),
            ],
          ]}
          bottom={{
            key: 'Settings',
            icon: <SettingsIcon />,
            component: <SettingsPanel />,
          }}
        ></MenuContainer>
      )}
    </>
  )
}

export default ViewerWC
