/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { ViewerEvents } from '@a3d-viewer/core';
import { A3dViewer } from '@a3d-viewer/viewer';
import { LitElement, css, html } from 'lit';
import { customElement, state } from 'lit/decorators.js';
let A3dInfo = class A3dInfo extends LitElement {
    formatDimension(dimension) {
        if (!dimension) {
            return;
        }
        const newDimension = dimension
            .map((dim) => {
            const rounded = Math.trunc((dim + Number.EPSILON) * 100) / 100;
            return rounded + ' m';
        })
            .join(' / ');
        return newDimension;
    }
    render() {
        if (!this.state?.scene) {
            return html ``;
        }
        let dimension = this.state?.scene.dimension;
        dimension = this.formatDimension(dimension);
        let numberOfMeshes = 0;
        let meshes = [];
        if (this.state?.meshes) {
            const meshesEntries = Object.entries(this.state.meshes);
            for (const [id, meshInfo] of meshesEntries) {
                meshes.push({
                    id,
                    dimension: this.formatDimension(meshInfo?.physicalSize),
                    triangles: meshInfo?.trianglesCount,
                    vertices: meshInfo?.verticesCount,
                });
            }
            numberOfMeshes = meshes.length;
        }
        return html `<div class="info">
      <p>Info panel</p>
      <div>Scene dimensions : ${dimension}</div>
      <div>Mesh count : ${numberOfMeshes}</div>
      ${meshes.map((mesh) => html `<div>
            <div>Mesh id : ${mesh.id}</div>
            <div>Mesh dimensions : ${mesh.dimension}</div>
            <div>Mesh triangles : ${mesh.triangles}</div>
            <div>Mesh vertices : ${mesh.vertices}</div>
          </div>`)}
    </div>`;
    }
    connectedCallback() {
        super.connectedCallback();
        this.slot = 'info';
        const viewer = this.parentElement;
        if (viewer instanceof A3dViewer) {
            viewer.addEventListener(ViewerEvents.VIEWER_UPDATED, this.viewerUpdated.bind(this));
        }
    }
    viewerUpdated(event) {
        this.state = event.detail;
    }
};
A3dInfo.styles = css `
    :host {
      position: absolute;
      right: 10px;
      top: 10px;
    }

    .info {
      background: var(--spectrum-alias-background-color-default);
      padding: 0.5em 1rem;
      border-radius: 16px;
      box-shadow: 0px 4px 16px 0px #00000014;
    }
  `;
__decorate([
    state()
], A3dInfo.prototype, "state", void 0);
A3dInfo = __decorate([
    customElement('a3d-info')
], A3dInfo);
export { A3dInfo };
