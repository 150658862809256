/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
/**
 * Normalize a value between 0 and 1
 * @param z The value to normalize
 * @param cameraMinZ The minimum z value of the camera
 * @param cameraMaxZ The maximum z value of the camera
 * @returns The normalized value
 */
function normalizeBetweenCameraDepth(z, cameraMinZ, cameraMaxZ) {
    return (z - cameraMinZ) / (-cameraMinZ + cameraMaxZ);
}
export { normalizeBetweenCameraDepth };
