/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let A3dSeparator = class A3dSeparator extends LitElement {
    constructor() {
        super(...arguments);
        this.horizontal = false;
    }
    render() {
        return html ` <div part="separator"></div> `;
    }
};
A3dSeparator.styles = css `
    :host::part(separator) {
      width: 3px;
      background-color: var(--spectrum-alias-border-color-light);
      border-radius: 2px;
      height: 100%;
    }

    :host([horizontal]) {
      width: 100%;
    }

    :host([horizontal])::part(separator) {
      width: 100%;
      height: 3px;
    }
  `;
__decorate([
    property({ type: Boolean, reflect: true })
], A3dSeparator.prototype, "horizontal", void 0);
A3dSeparator = __decorate([
    customElement('a3d-separator')
], A3dSeparator);
export { A3dSeparator };
