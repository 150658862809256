/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { RenderLevel } from '@a3d-viewer/renderer-types';
import { HardwareScalingOptimization, PostProcessesOptimization, RenderTargetsOptimization, SceneOptimizer, SceneOptimizerOptions, ShadowsOptimization, TextureOptimization, } from '@babylonjs/core/Misc/sceneOptimizer';
class Optimization {
    constructor(scene) {
        this.scene = null;
        this.sceneOptimizer = null;
        if (!scene) {
            throw new Error('No scene');
        }
        this.scene = scene;
        this.sceneOptimizer = new SceneOptimizer(this.scene);
    }
    getOptimizerOptions(renderLevel) {
        switch (renderLevel) {
            case RenderLevel.QUALITY:
                return this.getQualityOptimizerOptions();
            case RenderLevel.AVERAGE:
                return this.getAverageOptimizerOptions();
            case RenderLevel.PERFORMANCE:
                return this.getPerformanceOptimizerOptions();
            default:
                throw new Error(`Invalid render level: ${renderLevel}`);
        }
    }
    getQualityOptimizerOptions() {
        const optimizerOption = new SceneOptimizerOptions(30, 1000);
        var priority = 0;
        optimizerOption.addOptimization(new ShadowsOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new PostProcessesOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new TextureOptimization(priority, 1024));
        return optimizerOption;
    }
    getAverageOptimizerOptions() {
        const optimizerOption = new SceneOptimizerOptions(30, 1000);
        var priority = 0;
        optimizerOption.addOptimization(new ShadowsOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new PostProcessesOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new TextureOptimization(priority, 512));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new RenderTargetsOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new HardwareScalingOptimization(priority, 2));
        return optimizerOption;
    }
    getPerformanceOptimizerOptions() {
        const optimizerOption = new SceneOptimizerOptions(60, 1000);
        var priority = 0;
        optimizerOption.addOptimization(new ShadowsOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new PostProcessesOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new TextureOptimization(priority, 512));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new RenderTargetsOptimization(priority));
        // Next priority
        priority++;
        optimizerOption.addOptimization(new HardwareScalingOptimization(priority, 4));
        return optimizerOption;
    }
    logOptimizationResult() {
        if (!this.sceneOptimizer) {
            throw new Error('No scene optimizer');
        }
        this.sceneOptimizer.onSuccessObservable.add(() => {
            console.debug(` Optimization succeed, current FPS: ${this.sceneOptimizer?.currentFrameRate}`);
        });
        this.sceneOptimizer.onFailureObservable.add(() => {
            console.debug(` Optimization failed, current FPS: ${this.sceneOptimizer?.currentFrameRate}`);
        });
        this.sceneOptimizer.onNewOptimizationAppliedObservable.add((optim) => {
            console.log('New optimization applied', optim.getDescription());
        });
    }
    start(renderLevel) {
        if (!this.scene)
            return;
        if (!this.sceneOptimizer) {
            const optimizerOption = this.getOptimizerOptions(renderLevel);
            this.sceneOptimizer = new SceneOptimizer(this.scene, optimizerOption, false);
        }
        this.logOptimizationResult();
        this.sceneOptimizer.start();
    }
    reset() {
        this.sceneOptimizer?.reset();
    }
    stop() {
        this.reset();
        this.sceneOptimizer?.stop();
        this.sceneOptimizer?.dispose();
        this.sceneOptimizer = null;
    }
}
export default Optimization;
