/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

const meshUrl = 'https://cdn.substance3d.com/v2/files/public/SheenChair.glb'

import '@a3d-viewer/viewer'
import '@a3d-viewer/scene'
import '@a3d-viewer/mesh'

function Minimum() {
  return (
    <a3d-viewer>
      <a3d-scene>
        <a3d-mesh src={meshUrl}></a3d-mesh>
      </a3d-scene>
    </a3d-viewer>
  )
}

export default Minimum
