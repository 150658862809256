/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { WARN_RESOURCE_NOT_INITIALIZED, } from '@a3d-viewer/renderer-types';
import { PointLight } from '@babylonjs/core/Lights/pointLight';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BabylonResource } from './BabylonResource';
/**
 * Light resource class for Babylon resolver
 */
class LightResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
    }
    dispose() {
        if (!this.resource) {
            return;
        }
        this.resource.dispose();
        this.resolver.scene?.removeLight(this.resource);
    }
    getRandomVector3() {
        const x = Math.random() * 2 - 1 * 2;
        const y = Math.random() * 2 - 1 * 2;
        const z = Math.random() * 2 - 1 * 2;
        return new Vector3(x, y, z);
    }
    async update() {
        if (!this.resource) {
            // TODO Support other light types
            // TODO Support light parameters
            this.resource = new PointLight(this.id, this.getRandomVector3(), this.resolver.scene);
            this.resource.intensity = 10;
        }
    }
    setRelation(parent) {
        if (!this.resource) {
            console.warn('Setting parent', WARN_RESOURCE_NOT_INITIALIZED);
            return;
        }
        this.resource.parent = parent.getObjectResource();
    }
    getObjectResource() {
        return this.resource;
    }
    get state() {
        return {};
    }
}
export { LightResource };
