/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { BabylonResource } from './BabylonResource';
/**
 * Node resource class for Babylon resolver
 */
class NodeResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
        this.resource = new TransformNode(this.id, this.resolver.scene);
    }
    getObjectResource() {
        return this.resource;
    }
    async update(_config) { }
    dispose() {
        this.resource?.dispose();
        this.resolver.nodeDisposed(this.id);
    }
    setRelation(parent) {
        if (parent.rootNode) {
            this.resource?.setParent(parent.rootNode);
        }
    }
    get state() {
        if (!this.resource) {
            return {};
        }
        return { position: this.resource.position };
    }
}
export { NodeResource };
