/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement, css, html } from 'lit';
import { customElement, property } from 'lit/decorators.js';
let A3dButton = class A3dButton extends LitElement {
    constructor() {
        super(...arguments);
        this.selected = false;
        this.value = '';
        this.onClick = (e) => {
            e.preventDefault();
            e.stopPropagation();
            this.dispatchEvent(new CustomEvent('click', { bubbles: true, detail: this.value }));
        };
    }
    render() {
        return html `
      <button @click=${this.onClick}>
        <slot name="icon"></slot>
        <slot></slot>
      </button>
    `;
    }
};
A3dButton.styles = css `
    :host {
      /* vars */
      --spectrum-actionbutton-background-color-default: var(
        --system-spectrum-actionbutton-quiet-background-color-default,
        transparent
      );
      --spectrum-actionbutton-border-color-default: var(
        --system-spectrum-actionbutton-quiet-border-color-default,
        transparent
      );
      --spectrum-actionbutton-content-color-default: var(
        --spectrum-high-contrast-button-text,
        var(
          --spectrum-actionbutton-text-color,
          var(--spectrum-alias-text-color)
        )
      );
    }

    :host(:hover) {
      --spectrum-actionbutton-background-color-default: var(
        --spectrum-actionbutton-background-color-hover,
        #e6e6e6
      );
    }

    :host([selected]) {
      --spectrum-actionbutton-background-color-default: var(
        --mod-actionbutton-background-color-default-selected,
        var(--spectrum-neutral-background-color-selected-default, #464646)
      );
      --spectrum-actionbutton-content-color-default: var(
        --highcontrast-actionbutton-content-color-default,
        var(--mod-actionbutton-content-color-default, #ffffff)
      );
    }

    :host([selected]:hover) {
      --spectrum-actionbutton-background-color-default: var(
        --highcontrast-actionbutton-background-color-hover,
        var(
          --mod-actionbutton-background-color-hover,
          var(--spectrum-actionbutton-background-color-hover, #222222)
        )
      );
    }

    button {
      border-style: solid;
      border-width: 1px;

      border-radius: 8px;
      width: 40px;
      height: 40px;
      padding: 0 11px;
      box-sizing: border-box;
      display: inline-flex;
      align-items: center;
      justify-content: center;

      background-color: var(--spectrum-actionbutton-background-color-default);
      border-color: var(--spectrum-actionbutton-border-color-default);
      color: var(--spectrum-actionbutton-content-color-default);

      cursor: pointer;
    }

    slot[name='icon'] {
      color: inherit;
      fill: currentColor;
    }
  `;
__decorate([
    property({ type: Boolean })
], A3dButton.prototype, "selected", void 0);
__decorate([
    property({ type: String })
], A3dButton.prototype, "value", void 0);
A3dButton = __decorate([
    customElement('a3d-button')
], A3dButton);
export { A3dButton };
