/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
  ActionButton,
  Button,
  Flex,
  Heading,
  NumberField,
} from '@adobe/react-spectrum'
import { useAtom } from 'jotai'
import { useState } from 'react'
import { FormItem } from '../components/FormItem'
import { DefaultViewerConfig, viewerAtom } from '../config'

import RotateCCWIcon from '@spectrum-icons/workflow/RotateCCW'
import RotateCWIcon from '@spectrum-icons/workflow/RotateCW'

const Stepper = ({
  label,
  defaultValue,
  value,
  onChange,
  step = 0.001,
  shiftStep = 1,
}: {
  label: string
  defaultValue: number
  value: number
  onChange: (axis: string, value: number) => void
  step?: number
  shiftStep?: number
}) => {
  const [shiftKeyPressed, setShiftKeyPressed] = useState(false)

  const handleOnChange = (newValue: number) => {
    const shiftStepValue = newValue < value ? -shiftStep : shiftStep
    onChange(
      label.toLocaleLowerCase(),
      shiftKeyPressed ? value + shiftStepValue : newValue
    )
  }

  return (
    <NumberField
      value={value}
      onChange={handleOnChange}
      defaultValue={defaultValue}
      label={label}
      step={step}
      onKeyUp={(e) => {
        if (e.key === 'Shift') {
          setShiftKeyPressed(false)
        }
        if (e.key === 'ArrowUp' && shiftKeyPressed) {
          handleOnChange(value + step)
        }
        if (e.key === 'ArrowDown' && shiftKeyPressed) {
          handleOnChange(value - step)
        }
      }}
      onKeyDown={(e) => {
        if (e.key === 'Shift') {
          setShiftKeyPressed(true)
        }
      }}
      onBlur={() => setShiftKeyPressed(false)}
      width={'size-1250'}
    />
  )
}

const Rotate45 = ({
  value,
  onChange,
}: {
  value: number
  onChange: (value: number) => void
}) => {
  function handleOnChange(newValue: number) {
    //@ts-ignore
    onChange(value + newValue)
  }

  return (
    <Flex justifyContent="space-around">
      <ActionButton
        isQuiet
        onPress={() => handleOnChange(-45)}
        aria-label="rotation -45°"
      >
        <RotateCCWIcon aria-label="rotation -45° icon" />
      </ActionButton>
      <ActionButton
        isQuiet
        onPress={() => handleOnChange(45)}
        aria-label="rotation +45°"
      >
        <RotateCWIcon aria-label="rotation +45° icon" />
      </ActionButton>
    </Flex>
  )
}

function rad2deg(rad: number) {
  return (rad * 180) / Math.PI
}

function deg2rad(deg: number) {
  return (deg * Math.PI) / 180
}

function ModelPanel() {
  const [atom, setAtom] = useAtom(viewerAtom)

  const handleMeshTranslationChange = (axis: string, value: number) => {
    const translation = atom.mesh.translation
    // @ts-ignore
    translation[axis] = value
    setAtom({ ...atom, mesh: { ...atom.mesh, translation } })
  }

  const handleMeshRotationChange = (axis: string, value: number) => {
    const rotation = atom.mesh.rotation
    // @ts-ignore
    rotation[axis] = deg2rad(value)
    setAtom({ ...atom, mesh: { ...atom.mesh, rotation } })
  }

  const handleReset = () => {
    setAtom((prev) => ({
      ...prev,
      // did not want to work with DefaultViewerConfig directly
      mesh: {
        translation: { x: 0, y: 0, z: 0 },
        rotation: { x: 0, y: 0, z: 0 },
      },
    }))
  }

  return (
    <>
      <Heading level={1}>
        <Flex justifyContent="space-between" alignItems="center">
          <span>Model</span>
          <Button variant="primary" onPress={handleReset}>
            Reset
          </Button>
        </Flex>
      </Heading>
      <Heading level={2}>Position</Heading>
      <Flex gap="size-100">
        <FormItem>
          <Stepper
            label="X"
            defaultValue={DefaultViewerConfig.mesh.translation.x}
            value={atom.mesh.translation.x}
            onChange={handleMeshTranslationChange}
          />
        </FormItem>
        <FormItem>
          <Stepper
            label="Y"
            defaultValue={DefaultViewerConfig.mesh.translation.y}
            value={atom.mesh.translation.y}
            onChange={handleMeshTranslationChange}
          />
        </FormItem>
        <FormItem>
          <Stepper
            label="Z"
            defaultValue={DefaultViewerConfig.mesh.translation.z}
            value={atom.mesh.translation.z}
            onChange={handleMeshTranslationChange}
          />
        </FormItem>
      </Flex>
      <Heading level={2}>Rotation</Heading>
      <Flex gap="size-100">
        <FormItem>
          <Flex gap="size-100" direction="column">
            <Stepper
              step={0.01}
              label="X"
              defaultValue={rad2deg(DefaultViewerConfig.mesh.rotation.x)}
              value={rad2deg(atom.mesh.rotation.x)}
              onChange={handleMeshRotationChange}
            />
            <Rotate45
              value={rad2deg(atom.mesh.rotation.x)}
              onChange={(value: number) => handleMeshRotationChange('x', value)}
            />
          </Flex>
        </FormItem>
        <FormItem>
          <Flex gap="size-100" direction="column">
            <Stepper
              step={0.1}
              label="Y"
              defaultValue={rad2deg(DefaultViewerConfig.mesh.rotation.y)}
              value={rad2deg(atom.mesh.rotation.y)}
              onChange={handleMeshRotationChange}
            />
            <Rotate45
              value={rad2deg(atom.mesh.rotation.y)}
              onChange={(value: number) => handleMeshRotationChange('y', value)}
            />
          </Flex>
        </FormItem>
        <FormItem>
          <Flex gap="size-100" direction="column">
            <Stepper
              step={0.1}
              label="Z"
              defaultValue={rad2deg(DefaultViewerConfig.mesh.rotation.z)}
              value={rad2deg(atom.mesh.rotation.z)}
              onChange={handleMeshRotationChange}
            />
            <Rotate45
              value={rad2deg(atom.mesh.rotation.z)}
              onChange={(value: number) => handleMeshRotationChange('z', value)}
            />
          </Flex>
        </FormItem>
      </Flex>
    </>
  )
}

export { ModelPanel }
