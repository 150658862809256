/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
const WARN_RESOURCE_NOT_INITIALIZED = 'Resource not initialized';
/**
 * List of any events that can be dispatched by any resolver
 */
const RendererEvents = {
    // Event dispatched when the camera is created
    DEFAULT_CAMERA_CREATED: 'default-camera-created',
    // Event dispatched when the camera is updated
    CAMERA_UPDATED: 'camera-updated',
    // Event dispatched when the camera matrix is updated
    CAMERA_MATRIX_UPDATED: 'camera-matrix-updated',
    // Event dispatched when the camera is disposed
    CAMERA_DISPOSED: 'camera-disposed',
    // Event dispatched when the environment is being created
    ENV_CREATE: 'env-create',
    // Event dispatched when the environment is updated
    ENV_UPDATED: 'env-updated',
    // Event dispatched when the environment is disposed
    ENV_DISPOSED: 'env-disposed',
    // Event dispatched when the environment is ready, displaying the mesh
    ENV_READY: 'env-ready',
    // Event dispatched when the scene is updated
    SCENE_UPDATED: 'scene-updated',
    // Event dispatched when the scene is disposed
    SCENE_DISPOSED: 'scene-disposed',
    // Event dispatched when the node is updated
    NODE_UPDATED: 'node-updated',
    // Event dispatched when the node is disposed
    NODE_DISPOSED: 'node-disposed',
    // Event dispatched when the transform is disposed
    TRANSFORM_DISPOSED: 'transform-disposed',
    // Event dispatched when a mesh is loading
    MESH_PROGRESS: 'mesh-progress',
    // Event dispatched when a mesh is imported
    MESH_IMPORTED: 'mesh-imported',
    // Event dispatched when a mesh is updated
    MESH_UPDATED: 'mesh-updated',
    // Event dispatched when a mesh is disposed
    MESH_DISPOSED: 'mesh-disposed',
    // Event dispatched when a hotspot is updated
    HOTSPOT_UPDATED: 'hotspot-updated',
    // Event dispatched when a hotspot is deleted
    HOTSPOT_DELETED: 'hotspot-deleted',
};
var HotspotType;
(function (HotspotType) {
    HotspotType["position"] = "position";
    HotspotType["surface"] = "surface";
})(HotspotType || (HotspotType = {}));
var MeshInputAnimationStatus;
(function (MeshInputAnimationStatus) {
    MeshInputAnimationStatus["PLAY"] = "play";
    MeshInputAnimationStatus["PAUSE"] = "pause";
})(MeshInputAnimationStatus || (MeshInputAnimationStatus = {}));
var MeshLoader;
(function (MeshLoader) {
    MeshLoader["GLB"] = "glb";
    MeshLoader["PLY"] = "ply";
    MeshLoader["SPLAT"] = "splat";
})(MeshLoader || (MeshLoader = {}));
var PointerModes;
(function (PointerModes) {
    PointerModes["ORBIT"] = "orbit";
    PointerModes["PAN"] = "pan";
    PointerModes["ZOOM"] = "zoom";
    PointerModes["ENV"] = "env";
})(PointerModes || (PointerModes = {}));
var POST_EFFECTS;
(function (POST_EFFECTS) {
    POST_EFFECTS[POST_EFFECTS["SHADOWS"] = 0] = "SHADOWS";
    POST_EFFECTS[POST_EFFECTS["SSAO"] = 1] = "SSAO";
    POST_EFFECTS[POST_EFFECTS["ANTIALIASING"] = 2] = "ANTIALIASING";
    POST_EFFECTS[POST_EFFECTS["DEPTH"] = 3] = "DEPTH";
    POST_EFFECTS[POST_EFFECTS["GRID"] = 4] = "GRID";
    POST_EFFECTS[POST_EFFECTS["SHADOWS_IBL"] = 5] = "SHADOWS_IBL";
})(POST_EFFECTS || (POST_EFFECTS = {}));
var RenderLevel;
(function (RenderLevel) {
    RenderLevel["QUALITY"] = "quality";
    RenderLevel["AVERAGE"] = "average";
    RenderLevel["PERFORMANCE"] = "performance";
})(RenderLevel || (RenderLevel = {}));
class PostEffect {
}
export { WARN_RESOURCE_NOT_INITIALIZED, RendererEvents, POST_EFFECTS, PostEffect, HotspotType, MeshLoader, MeshInputAnimationStatus, RenderLevel, PointerModes, };
