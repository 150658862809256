/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { BabylonResource } from './BabylonResource';
class InstantiatedEntriesResource extends BabylonResource {
    constructor(id, uuid, resolver, container) {
        super(id, uuid, resolver);
        this.resource = container.instantiateModelsToScene();
    }
    getObjectResource() {
        return this.resource;
    }
    async update(_config) {
        // TODO Implement update
    }
    dispose() {
        this.resource?.dispose();
    }
    setRelation(parent) {
        const transformNode = parent.getObjectResource();
        if (transformNode) {
            this.resource.rootNodes.forEach((node) => {
                node.parent = transformNode;
            });
        }
    }
    get state() {
        throw new Error('Method not implemented.');
    }
}
export { InstantiatedEntriesResource };
