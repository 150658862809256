/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
/**
 * Compute the target of the camera based on the bounding box of the scene
 * @param boundingBox The bounding box of the scene
 * @returns The target of the camera
 */
export function cameraTargetBySceneBoundingBox(boundingBox) {
    const target = [
        (boundingBox.max[0] - Math.abs(boundingBox.min[0])) / 2,
        (boundingBox.max[1] - Math.abs(boundingBox.min[1])) / 2,
        (boundingBox.max[2] - Math.abs(boundingBox.min[2])) / 2,
    ];
    return target;
}
export function cameraRadiusLimitBySceneDimension(sceneBox, minCoef = 1.5, maxCoef = 2.5) {
    const min = Math.min(...sceneBox);
    const max = Math.max(...sceneBox);
    return [min * minCoef, max * maxCoef];
}
/**
 * Compute the polar coordinates from a 3D position
 * https://en.wikipedia.org/wiki/Vector_fields_in_cylindrical_and_spherical_coordinates#Vector_fields_2
 * @param v the position vector
 * @returns the orbit coordinates
 */
export function positionToOrbit(v, target) {
    const x = v[0] - target[0];
    const y = v[1] - target[1];
    const z = v[2] - target[2];
    const radius = Math.sqrt(x * x + y * y + z * z);
    const alpha = Math.atan2(z, x);
    const beta = Math.acos(y / radius);
    return {
        radius,
        alpha,
        beta,
    };
}
/**
 * Compute the camera configuration from differents inputs
 * @param sceneDimension
 * @param sceneBoundingBox
 * @param defaultValues
 * @param input
 * @returns
 */
export function cameraConfigFromInputs(sceneDimension, sceneBoundingBox, cameraDefault, input) {
    const [minRadius, maxRadius] = cameraRadiusLimitBySceneDimension(sceneDimension, input?.minLimit, input?.maxLimit);
    const fittedTarget = cameraTargetBySceneBoundingBox(sceneBoundingBox);
    if (!input) {
        return {
            ...cameraDefault,
            target: fittedTarget,
            radius: minRadius,
            minRadius,
            maxRadius,
        };
    }
    const computedTarget = input.target
        ? input.target
        : fittedTarget;
    let alpha = cameraDefault.alpha, beta = cameraDefault.beta, radius = maxRadius;
    if (input.position) {
        let computedPosition = positionToOrbit(input.position, computedTarget);
        alpha = computedPosition.alpha;
        beta = computedPosition.beta;
        radius = computedPosition.radius;
    }
    if (!input.limitDisabled) {
        radius = Math.max(Math.min(radius, maxRadius), minRadius);
    }
    return {
        target: computedTarget,
        alpha,
        beta,
        radius,
        minRadius,
        maxRadius,
        far: input.far ? input.far : cameraDefault.far,
        near: input.near ? input.near : cameraDefault.near,
        fov: input.fov,
    };
}
