/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { ArcRotateCamera } from '@babylonjs/core/Cameras/arcRotateCamera';
import { PointerModes, WARN_RESOURCE_NOT_INITIALIZED, } from '@a3d-viewer/renderer-types';
import _isFinite from 'lodash/isFinite';
import { isUserMesh } from './MeshResource';
import { A3dArcRotateCameraPointersInput } from '../A3dArcRotateCameraPointersInput';
import { CameraResource } from './CameraResource';
/**
 * Camera resource class for Babylon resolver
 */
export class RotateCameraResource extends CameraResource {
    constructor(id, uuid, resolver, config) {
        super(id, uuid, resolver);
        this.resource = new ArcRotateCamera(this.id, config.alpha, config.beta, config.radius, config.target, undefined, true);
        this.resource.minZ = config.near;
        this.resource.maxZ = config.far;
        // TODO : If activated, fix inifite render loop ViezMatrix -> State -> ViewMatrix
        // this.resource.onViewMatrixChangedObservable.add(() => {
        //   this.resolver.cameraMatrixUpdated()
        // })
        // add custom inputs to the camera to be able to switch between pointer modes
        this.resource.inputs.removeByType('ArcRotateCameraPointersInput');
        this.inputs = new A3dArcRotateCameraPointersInput(this.resource);
        this.resource.inputs.add(this.inputs);
        // inspired by babylon.js createDefaultCamera() from class Scene
        // it is used to calculate the speed, panningSensibility and wheelPrecision based world size
        if (this.resolver.scene) {
            if (this.resolver.scene.meshes.length) {
                const worldExtends = this.resolver.scene.getWorldExtends(isUserMesh);
                const worldSize = worldExtends.max.subtract(worldExtends.min);
                const worldCenter = worldExtends.min.add(worldSize.scale(0.5));
                let radius = worldSize.length() * 1.5;
                // empty scene scenario!
                if (!isFinite(radius)) {
                    radius = 1;
                    worldCenter.copyFromFloats(0, 0, 0);
                }
                // copy from babylon.js code see _configureCamera() from class SceneManager of the viewer
                this.resource.speed = radius * 0.2;
                // copy from babylon.js code see zoomOnBoundingInfo() from class FramingBehavior
                this.resource.wheelPrecision = 100 / radius;
                const extend = worldExtends.max.subtract(worldExtends.min).length();
                this.resource.panningSensibility = 5000 / extend;
            }
        }
        this.initialState = {
            target: this.resource.target.clone(),
            alpha: this.resource.alpha,
            beta: this.resource.beta,
            radius: this.resource.radius,
        };
    }
    async update({ target, fov, near, far, radius, alpha, beta, }) {
        if (target) {
            this.resource.target = target;
        }
        if (fov !== undefined && _isFinite(fov)) {
            this.resource.fov = fov;
        }
        if (_isFinite(near)) {
            this.resource.minZ = near;
        }
        if (_isFinite(far)) {
            this.resource.maxZ = far;
        }
        if (_isFinite(radius)) {
            this.resource.radius = radius;
        }
        if (_isFinite(alpha)) {
            this.resource.alpha = alpha;
        }
        if (_isFinite(beta)) {
            this.resource.beta = beta;
        }
        this.resource.lowerRadiusLimit = 0;
        this.resolver.cameraUpdated();
    }
    get pointerMode() {
        return this.inputs.pointerMode || PointerModes.ORBIT;
    }
    set pointerMode(mode) {
        var _a;
        (_a = this.inputs).pointerMode && (_a.pointerMode = mode);
    }
    dispose() {
        this.resource.dispose();
        this.resolver.scene?.removeCamera(this.resource);
        this.resolver.cameraDisposed(this.id);
    }
    setRelation(parent) {
        if (!this.resource) {
            console.warn('Setting parent', WARN_RESOURCE_NOT_INITIALIZED);
            return;
        }
        this.resource.parent = parent.getObjectResource();
    }
    setObjectResource(resource) {
        this.resource = resource;
    }
    getObjectResource() {
        return this.resource;
    }
    reset() {
        if (!this.initialState || !this.resource) {
            return;
        }
        this.resource.target = this.initialState.target.clone();
        this.resource.alpha = this.initialState.alpha;
        this.resource.beta = this.initialState.beta;
        this.resource.radius = this.initialState.radius;
    }
    get state() {
        function negativeMod(value, mod) {
            return (mod + (value % mod)) % mod;
        }
        const cameraPosition = this.resource.position;
        const alpha = this.resource.alpha;
        const beta = this.resource.beta;
        return {
            id: this.id,
            position: cameraPosition.asArray(),
            target: this.resource.target.asArray(),
            fov: this.resource.fov,
            minZ: this.resource.minZ,
            maxZ: this.resource.maxZ,
            radius: this.resource.radius,
            alpha: negativeMod(alpha, Math.PI * 2),
            beta: negativeMod(beta, Math.PI * 2),
            matrix: [...this.resource.getWorldMatrix().asArray()],
        };
    }
}
