/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { HotspotType, POST_EFFECTS, RendererEvents, } from '@a3d-viewer/renderer-types';
import _map from 'lodash/map';
import _throttle from 'lodash/throttle';
import _isEmpty from 'lodash/isEmpty';
import { EnvError, EnvErrorMessages } from './errors';
import { A3dResolverErrorNames, dispatchErrorEvent, dispatchHotSpotCreateEvent, dispatchHotSpotDeleteEvent, dispatchResourceLoaded, dispatchResourceProgress, dispatchSceneInitializing, dispatchSceneReady, dispatchSceneUpdate, dispatchViewerUpdatedEvent, ViewerEvents, } from './events';
import { HotspotEvents } from './hotspot';
import { whenAllSync } from './utils';
import { CameraManager } from './CameraManager';
import { SHADOWS } from './types';
const DEFAULT_ENVIRONMENT_ID = 'default-env';
const DEFAULT_ENVIRONMENT_URL = 'https://cdn.substance3d.com/v2/files/public/studio-white.env';
const DEFAULT_CAMERA_ID = 'default-camera';
const POST_EFFECTS_DEBOUNCE_TIME = 33;
class A3dResolver {
    constructor(viewerElement, renderer, cameraManager) {
        this.initialized = false;
        this.elements = {};
        this.state = {};
        this.needCameraRefresh = false;
        /**
         * List of elements ids that are currently being loaded
         */
        this.loadingQueueId = [];
        this.viewerElement = viewerElement;
        this.renderer = renderer;
        this.cameraManager = cameraManager
            ? cameraManager
            : new CameraManager(renderer);
        this.postEffectsDebounce = _throttle((effects) => {
            this.renderer.postEffect(effects);
        }, POST_EFFECTS_DEBOUNCE_TIME);
        this.renderer.canvas.addEventListener(RendererEvents.CAMERA_UPDATED, (e) => {
            const customEvent = e;
            this.state = { ...this.state, camera: customEvent.detail };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.CAMERA_DISPOSED, (e) => {
            const customEvent = e;
            // only 1 camera ATM so we can reset the state & delete the camera in elements
            if (this.elements)
                delete this.elements[customEvent.detail.id];
            this.state = { ...this.state, camera: undefined };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.ENV_UPDATED, (e) => {
            const customEvent = e;
            this.state = { ...this.state, env: customEvent.detail };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.ENV_DISPOSED, (e) => {
            const customEvent = e;
            // only 1 env ATM so we can reset the state & delete the environment in elements
            if (this.elements)
                delete this.elements[customEvent.detail.id];
            this.state = { ...this.state, env: undefined };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.SCENE_UPDATED, (e) => {
            const customEvent = e;
            this.state = { ...this.state, scene: customEvent.detail };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.SCENE_DISPOSED, (e) => {
            const customEvent = e;
            // only 1 scene ATM so we can reset the state & delete the scene in elements
            if (this.elements)
                delete this.elements[customEvent.detail.id];
            this.state = { ...this.state, scene: undefined };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.NODE_UPDATED, (e) => {
            const customEvent = e;
            this.state = {
                ...this.state,
                nodes: { ...this.state.nodes, ...customEvent.detail },
            };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.NODE_DISPOSED, (e) => {
            const customEvent = e;
            let nodes = this.state.nodes;
            if (nodes)
                delete nodes[customEvent.detail.id];
            if (this.elements)
                delete this.elements[customEvent.detail.id];
            // to remove the nodes key entry in state if it is empty
            if (_isEmpty(nodes))
                nodes = undefined;
            this.state = {
                ...this.state,
                nodes,
            };
            dispatchViewerUpdatedEvent(this.viewerElement, { ...this.state });
        });
        this.renderer.canvas.addEventListener(RendererEvents.TRANSFORM_DISPOSED, (e) => {
            const customEvent = e;
            if (this.elements)
                delete this.elements[customEvent.detail.id];
            // state updated by node updated event
        });
        this.renderer.canvas.addEventListener(RendererEvents.MESH_PROGRESS, (e) => {
            const customEvent = e;
            dispatchResourceProgress(this.viewerElement, customEvent.detail.meshId, 'mesh', customEvent.detail.progress);
        });
        this.renderer.canvas.addEventListener(RendererEvents.MESH_IMPORTED, (e) => {
            const customEvent = e;
            console.debug('mesh imported', customEvent.detail.meshId);
            dispatchResourceLoaded(this.viewerElement, customEvent.detail.meshId, 'mesh');
        });
        this.renderer.canvas.addEventListener(RendererEvents.MESH_UPDATED, (e) => {
            const customEvent = e;
            this.state = {
                ...this.state,
                meshes: { ...this.state.meshes, ...customEvent.detail },
            };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.MESH_DISPOSED, (e) => {
            const customEvent = e;
            let meshes = this.state.meshes;
            // meshes could be undefined if the mesh is not loaded in the scene
            if (meshes)
                delete meshes[customEvent.detail.id];
            if (this.elements)
                delete this.elements[customEvent.detail.id];
            // to remove the meshes key entry in state if it is empty
            if (_isEmpty(meshes))
                meshes = undefined;
            this.state = {
                ...this.state,
                meshes,
            };
            dispatchViewerUpdatedEvent(this.viewerElement, { ...this.state });
        });
        this.renderer.canvas.addEventListener(RendererEvents.HOTSPOT_UPDATED, (e) => {
            const customEvent = e;
            this.state = {
                ...this.state,
                hotspots: { ...this.state.hotspots, ...customEvent.detail },
            };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.renderer.canvas.addEventListener(RendererEvents.HOTSPOT_DELETED, (e) => {
            const customEvent = e;
            let hotspots = this.state.hotspots;
            if (this.elements)
                delete this.elements[customEvent.detail];
            if (hotspots)
                delete hotspots[customEvent.detail];
            // to remove the hotspots key entry in state if it is empty
            if (_isEmpty(hotspots))
                hotspots = undefined;
            this.state = {
                ...this.state,
                hotspots,
            };
            dispatchViewerUpdatedEvent(this.viewerElement, this.state);
        });
        this.viewerElement.addEventListener(HotspotEvents.HOTSPOT_DELETE_CONTAINER, (e) => {
            e.stopImmediatePropagation();
            dispatchHotSpotDeleteEvent(this.viewerElement, e);
        });
        this.viewerElement.addEventListener(ViewerEvents.RESET_CAMERA, this.resetCamera.bind(this));
        this.viewerElement.addEventListener(ViewerEvents.HOTSPOT_ADD, this.addHotspot.bind(this));
        this.viewerElement.addEventListener(ViewerEvents.HOTSPOT_FOCUS, (e) => {
            const customEvent = e;
            this.focusHotspot(customEvent.detail.id, customEvent.detail.instantSet);
        });
        this.viewerElement.addEventListener(ViewerEvents.MESH_FOCUS, () => {
            this.focusMesh();
        });
    }
    resize() {
        this.renderer.resize();
    }
    resizeWithRender() {
        this.renderer.resizeWithRender();
    }
    /**
     * Create a default camera if none has been created
     */
    createDefaultCamera() {
        const cameraResource = this.renderer.createCamera(DEFAULT_CAMERA_ID, crypto.randomUUID(), {});
        this.elements[DEFAULT_CAMERA_ID] = cameraResource;
        this.renderer.canvas.dispatchEvent(new CustomEvent(RendererEvents.DEFAULT_CAMERA_CREATED, {
            detail: { id: DEFAULT_CAMERA_ID },
        }));
        return cameraResource;
    }
    /**
     * create a default environment if none has been created
     */
    createDefaultEnvironment() {
        const envResource = this.renderer.createEnv(DEFAULT_ENVIRONMENT_ID, crypto.randomUUID());
        envResource.update({
            src: DEFAULT_ENVIRONMENT_URL,
        });
        this.elements[DEFAULT_ENVIRONMENT_ID] = envResource;
        return envResource;
    }
    /**
     * Add an element to the loading queue
     * @param id The id of the element to add to the loading queue
     */
    addToLoadingQueue(id) {
        if (this.initialized && this.loadingQueueId.length === 0) {
            dispatchSceneUpdate(this.viewerElement);
        }
        this.loadingQueueId.push(id);
    }
    /**
     * Remove an element from the loading queue
     * @param id The id of the element to remove from the loading queue
     */
    removeFromLoadingQueue(id) {
        this.loadingQueueId = this.loadingQueueId.filter((e) => e !== id);
        if (this.initialized && this.loadingQueueId.length === 0) {
            dispatchSceneReady(this.viewerElement);
        }
    }
    async mapMeshes(meshes, parent) {
        for (let meshId in meshes) {
            const mesh = meshes[meshId];
            let resource = this.elements[mesh.id];
            const { data } = mesh;
            const loader = data?.loader || 'glb';
            if (!data) {
                return;
            }
            // no update if the mesh is already in the scene and the uuid is the same
            if (resource?.uuid === mesh.uuid)
                return;
            try {
                const updateProperties = {
                    src: data.src,
                    loader: loader,
                    selectedAnimation: data.selectedAnimation,
                    animationStatus: data.animationStatus,
                    disabledAnimations: data.disabledAnimations,
                };
                // Already exist and loaded
                // Just update the properties
                if (resource) {
                    await resource.update(updateProperties);
                    return;
                }
                // Add a new mesh to the scene
                // Dispatch add load event for the mesh
                this.addToLoadingQueue(mesh.id);
                // TODO do not add preloaded meshes to the scene again
                resource = this.renderer.createMesh(mesh.id, mesh.uuid, loader);
                this.elements[mesh.id] = resource;
                await resource.update(updateProperties);
                resource?.setRelation(parent);
                // end loading event
                this.removeFromLoadingQueue(mesh.id);
                if (this.initialized) {
                    this.needCameraRefresh = true;
                }
            }
            catch (e) {
                dispatchErrorEvent(this.viewerElement, e);
            }
        }
    }
    async mapLights(lights) {
        await Promise.all(_map(lights, async (lightDescription) => {
            if (!this.elements[lightDescription.id]) {
                const lightResource = this.renderer.createLight(lightDescription.id, lightDescription.uuid);
                this.elements[lightDescription.id] = lightResource;
            }
            await this.elements[lightDescription.id].update();
            // TODO
            // lightResource.setRelation(sceneResource)
        }));
    }
    async mapCameras(cameras) {
        await Promise.all(_map(cameras, async (cameraDescription) => {
            const config = {
                position: cameraDescription.data?.position,
                target: cameraDescription.data?.target,
                fov: cameraDescription.data?.fov,
                near: cameraDescription.data?.near,
                far: cameraDescription.data?.far,
                minLimit: cameraDescription.data?.minLimit,
                maxLimit: cameraDescription.data?.maxLimit,
                limitDisabled: cameraDescription.data?.limitDisabled,
                cameraId: cameraDescription.data?.cameraId,
            };
            let camera = this.elements[cameraDescription.id];
            if (cameraDescription.uuid === camera?.uuid) {
                return;
            }
            if (!camera) {
                const cameraResource = this.renderer.createCamera(cameraDescription.id, cameraDescription.uuid, config);
                camera = cameraResource;
                this.elements[cameraDescription.id] = camera;
            }
            else {
                this.renderer.updateCamera(camera, config);
            }
            if (cameraDescription.data?.active) {
                this.nextActiveCamera = camera;
            }
        }));
    }
    async mapEnv(envs) {
        await Promise.all(_map(envs, async (envDescription) => {
            if (this.elements[envDescription.id]?.uuid === envDescription.uuid) {
                return;
            }
            try {
                if (!this.elements[envDescription.id]) {
                    const envResource = this.renderer.createEnv(envDescription.id, envDescription.uuid);
                    this.elements[envDescription.id] = envResource;
                }
            }
            catch (e) {
                dispatchErrorEvent(this.viewerElement, e);
            }
            try {
                await this.elements[envDescription.id].update({
                    src: envDescription.data?.src,
                    visible: envDescription.data?.visible,
                    blurLevel: envDescription.data?.blurLevel,
                    rotationY: envDescription.data?.rotationY,
                    intensity: envDescription.data?.intensity,
                });
            }
            catch (e) {
                dispatchErrorEvent(this.viewerElement, new EnvError(EnvErrorMessages.UPDATE_FAILED));
            }
            // TODO
            // envResource.setRelation(sceneResource)
        }));
    }
    async mapTransforms(transforms, nodeResource) {
        await Promise.all(_map(transforms, async (transformDescription) => {
            const { position, rotation } = transformDescription.data ?? {};
            if (!this.elements[transformDescription.id]) {
                const transformResource = this.renderer.createTransform(transformDescription.id, transformDescription.uuid);
                this.elements[transformDescription.id] = transformResource;
            }
            await this.elements[transformDescription.id].update({
                node: nodeResource,
                position: position.split(' ').map((p) => parseFloat(p)),
                rotation: rotation.split(' ').map((p) => parseFloat(p)),
            });
        }));
    }
    async mapHotspots(hotspots, nodeResource) {
        await Promise.all(_map(hotspots, async (hotspotDescritpion) => {
            if (!this.elements[hotspotDescritpion.id]) {
                const hotspotResource = this.renderer.createHotspot(hotspotDescritpion.id, hotspotDescritpion.uuid);
                this.elements[hotspotDescritpion.id] = hotspotResource;
                hotspotResource.setRelation(nodeResource);
            }
            try {
                await this.elements[hotspotDescritpion.id].update({
                    node: nodeResource,
                    position: hotspotDescritpion.data?.position,
                    surface: hotspotDescritpion.data?.surface,
                    detail: hotspotDescritpion.data?.detail,
                });
            }
            catch (error) {
                dispatchErrorEvent(this.viewerElement, error);
            }
        }));
    }
    async run(elements) {
        const scenes = elements.Scenes;
        const assets = elements.Assets;
        if (!scenes || Object.keys(scenes).length === 0) {
            console.error('No scenes found');
            return;
        }
        const scene = Object.values(scenes)[0];
        let sceneResource = this.elements[scene.id];
        // 1. Create the scene
        if (!this.initialized) {
            // Only read the first scene for now
            dispatchSceneInitializing(this.viewerElement);
            console.debug('create scene');
            sceneResource = this.renderer.createScene(scene.id, scene.uuid);
            this.elements[scene.id] = sceneResource;
            // Wait for the scene to be ready
            // If there is an environment, wait for it to be loaded as well
            const readinessEvents = [
                RendererEvents.MESH_IMPORTED,
                RendererEvents.ENV_READY,
            ];
            // Wait for all the assets (mesh & env) to be loaded
            whenAllSync(this.renderer.canvas, readinessEvents).then(() => {
                const cameras = this.renderer.cameras();
                console.debug('viewer is ready', this.state);
                this.state = {
                    ...this.state,
                    ready: true,
                    cameras,
                };
                this.sceneReady = true;
                dispatchViewerUpdatedEvent(this.viewerElement, this.state);
                dispatchSceneReady(this.viewerElement);
            });
            // Only create the default camera when the first mesh is imported
            // So the bounding box exists
            this.renderer.canvas.addEventListener(RendererEvents.MESH_IMPORTED, () => {
                const viewportCamera = this.createDefaultCamera();
                this.cameraManager.setViewportCamera(viewportCamera);
                this.state = {
                    ...this.state,
                    camera: viewportCamera.state,
                };
            }, { once: true });
            // Start the render loop when the default camera is created
            this.renderer.canvas.addEventListener(RendererEvents.DEFAULT_CAMERA_CREATED, () => {
                console.debug('start render loop');
                this.renderer.startRenderLoop();
            }, { once: true });
        }
        // 2. Preload assets
        if (assets && Object.keys(assets).length > 0) {
            const { Meshes: meshes } = Object.values(assets)[0];
            console.debug('preload assets');
            if (meshes) {
                for (const meshId in meshes) {
                    const mesh = meshes[meshId];
                    const { assetId } = mesh;
                    // TODO Handle assets deletion
                    if (assetId && this.elements[assetId] === undefined) {
                        //TODO: fix the empty string with uuid
                        const meshResource = this.renderer.preloadMesh(meshId, '');
                        await meshResource.update({
                            src: mesh.data?.src,
                            loader: mesh.data?.loader,
                            selectedAnimation: mesh.data?.selectedAnimation,
                            animationStatus: mesh.data?.animationStatus,
                            disabledAnimations: mesh.data?.disabledAnimations,
                        });
                        this.elements[assetId] = meshResource;
                    }
                }
            }
        }
        // 3. Update the scene
        console.debug('going throught the nodes of the scene');
        for (let nodeDescriptionId in scene.Nodes) {
            const nodeDescription = scene.Nodes[nodeDescriptionId];
            let nodeResource = this.elements[nodeDescription.id];
            if (!nodeResource) {
                nodeResource = this.renderer.createNode(nodeDescription.id, nodeDescription.uuid);
                this.elements[nodeDescription.id] = nodeResource;
                nodeResource.setRelation(sceneResource);
            }
            nodeDescription.Lights && (await this.mapLights(nodeDescription.Lights));
            if (nodeDescription.Meshes) {
                await this.mapMeshes(nodeDescription.Meshes, nodeResource);
            }
            nodeDescription.Cameras &&
                (await this.mapCameras(nodeDescription.Cameras));
            nodeDescription.Transform &&
                (await this.mapTransforms(nodeDescription.Transform, nodeResource));
            nodeDescription.Hotspot &&
                (await this.mapHotspots(nodeDescription.Hotspot, nodeResource));
        }
        scene.Lights && (await this.mapLights(scene.Lights));
        scene.Meshes && (await this.mapMeshes(scene.Meshes, sceneResource));
        scene.Cameras && (await this.mapCameras(scene.Cameras));
        scene.Transform &&
            (await this.mapTransforms(scene.Transform, sceneResource));
        scene.Hotspot && (await this.mapHotspots(scene.Hotspot, sceneResource));
        // Read the environments or create a default one
        if (scene.Environments && Object.entries(scene.Environments).length > 0) {
            await this.mapEnv(scene.Environments);
        }
        else {
            this.createDefaultEnvironment();
        }
        // 4. Update cameras if needed
        // Only executed for external active cameras
        if (this.nextActiveCamera) {
            this.cameraManager.setActiveCamera(this.nextActiveCamera);
            // When any camera is moved, we switch back to the viewport camera
            this.renderer.canvas.addEventListener(RendererEvents.CAMERA_UPDATED, () => {
                this.cameraManager.setActiveCamera(this.cameraManager.getViewportCamera());
            }, { once: true });
            // Set the flag to false for the next update
            this.nextActiveCamera = undefined;
        }
        // 5. Apply pointer mode if needed
        const viewportCamera = this.cameraManager.getViewportCamera();
        if (viewportCamera) {
            const pointerMode = scene.data?.pointerMode;
            this.renderer.setCameraPointerMode(viewportCamera, pointerMode);
        }
        // 6. Apply and update the post effect stack
        const effects = [];
        if (scene.data?.depthMap) {
            effects.push(POST_EFFECTS.DEPTH);
        }
        if (scene.data?.shadows === SHADOWS.PERFORMANCE) {
            effects.push(POST_EFFECTS.SHADOWS);
        }
        else if (scene.data?.shadows === SHADOWS.QUALITY) {
            effects.push(POST_EFFECTS.SHADOWS_IBL);
        }
        if (scene.data?.grid) {
            effects.push(POST_EFFECTS.GRID);
        }
        if (scene.data?.antialiasing) {
            effects.push(POST_EFFECTS.ANTIALIASING);
        }
        this.postEffectsDebounce(effects);
        sceneResource.update({
            color: scene.data?.color,
            renderLevel: scene.data?.renderLevel,
            pointerMode: scene.data?.pointerMode,
        });
        // 5. Start the loop
        console.debug('start the loop');
        if (!this.initialized) {
            this.initialized = true;
        }
        this.renderer.runOptimization?.(sceneResource.state?.renderLevel);
        this.renderer.startRenderLoop();
    }
    disposeElement(id) {
        this.elements[id]?.dispose();
    }
    resetCamera() {
        this.viewportCamera && this.renderer.frameCamera(this.viewportCamera);
    }
    focusMesh() {
        const info = this.renderer.pickMesh();
        if (!info)
            return;
        const { meshId } = info;
        const focusPoint = this.renderer.getMeshPosition(meshId);
        if (!focusPoint)
            return console.warn('A3dResolvers.focusMesh()', 'Mesh position not found');
        this.renderer.setOrbitPoint(focusPoint, undefined, true);
    }
    addHotspot() {
        const info = this.renderer.pickMesh();
        if (!info) {
            return;
        }
        if (info.type === HotspotType.surface) {
            const { meshId, subMeshId, faceId, barycentricU, barycentricV } = info;
            dispatchHotSpotCreateEvent(this.viewerElement, {
                type: HotspotType.surface,
                info: info,
                attributes: {
                    [HotspotType.surface]: `${meshId} ${subMeshId} ${faceId} ${barycentricU} ${barycentricV}`,
                },
                meshId,
            });
            return;
        }
        const { meshId, position } = info;
        if (meshId && position) {
            dispatchHotSpotCreateEvent(this.viewerElement, {
                type: HotspotType.position,
                info: info,
                attributes: {
                    [HotspotType.position]: position.join(' '),
                },
                meshId,
            });
        }
    }
    set sceneReady(value) {
        const scene = Object.values(this.elements).find((element) => element.constructor.name === 'SceneResource');
        if (scene)
            scene.isReady = value;
    }
    focusHotspot(id, duration) {
        const focusPoint = this.renderer.getHotspotPosition(id);
        if (!focusPoint)
            return console.warn('A3dResolvers.focusHotspot()', 'Hotspot position not found');
        this.renderer.setOrbitPoint(focusPoint, duration);
    }
}
export { A3dResolver, A3dResolverErrorNames };
