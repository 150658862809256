/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Matrix } from '@babylonjs/core/Maths/math.vector';
/**
 * Compute the global matrix recursively.
 * Go thourght the parent nodes to compute the global matrix.
 * @param state the state of the viewer
 * @param node the node to compute the global matrix from
 * @returns the computed matrix
 */
function computeMatrixRecursive(state, node) {
    if (!node.parent) {
        return Matrix.FromArray(node.transform.matrix);
    }
    if (state.nodes) {
        const parent = state.nodes[node.parent];
        const parentMatrix = computeMatrixRecursive(state, parent);
        return parentMatrix.multiply(Matrix.FromArray(node.transform.matrix));
    }
    return Matrix.FromArray(node.transform.matrix);
}
/**
 * Get the global mesh transform matrix
 * @param state
 * @param meshId
 * @returns
 */
export function getGlobalMeshTransformMatrix(state, meshId) {
    if (!state || !state.meshes) {
        return undefined;
    }
    const mesh = state.meshes[meshId];
    if (mesh) {
        const globalMatrix = computeMatrixRecursive(state, mesh);
        return [...globalMatrix.asArray()];
    }
    return undefined;
}
