/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { WARN_RESOURCE_NOT_INITIALIZED, } from '@a3d-viewer/renderer-types';
import { GaussianSplattingMesh } from '@babylonjs/core';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { BabylonResource } from '../BabylonResource';
import { USER_MESH_TAG } from './MeshResource';
import { isTransformNode } from '../../util';
class GaussianSplattingMeshResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
        /** The loaded meshes */
        this.resource = null;
        this.node = new TransformNode('transform-node' + this.id, this.resolver.scene);
    }
    dispose() {
        if (!this.resource) {
            return;
        }
        if (this.resource) {
            this.resource.dispose();
        }
    }
    async update(config) {
        if (!this.resource && config.src) {
            this.resource = new GaussianSplattingMesh(this.id, null, this.resolver.scene);
            await this.resource.loadFileAsync(config.src);
            this.resource.metadata = {
                ...this.resource.metadata,
                type: USER_MESH_TAG,
                id: this.id,
            };
            let min = this.resource.getBoundingInfo().boundingBox.minimumWorld;
            this.node.translate(new Vector3(0, 1, 0), Math.abs(min._y));
            this.resolver.meshImported(this.id, false);
        }
    }
    getObjectResource() {
        return this.resource;
    }
    setRelation(parent) {
        if (!this.resource) {
            console.warn('Setting parent', WARN_RESOURCE_NOT_INITIALIZED);
            return;
        }
        // If a node is present use it as the reference
        if (this.node && isTransformNode(parent.getObjectResource())) {
            this.node.setParent(parent.getObjectResource());
            return;
        }
        // Use all the meshes as the reference
        this.resource.setParent(this.node);
    }
    get state() {
        if (!this.resource) {
            return {};
        }
        return { src: 'gaussiansplattingtestsrc' };
    }
}
export { GaussianSplattingMeshResource };
