/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
/**
 * Generate a bias for some z fighting
 * @param zValue the z value to use
 * @returns A bias near the log precision of the z value with some minimium value
 */
function biasForZFighting(zValue) {
    if (zValue > 0) {
        return Math.max(Math.pow(10, Math.floor(Math.log(zValue))), 0.000001);
    }
    else {
        return 0.00001;
    }
}
export { biasForZFighting };
