/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { useAtom } from 'jotai'
import { useEffect } from 'react'
import { Theme, themeAtom } from '../config'

export default function useTheme() {
  const [theme, setTheme] = useAtom(themeAtom)
  const toggleTheme = () =>
    setTheme(theme === Theme.light ? Theme.dark : Theme.light)

  useEffect(() => {
    if (
      theme === Theme.dark ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      localStorage.theme = Theme.dark
      document.documentElement.classList.add('dark')
    } else {
      localStorage.theme = Theme.light
      document.documentElement.classList.remove('dark')
    }
  }, [theme])

  return { theme, toggleTheme }
}
