/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { A3dElement, Group } from '@a3d-viewer/core';
import { property } from 'lit/decorators.js';
import { A3dScene } from '@a3d-viewer/scene';
import { A3dNode } from '@a3d-viewer/node';
import _isFinite from 'lodash/isFinite';
var A3dCameraErrorNames;
(function (A3dCameraErrorNames) {
    A3dCameraErrorNames["PARENT_ERROR"] = "A3dCameraParentError";
    A3dCameraErrorNames["BAD_ZFAR"] = "A3dCameraBadZFar";
    A3dCameraErrorNames["BAD_ZNEAR"] = "A3dCameraBadZNear";
    A3dCameraErrorNames["CLIPPING_ERROR"] = "A3dCameraClippingError";
    A3dCameraErrorNames["BAD_YFOV"] = "A3dCameraBadYFov";
    A3dCameraErrorNames["BAD_POSITION"] = "A3dCameraBadPosition";
    A3dCameraErrorNames["BAD_TARGET"] = "A3dCameraBadTarget";
})(A3dCameraErrorNames || (A3dCameraErrorNames = {}));
const A3dCameraErrorMessages = {
    [A3dCameraErrorNames.PARENT_ERROR]: 'A3dCamera: A3dCamera should be a child of A3dScene',
    [A3dCameraErrorNames.BAD_ZFAR]: 'A3dCamera: z-far have to be a number greater than 0',
    [A3dCameraErrorNames.BAD_ZNEAR]: 'A3dCamera: z-near have to be a number greater than 0',
    [A3dCameraErrorNames.CLIPPING_ERROR]: 'A3dCamera: z-far have to be greater than z-near',
    [A3dCameraErrorNames.BAD_YFOV]: 'A3dCamera: y-fov have to be a number less than π',
    [A3dCameraErrorNames.BAD_POSITION]: 'A3dCamera: position have to be a string with 3 numbers',
    [A3dCameraErrorNames.BAD_TARGET]: 'A3dCamera: target have to be a string with 3 numbers',
};
export class A3dCamera extends A3dElement {
    constructor() {
        super(...arguments);
        /**
         * @internal
         */
        this.group = Group.CAMERAS;
        /**
         * Whether or not this camera is active.
         */
        this.active = false;
        /**
         * The floating-point distance to the far clipping plane.
         * This value MUST NOT be equal to zero.
         * zFar MUST be greater than znear.
         * Default value is 1000
         */
        this.zFar = 1000;
        /**
         * The floating-point distance to the near clipping plane.
         * This value MUST NOT be equal to zero.
         * This value SHOULD NOT be negative.
         * Default value is 0.001
         */
        this.zNear = 0.001;
        /**
         * The minimum distance from the camera to the target.
         */
        this.minLimit = 1.0;
        /**
         * The maximum distance from the camera to the target.
         */
        this.maxLimit = 1.5;
        /**
         * If true, the zoom limits are disabled.
         */
        this.limitDisabled = true;
    }
    cameraData() {
        return {
            far: this.zFar,
            near: this.zNear,
            minLimit: this.minLimit,
            maxLimit: this.maxLimit,
            cameraId: this.cameraId,
            ...(this.limitDisabled && { limitDisabled: this.limitDisabled }),
            ...(this.active && { active: this.active }),
        };
    }
    /**
     * @internal
     */
    validateParent() {
        const isValid = this.parent?.constructor === A3dScene ||
            this.parent?.constructor === A3dNode;
        if (!isValid) {
            this.dispatchErrorEvent(A3dCameraErrorNames.PARENT_ERROR, A3dCameraErrorMessages[A3dCameraErrorNames.PARENT_ERROR]);
        }
        return isValid;
    }
    /**
     * Check if the value is a number greater than 0
     * @internal
     */
    validateNumber(value, errorName) {
        if (!value) {
            return true;
        }
        const isValid = _isFinite(value) && value > 0;
        if (!isValid) {
            this.dispatchErrorEvent(errorName, A3dCameraErrorMessages[errorName]);
        }
        return isValid;
    }
    /**
     * Check if the clipping is correct (zfar is greater than znear)
     * @internal
     */
    validateClipping() {
        if (!this.zFar || !this.zNear) {
            return true;
        }
        const isValid = this.zFar > this.zNear;
        if (!isValid) {
            this.dispatchErrorEvent(A3dCameraErrorNames.CLIPPING_ERROR, A3dCameraErrorMessages[A3dCameraErrorNames.CLIPPING_ERROR]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validate() {
        const isValidParent = this.validateParent();
        const isValidZFar = this.validateNumber(this.zFar, A3dCameraErrorNames.BAD_ZFAR);
        const isValidZNear = this.validateNumber(this.zNear, A3dCameraErrorNames.BAD_ZNEAR);
        const isNoClipping = this.validateClipping();
        return isValidParent && isValidZFar && isValidZNear && isNoClipping;
    }
}
__decorate([
    property({ type: Boolean, reflect: true })
], A3dCamera.prototype, "active", void 0);
__decorate([
    property({
        type: Number,
        attribute: 'z-far',
    })
], A3dCamera.prototype, "zFar", void 0);
__decorate([
    property({
        type: Number,
        attribute: 'z-near',
    })
], A3dCamera.prototype, "zNear", void 0);
__decorate([
    property({
        type: Number,
        attribute: 'zoom-min',
    })
], A3dCamera.prototype, "minLimit", void 0);
__decorate([
    property({
        type: Number,
        attribute: 'zoom-max',
    })
], A3dCamera.prototype, "maxLimit", void 0);
__decorate([
    property({
        type: Boolean,
        attribute: 'limit-disabled',
    })
], A3dCamera.prototype, "limitDisabled", void 0);
__decorate([
    property({
        type: String,
        attribute: 'camera-id',
    })
], A3dCamera.prototype, "cameraId", void 0);
export { A3dCameraErrorNames, A3dCameraErrorMessages };
