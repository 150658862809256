/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import _isEmpty from 'lodash/isEmpty';
import _isString from 'lodash/isString';
import _isNumber from 'lodash/isNumber';
import _isFinite from 'lodash/isFinite';
import { A3dElement, Group } from '@a3d-viewer/core';
import { customElement, property } from 'lit/decorators.js';
import { A3dScene } from '@a3d-viewer/scene';
var A3dEnvironmentErrorNames;
(function (A3dEnvironmentErrorNames) {
    A3dEnvironmentErrorNames["PARENT_ERROR"] = "A3dEnvironmentParentError";
    A3dEnvironmentErrorNames["NO_SRC_FILE_PROVIDED"] = "A3dEnvironmentNoSrcFileProvided";
    A3dEnvironmentErrorNames["BAD_BLUR_LEVEL"] = "A3dEnvironmentBadBlurLevel";
    A3dEnvironmentErrorNames["BAD_ROTATION_Y"] = "A3dEnvironmentBadRotationY";
    A3dEnvironmentErrorNames["BAD_INTENSITY"] = "A3dEnvironmentBadIntensity";
})(A3dEnvironmentErrorNames || (A3dEnvironmentErrorNames = {}));
const A3dEnvironmentErrorMessages = {
    [A3dEnvironmentErrorNames.PARENT_ERROR]: 'A3dEnv: A3dEnv should be a child of A3dScene',
    [A3dEnvironmentErrorNames.NO_SRC_FILE_PROVIDED]: 'A3dEnv: src is required',
    [A3dEnvironmentErrorNames.BAD_BLUR_LEVEL]: 'A3dEnv: blur-level have to be a number between 0 and 1',
    [A3dEnvironmentErrorNames.BAD_ROTATION_Y]: 'A3dEnv: rotation-y have to be a number',
    [A3dEnvironmentErrorNames.BAD_INTENSITY]: 'A3dEnv: intensity have to be a number',
};
let A3dEnv = class A3dEnv extends A3dElement {
    constructor() {
        super(...arguments);
        /**
         * @internal
         */
        this.group = Group.ENVIRONMENTS;
        /**
         * Source (url) of the environment file (HDR panorama picture stored in RGBE format).
         * @attr
         */
        this.src = '';
        /**
         * Sets the environment visible in the background.
         * @attr
         */
        this.visible = false;
        /**
         * Blur level of the environment from 0 (not blurred) to 1.
         * @attr
         */
        this.blurLevel = 0;
        /**
         * Rotation Y of the environment in rad.
         * @attr
         */
        this.rotationY = 0;
        /**
         * Intensity of the environment.
         * @attr
         */
        this.intensity = 1;
    }
    /**
     * @internal
     */
    exposedData() {
        return {
            src: this.src,
            visible: this.visible,
            rotationY: (this.rotationY + Math.PI) % (2 * Math.PI),
            blurLevel: this.blurLevel,
            intensity: this.intensity,
        };
    }
    /**
     * @internal
     */
    validateParent() {
        const isValid = this.parent?.constructor === A3dScene;
        if (!isValid) {
            this.dispatchErrorEvent(A3dEnvironmentErrorNames.PARENT_ERROR, A3dEnvironmentErrorMessages[A3dEnvironmentErrorNames.PARENT_ERROR]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validateSrc() {
        const isValid = _isString(this.src) && !_isEmpty(this.src);
        if (!isValid) {
            this.dispatchErrorEvent(A3dEnvironmentErrorNames.NO_SRC_FILE_PROVIDED, A3dEnvironmentErrorMessages[A3dEnvironmentErrorNames.NO_SRC_FILE_PROVIDED]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validateBlurLevel() {
        const isValid = _isNumber(this.blurLevel) && this.blurLevel >= 0 && this.blurLevel <= 1;
        if (!isValid) {
            this.dispatchErrorEvent(A3dEnvironmentErrorNames.BAD_BLUR_LEVEL, A3dEnvironmentErrorMessages[A3dEnvironmentErrorNames.BAD_BLUR_LEVEL]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validateNumber(value, errorName) {
        const isValid = _isFinite(value);
        if (!isValid) {
            this.dispatchErrorEvent(errorName, A3dEnvironmentErrorMessages[errorName]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validate() {
        let isValidBlurLevel = true;
        const isValidParent = this.validateParent();
        const isValidSrc = this.validateSrc();
        if (this.blurLevel) {
            isValidBlurLevel = this.validateBlurLevel();
        }
        const isValidRotationY = this.validateNumber(this.rotationY, A3dEnvironmentErrorNames.BAD_ROTATION_Y);
        const isValidIntensity = this.validateNumber(this.intensity, A3dEnvironmentErrorNames.BAD_INTENSITY);
        return (isValidParent &&
            isValidSrc &&
            isValidBlurLevel &&
            isValidRotationY &&
            isValidIntensity);
    }
};
__decorate([
    property({ type: String })
], A3dEnv.prototype, "src", void 0);
__decorate([
    property({ type: Boolean })
], A3dEnv.prototype, "visible", void 0);
__decorate([
    property({ type: Number, attribute: 'blur-level' })
], A3dEnv.prototype, "blurLevel", void 0);
__decorate([
    property({ type: Number, attribute: 'rotation-y' })
], A3dEnv.prototype, "rotationY", void 0);
__decorate([
    property({ type: Number })
], A3dEnv.prototype, "intensity", void 0);
A3dEnv = __decorate([
    customElement('a3d-environment')
], A3dEnv);
export { A3dEnv };
export { A3dEnvironmentErrorNames, A3dEnvironmentErrorMessages };
