/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Heading, Switch, Text, ToggleButton } from '@adobe/react-spectrum'
import { useAtom } from 'jotai'

import Pin from '@spectrum-icons/workflow/PinOff'

import { FormItem } from '../components/FormItem'
import { viewerAtom } from '../config'

function HotspotsPanel() {
  const [atom, setAtom] = useAtom(viewerAtom)

  const setAddHotspotOnDblClick = (value: boolean) => {
    setAtom({
      ...atom,
      hotspot: {
        ...atom.hotspot,
        addHotspotOnDblClick: value,
        addHotspotOnNextClick: false,
      },
    })
  }

  const setAddHotspotOnNextClick = () => {
    setAtom({
      ...atom,
      hotspot: {
        ...atom.hotspot,
        addHotspotOnDblClick: false,
        addHotspotOnNextClick: !atom.hotspot.addHotspotOnNextClick,
      },
    })
  }

  return (
    <>
      <Heading level={1}>Hotspots</Heading>
      <FormItem>
        <Switch
          isSelected={atom.hotspot.addHotspotOnDblClick}
          onChange={setAddHotspotOnDblClick}
        >
          Add hotspot on double click
        </Switch>
      </FormItem>
      <FormItem>
        <ToggleButton
          isSelected={atom.hotspot.addHotspotOnNextClick}
          onChange={setAddHotspotOnNextClick}
          aria-label="Pin"
        >
          <Pin />
          <Text>Add hotspot</Text>
        </ToggleButton>
      </FormItem>
    </>
  )
}

export { HotspotsPanel }
