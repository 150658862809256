/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
  Button,
  Flex,
  Heading,
  Item,
  ListBox,
  NumberField,
  Selection,
  Switch,
  Text,
} from '@adobe/react-spectrum'
import { useAtom, useAtomValue } from 'jotai'
import { FormItem } from '../components/FormItem'
import { cameraCurrentAtom, DefaultViewerConfig, viewerAtom } from '../config'

type CameraPanelProps = {
  handleReset: () => void
  cameras: any[]
}

function CameraPanel({ handleReset, cameras }: CameraPanelProps) {
  const [atom, setAtom] = useAtom(viewerAtom)
  const currentCamera = useAtomValue(cameraCurrentAtom)

  const handleCameraPositionChange = (axis: string) => (value: number) => {
    const position = atom.camera.position
    // @ts-ignore
    position[axis] = value
    setAtom((prev) => ({ ...prev, camera: { ...prev.camera, position } }))
  }

  const handleCameraTargetChange = (axis: string) => (value: number) => {
    const target = atom.camera.target
    // @ts-ignore
    target[axis] = value
    setAtom((prev) => ({ ...prev, camera: { ...prev.camera, target } }))
  }

  const handleResetButtonPress = () => {
    setAtom((prev) => ({
      ...prev,
      camera: {
        ...DefaultViewerConfig.camera,
      },
    }))
    handleReset()
  }

  const setCameraPositionOn = (value: boolean) => {
    setAtom((prev) => ({
      ...prev,
      camera: {
        ...prev.camera,
        positionOn: value,
        ...(value && {
          position: currentCamera.position,
          selectedCamera: undefined,
        }),
      },
    }))
  }

  const setCameraTargetOn = (value: boolean) => {
    setAtom({
      ...atom,
      camera: {
        ...atom.camera,
        targetOn: value,
        ...(value && {
          selectedCamera: undefined,
        }),
      },
    })
  }

  // const setCameraLimitOff = (value: boolean) => {
  //   setAtom({
  //     ...atom,
  //     camera: {
  //       ...atom.camera,
  //       limitOff: value,
  //     },
  //   })
  // }

  // const setCameraLimitMin = (value: number) => {
  //   setAtom({ ...atom, camera: { ...atom.camera, limitMin: value } })
  // }

  // const setCameraLimitMax = (value: number) => {
  //   setAtom({ ...atom, camera: { ...atom.camera, limitMax: value } })
  // }

  const onSceneCameraChange = (keys: Selection) => {
    const selected = [...keys][0]
    setAtom({
      ...atom,
      camera: { ...atom.camera, selectedCamera: selected as string },
    })
  }

  return (
    <>
      <Heading level={1}>
        <Flex justifyContent="space-between" alignItems="center">
          <span>Camera</span>
          <Button variant="primary" onPress={handleResetButtonPress}>
            Reset
          </Button>
        </Flex>
      </Heading>

      <Heading level={5} UNSAFE_className="m-0 p-0">
        Scene Cameras
      </Heading>
      {cameras && (
        <FormItem>
          <ListBox
            width="100%"
            aria-label="Cameras"
            selectionMode="single"
            selectedKeys={
              atom.camera.selectedCamera ? [atom.camera.selectedCamera] : []
            }
            onSelectionChange={onSceneCameraChange}
          >
            {cameras.map((camera) => {
              return (
                <Item
                  key={camera.name}
                  textValue={`${camera.name} (${camera.parentName})`}
                >
                  <Text>
                    {camera.name} ({camera.parentName})
                  </Text>
                </Item>
              )
            })}
          </ListBox>
        </FormItem>
      )}

      <Heading level={2}>Position</Heading>

      <FormItem>
        <NumberField
          UNSAFE_className="flex-1 w-auto"
          label="X"
          step={atom.camera.positionOn ? 0.1 : 0.0001}
          value={
            atom.camera.positionOn
              ? atom.camera.position.x
              : currentCamera.position.x
          }
          onChange={handleCameraPositionChange('x')}
          isDisabled={!atom.camera.positionOn}
        />
        <NumberField
          UNSAFE_className="flex-1 w-auto"
          label="Y"
          step={atom.camera.positionOn ? 0.1 : 0.0001}
          value={
            atom.camera.positionOn
              ? atom.camera.position.y
              : currentCamera.position.y
          }
          onChange={handleCameraPositionChange('y')}
          isDisabled={!atom.camera.positionOn}
        />
        <NumberField
          UNSAFE_className="flex-1 w-auto"
          label="Z"
          step={atom.camera.positionOn ? 0.1 : 0.0001}
          value={
            atom.camera.positionOn
              ? atom.camera.position.z
              : currentCamera.position.z
          }
          onChange={handleCameraPositionChange('z')}
          isDisabled={!atom.camera.positionOn}
        />
      </FormItem>
      <FormItem>
        <Switch
          isSelected={atom.camera.positionOn}
          onChange={setCameraPositionOn}
        >
          Override position
        </Switch>
      </FormItem>

      <Heading level={2}>Target</Heading>

      <FormItem>
        <NumberField
          UNSAFE_className="flex-1 w-auto"
          label="X"
          step={atom.camera.targetOn ? 0.01 : 0.0001}
          value={
            atom.camera.targetOn ? atom.camera.target.x : currentCamera.target.x
          }
          onChange={handleCameraTargetChange('x')}
          isDisabled={!atom.camera.targetOn}
        />
        <NumberField
          UNSAFE_className="flex-1 w-auto"
          label="Y"
          step={atom.camera.targetOn ? 0.01 : 0.0001}
          value={
            atom.camera.targetOn ? atom.camera.target.y : currentCamera.target.y
          }
          onChange={handleCameraTargetChange('y')}
          isDisabled={!atom.camera.targetOn}
        />
        <NumberField
          UNSAFE_className="flex-1 w-auto"
          label="Z"
          step={atom.camera.targetOn ? 0.01 : 0.0001}
          value={
            atom.camera.targetOn ? atom.camera.target.z : currentCamera.target.z
          }
          onChange={handleCameraTargetChange('z')}
          isDisabled={!atom.camera.targetOn}
        />
      </FormItem>
      <FormItem>
        <Switch isSelected={atom.camera.targetOn} onChange={setCameraTargetOn}>
          Override target
        </Switch>
      </FormItem>

      {/*
      <Heading level={2}>
        Camera Zoom Limit
        <ContextualHelp variant="info">
          <Heading>Camera Min/Max</Heading>
          <Content>
            <Text>
              expressed as a ratio of the scene dimension.
              <br />
              1 for the min, means the camera can zoom in until the scene fits
              the screen.
              <br />
              1.5 for the max, means the camera can zoom out 1.5 times the scene
              dimension.
            </Text>
          </Content>
        </ContextualHelp>
      </Heading>

      <FormItem>
        <NumberField
          label="Minimum"
          step={0.1}
          value={atom.camera.limitMin}
          onChange={setCameraLimitMin}
          isDisabled={atom.camera.limitOff}
        />
        <NumberField
          label="Maximum"
          step={0.1}
          value={atom.camera.limitMax}
          onChange={setCameraLimitMax}
          isDisabled={atom.camera.limitOff}
        />
      </FormItem>
      <FormItem>
        <Switch isSelected={atom.camera.limitOff} onChange={setCameraLimitOff}>
          Disable limits
        </Switch>
      </FormItem>
      */}
    </>
  )
}

export { CameraPanel }
