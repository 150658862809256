/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Engine } from '@babylonjs/core/Engines/engine';
import { Matrix, Vector3 } from '@babylonjs/core/Maths/math';
import { Animation } from '@babylonjs/core/Animations/animation';
import { FramingBehavior } from '@babylonjs/core/Behaviors/Cameras/framingBehavior';
// required import for babylon
import '@babylonjs/loaders/glTF';
import { AdobeGSplatAssetConfig, registerAdobeGSplatAssetGLTFLoaderExtension, } from '@3di/adobe-neural-assets';
import { BabylonSetup } from './BabylonSetup';
import _each from 'lodash/each';
import { A3dRenderEngine, HotspotType, MeshLoader, POST_EFFECTS, RendererEvents, defaultConfig, } from '@a3d-viewer/renderer-types';
import { cameraConfigFromInputs } from '@a3d-viewer/core';
import Optimization from './Optimization';
import '@babylonjs/core/Rendering';
import DefaultPost from './post/DefaultPost';
import SSAOPost from './post/SSAOPost';
import ShadowsPost from './post/ShadowsPost';
import ShadowsIblPost from './post/ShadowsIblPost';
import { EnvResource, GaussianSplattingMeshResource, HotspotResource, InstantiatedEntriesResource, LightResource, MeshCameraResource, MeshResource, NodeResource, PreloadedMeshResource, RotateCameraResource, SceneResource, TransformResource, isUserMesh, } from './resources';
import { Observable } from '@babylonjs/core';
import DepthPost from './post/DepthPost';
import GridPost from './post/GridPost';
import { cloneRotateCamera, findCameraByNameOrParentName, generateUniqueCameraIds, newRotateCameraFromTargetCamera, } from './cameras/utils';
import { postEffectsOnCameraMove } from './observables/postEffectsOnCameraMove';
import { manageRenderLoop } from './observables/renderLoop';
class A3dBabylonRender extends A3dRenderEngine {
    constructor(canvas, engine) {
        super(canvas);
        this.hotspotsResource = [];
        this.meshesById = new Map();
        this.effects = {};
        this.sceneOptimizer = null;
        this.onViewerModelLoadedObservable = new Observable();
        this.canvas = canvas;
        this.engine = engine ? engine : new Engine(this.canvas, true);
        registerAdobeGSplatAssetGLTFLoaderExtension(new AdobeGSplatAssetConfig());
        this.canvas.addEventListener(RendererEvents.MESH_IMPORTED, (e) => {
            if (!this.scene) {
                return;
            }
            // Prevent babylonjs to check for object click to increase performance
            this.scene.skipPointerMovePicking = true;
            postEffectsOnCameraMove(this.canvas, this.scene, this.effects);
            this._updateRenderLoop = manageRenderLoop(this.canvas, this.scene, this);
            setTimeout(() => {
                this.observeRenderToUpdateHotspots();
            }, 1000);
            const customEvent = e;
            const { hasAnimations } = customEvent.detail;
            if (hasAnimations) {
                return;
            }
        });
        // Maybe on mobile device go to 1 t render only at the canvas size
        this.engine.setHardwareScalingLevel(1.0 / window.devicePixelRatio);
    }
    async init() { }
    resize() {
        this.engine.resize();
    }
    resizeWithRender() {
        this.resize();
        if (this.scene?.activeCamera) {
            this.scene?.render();
        }
    }
    get fps() {
        return this.engine.getFps();
    }
    startRenderLoop() {
        this._updateRenderLoop?.();
    }
    runOptimization(renderLevel) {
        if (!this.scene) {
            console.debug('No scene to optimize');
            return;
        }
        if (!this.sceneOptimizer) {
            this.sceneOptimizer = new Optimization(this.scene);
        }
        this.sceneOptimizer.start(renderLevel);
    }
    stopOptimization() {
        this.sceneOptimizer?.stop();
    }
    createScene(id, uuid) {
        const sceneResource = new SceneResource(id, uuid, this);
        this.scene = sceneResource.getObjectResource();
        this.scene.useRightHandedSystem = true;
        return sceneResource;
    }
    createMesh(id, uuid, loader) {
        let meshResource;
        if (loader === MeshLoader.SPLAT) {
            meshResource = new GaussianSplattingMeshResource(id, uuid, this);
        }
        else {
            meshResource = new MeshResource(id, uuid, this);
        }
        this.meshesById.set(id, meshResource);
        return meshResource;
    }
    addMeshToScene(resource) {
        _each(resource.getObjectResource(), (mesh) => {
            this.scene?.addMesh(mesh, true);
        });
    }
    preloadMesh(id, uuid) {
        return new PreloadedMeshResource(id, uuid, this);
    }
    addPreloadedMeshToScene(resource) {
        const assetContainer = resource.getObjectResource();
        const createdResource = new InstantiatedEntriesResource(resource.id, resource.uuid, this, assetContainer);
        return createdResource;
    }
    createLight(id, uuid) {
        return new LightResource(id, uuid, this);
    }
    addLightToScene(resource) {
        this.scene?.addLight(resource.getObjectResource());
    }
    createCamera(id, uuid, input) {
        const cameraId = input.cameraId;
        if (cameraId) {
            const camera = findCameraByNameOrParentName(this.scene?.cameras, cameraId);
            if (camera) {
                return new MeshCameraResource(id, uuid, this, camera);
            }
        }
        let config = cameraConfigFromInputs(this.sceneDimension(), this.sceneBoundingBox(), defaultConfig.defaultCameraConfig, input);
        const resourceConfig = {
            ...config,
            ...(config.target && { target: new Vector3(...config.target) }),
        };
        const cameraResource = new RotateCameraResource(id, uuid, this, resourceConfig);
        cameraResource.update(resourceConfig);
        return cameraResource;
    }
    refreshDepthRendererForCamera(camera) {
        const scene = this.scene;
        this.depthRenderer = scene.enableDepthRenderer(camera.getObjectResource(), false, true);
    }
    updateCamera(camera, input) {
        const config = cameraConfigFromInputs(this.sceneDimension(), this.sceneBoundingBox(), defaultConfig.defaultCameraConfig, input);
        const resourceConfig = {
            ...config,
            target: input.target ? new Vector3(...config.target) : undefined,
            alpha: input.position ? config.alpha : undefined,
            beta: input.position ? config.beta : undefined,
            radius: input.position ? config.radius : undefined,
        };
        camera.update({
            ...resourceConfig,
        });
    }
    disableCamera(camera) {
        camera.getObjectResource().detachControl();
    }
    enableCamera(camera) {
        camera.getObjectResource().attachControl();
    }
    resetCamera(camera) {
        const config = cameraConfigFromInputs(this.sceneDimension(), this.sceneBoundingBox(), defaultConfig.defaultCameraConfig);
        const target = config.target ? new Vector3(...config.target) : undefined;
        camera.update({
            ...config,
            target,
        });
    }
    copyCamera(cameraToCopy, cameraToCopyTo) {
        const viewportCamera = cameraToCopyTo.getObjectResource();
        let newCamera;
        if (cameraToCopy instanceof MeshCameraResource) {
            const meshCamera = cameraToCopy.getObjectResource();
            meshCamera.minZ = viewportCamera.minZ;
            meshCamera.maxZ = viewportCamera.maxZ;
            newCamera = newRotateCameraFromTargetCamera(meshCamera, viewportCamera);
        }
        else {
            const meshCamera = cameraToCopy.getObjectResource();
            newCamera = cloneRotateCamera(meshCamera, viewportCamera);
            meshCamera.minZ = viewportCamera.minZ;
            meshCamera.maxZ = viewportCamera.maxZ;
        }
        if (!newCamera) {
            return false;
        }
        return true;
    }
    getActiveCameraMinMaxZ() {
        const activeCam = this.scene?.activeCamera;
        if (!activeCam) {
            return undefined;
        }
        return { min: activeCam.minZ, max: activeCam.maxZ };
    }
    setCameraMinMaxZ(camera, minMax) {
        const babylonCamera = camera.getObjectResource();
        babylonCamera.minZ = minMax.min;
        babylonCamera.maxZ = minMax.max;
    }
    addCameraToScene(resource) {
        this.scene?.addCamera(resource.getObjectResource());
    }
    createEnv(id, uuid) {
        return new EnvResource(id, uuid, this);
    }
    createNode(id, uuid) {
        return new NodeResource(id, uuid, this);
    }
    sceneBoundingBox() {
        if (!this.scene) {
            return { min: [0, 0, 0], max: [0, 0, 0] };
        }
        let minmax = this.scene.getWorldExtends(isUserMesh);
        return {
            min: minmax.min.asArray(),
            max: minmax.max.asArray(),
        };
    }
    sceneDimension() {
        if (!this.scene) {
            return [0, 0, 0];
        }
        let minmax = this.scene.getWorldExtends(isUserMesh);
        return [
            Math.abs(minmax.max.x - minmax.min.x),
            Math.abs(minmax.max.y - minmax.min.y),
            Math.abs(minmax.max.z - minmax.min.z),
        ];
    }
    sceneSize() {
        if (!this.scene) {
            return 0;
        }
        let minmax = this.scene.getWorldExtends(isUserMesh);
        return Math.max(Math.abs(minmax.max.x - minmax.min.x), Math.abs(minmax.max.z - minmax.min.z));
    }
    createTransform(id, uuid) {
        return new TransformResource(id, uuid, this);
    }
    postEffect(effects) {
        if (!this.scene) {
            return;
        }
        // TODO Make this method return the post effect
        // so we can dispose them with the Generic Runner and not just for babylon
        console.debug('adding post effect');
        const scene = this.scene;
        // Dispose of old post effects
        _each(this.effects, (effect) => {
            if (!effects.includes(effect.type)) {
                effect.dispose();
                delete this.effects[effect.type];
            }
        });
        // Create and apply new post effect
        for (let effect of effects) {
            if (!this.effects[effect]) {
                switch (effect) {
                    case POST_EFFECTS.SHADOWS:
                        this.effects[effect] = new ShadowsPost(scene);
                        break;
                    case POST_EFFECTS.GRID:
                        this.effects[effect] = new GridPost(scene);
                        break;
                    case POST_EFFECTS.SSAO:
                        this.effects[effect] = new SSAOPost(scene);
                        break;
                    case POST_EFFECTS.DEPTH:
                        this.effects[effect] = new DepthPost(scene, this.depthRenderer);
                        break;
                    case POST_EFFECTS.ANTIALIASING:
                        this.effects[effect] = new DefaultPost(scene);
                        break;
                    case POST_EFFECTS.SHADOWS_IBL:
                        this.effects[effect] = new ShadowsIblPost(scene);
                        break;
                }
            }
            // Process the effect at every update
            this.effects[effect].process();
        }
        // Fine tune materials of the scene
        scene.materials.forEach((mat) => {
            if (!mat.reflectionTexture &&
                BabylonSetup.PBRMaterialReflectionTexture) {
                ;
                mat.reflectionTexture = scene.environmentTexture;
            }
            ;
            mat.enableSpecularAntiAliasing =
                BabylonSetup.PBRMaterialEnableSpecularAntiAliasing;
        });
    }
    frameCamera(cameraResource) {
        cameraResource?.reset();
    }
    setActiveCamera(camera) {
        if (!this.scene) {
            return;
        }
        this.scene.activeCamera = camera.getObjectResource();
    }
    setCameraPointerMode(cameraResource, pointerMode) {
        if (!this.scene) {
            return;
        }
        if (cameraResource?.pointerMode !== pointerMode) {
            cameraResource.pointerMode = pointerMode;
        }
    }
    createHotspot(id, uuid) {
        const hotspotResource = new HotspotResource(id, uuid, this, this.clearHotspotCallback.bind(this, id));
        this.hotspotsResource.push(hotspotResource);
        return hotspotResource;
    }
    pickMesh() {
        if (!this.scene) {
            return null;
        }
        let ray = this.scene.createPickingRay(this.scene.pointerX, this.scene.pointerY, Matrix.Identity(), null);
        let hit = this.scene.pickWithRay(ray, (mesh) => {
            return mesh.metadata && mesh.metadata.id;
        });
        if (!hit || !hit?.pickedMesh) {
            console.debug('No mesh hit');
            return null;
        }
        const hasAnimations = hit.pickedMesh?.metadata.hasAnimations;
        if (hasAnimations) {
            const pickInfo = {
                type: HotspotType.surface,
                meshId: hit.pickedMesh.metadata.id,
                subMeshId: hit.pickedMesh.id,
                faceId: hit.faceId,
                barycentricU: hit.bu,
                barycentricV: hit.bv,
            };
            return pickInfo;
        }
        let pickInfo = {
            type: HotspotType.position,
            ...(hit.pickedMesh && {
                meshId: hit.pickedMesh.metadata.id,
            }),
            ...(hit.pickedPoint && {
                position: [hit.pickedPoint.x, hit.pickedPoint.y, hit.pickedPoint.z],
            }),
        };
        return pickInfo;
    }
    clearHotspotCallback(id) {
        this.hotspotsResource = this.hotspotsResource.filter((h) => h.id !== id);
    }
    /**
     * Observe the render loop to update the hotspots position on screen
     */
    observeRenderToUpdateHotspots() {
        const scene = this.scene;
        if (!scene) {
            return;
        }
        let depthMap;
        const depthRenderer = this.depthRenderer;
        if (depthRenderer) {
            const buffer = new Float32Array(4);
            scene.onAfterRenderObservable.add((scene) => {
                if (this.hotspotsResource.length === 0) {
                    return;
                }
                depthMap = depthRenderer.getDepthMap();
                scene.getEngine().onResizeObservable.add(() => {
                    depthMap.resize({
                        width: scene.getEngine().getRenderWidth(),
                        height: scene.getEngine().getRenderHeight(),
                    });
                });
                for (let hotspotResource of this.hotspotsResource) {
                    const resource = hotspotResource.getObjectResource();
                    // move the hotspot based on screen coords
                    const screenCoords = hotspotResource.calculateScreenCoords(this.engine, scene);
                    if (screenCoords) {
                        // read only the needed pixel
                        depthMap.readPixels(0, 0, buffer, true, false, Math.floor(screenCoords.x), 
                        // the depth map is flipped, origin is bottom left
                        Math.floor(scene.getEngine().getRenderHeight() - screenCoords.y), 1, 1);
                        // depth value are stored in the red channel
                        const depthValue = buffer[0];
                        // is the hotspot visible
                        if (scene.activeCamera !== null) {
                            const isVisible = hotspotResource.isVisible(scene, scene.activeCamera, depthValue);
                            resource.setVisibility(isVisible);
                            resource.move(Math.floor(screenCoords.x), Math.floor(screenCoords.y));
                        }
                    }
                }
            });
        }
    }
    /**
     * Called to get the position of a hotspot
     * @param hotspotId the hotspot id
     */
    getHotspotPosition(id) {
        const hotspot = this.hotspotsResource.find((h) => h.id === id);
        if (hotspot) {
            const position = hotspot.getPosition();
            if (position)
                return [position?.x, position?.y, position?.z];
        }
        return null;
    }
    /**
     * Called to get the position of a mesh
     * @param meshId the mesh id
     */
    getMeshPosition(id) {
        const resource = this.meshesById.get(id);
        const meshResource = resource.getObjectResource();
        if (meshResource && meshResource[0]) {
            const position = meshResource[0].getBoundingInfo().boundingBox.centerWorld;
            return [position.x, position.y, position.z];
        }
        return null;
    }
    /**
     * Set the orbit point of the camera to a hotspot
     * Inpired by the zoomOnBoundingInfo method of the FramingBehavior in Babylon.js
     * @param focusPoint the position to focus
     * @param duration the duration of the animation, in milliseconds
     * @param maxRadius if true, the camera will zoom to the max radius
     */
    setOrbitPoint(focusPoint, duration = 500, maxRadius = false) {
        if (!this.scene)
            return console.warn('A3dBabylonRender.setHotspotOrbitPoint()', 'Scene not found');
        const camera = this.scene.activeCamera;
        let radius = 0.05;
        if (maxRadius) {
            radius = camera.upperRadiusLimit || radius;
        }
        else {
            if (camera.upperRadiusLimit && camera.lowerRadiusLimit)
                radius = (camera.upperRadiusLimit + camera.lowerRadiusLimit) / 2 || 0.05;
        }
        const fps = 60;
        if (!this._vectorTransition) {
            this._vectorTransition = Animation.CreateAnimation('target', Animation.ANIMATIONTYPE_VECTOR3, fps, FramingBehavior.EasingFunction);
        }
        Animation.TransitionTo('target', new Vector3(focusPoint[0], focusPoint[1], focusPoint[2]), camera, camera.getScene(), fps, this._vectorTransition, duration);
        if (!this._radiusTransition) {
            this._radiusTransition = Animation.CreateAnimation('radius', Animation.ANIMATIONTYPE_FLOAT, fps, FramingBehavior.EasingFunction);
        }
        Animation.TransitionTo('radius', radius, camera, camera.getScene(), fps, this._radiusTransition, duration);
    }
    cameras() {
        if (!this.scene) {
            return;
        }
        return generateUniqueCameraIds(this.scene.cameras);
    }
    setActiveCameraById(cameraId) {
        if (!this.scene) {
            return;
        }
        const camera = this.scene?.cameras.find((c) => {
            return c.uniqueId.toString() === cameraId.toString() || c.id === cameraId;
        });
        if (!camera) {
            return;
        }
        this.scene.activeCamera = camera;
    }
    sceneHasAnimations() {
        if (this._sceneHasAnimations !== undefined) {
            return this._sceneHasAnimations;
        }
        if (!this.scene) {
            return false;
        }
        for (const [_, meshResource] of this.meshesById) {
            const meshes = meshResource.getObjectResource();
            for (let mesh of meshes) {
                if (mesh.metadata.hasAnimations) {
                    this._sceneHasAnimations = true;
                    return true;
                }
            }
        }
        this._sceneHasAnimations = false;
        return false;
    }
    sceneUpdated(partialSceneInfos) {
        super.sceneUpdated(partialSceneInfos);
        if (this.effects[POST_EFFECTS.SHADOWS_IBL] instanceof ShadowsIblPost) {
            this.effects[POST_EFFECTS.SHADOWS_IBL].resetAccumulation();
        }
    }
}
export { A3dBabylonRender };
