/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, PostEffect } from '@a3d-viewer/renderer-types';
import { Color3, TransformNode, } from '@babylonjs/core';
import { Vector3 } from '@babylonjs/core/Maths/math.vector';
import { MeshBuilder } from '@babylonjs/core/Meshes/meshBuilder';
import { Scene } from '@babylonjs/core/scene';
import { GridMaterial } from '@babylonjs/materials';
class GridPost extends PostEffect {
    constructor(scene) {
        super();
        this.type = POST_EFFECTS.GRID;
        this.scene = scene;
        this.gridGround = MeshBuilder.CreateGround('ground', { width: 1000, height: 1000 }, this.scene);
        const gridNode = new TransformNode('gridNode', this.scene);
        gridNode.translate(new Vector3(0, -1, 0), 0.0001);
        this.gridGround.parent = gridNode;
        var gridMaterial = new GridMaterial('groundMaterial', scene);
        gridMaterial.majorUnitFrequency = 5;
        gridMaterial.minorUnitVisibility = 0.5;
        gridMaterial.gridRatio = 2;
        gridMaterial.opacity = 0.5;
        gridMaterial.useMaxLine = true;
        gridMaterial.mainColor = new Color3(0.5, 0.5, 0.5);
        gridMaterial.lineColor = new Color3(0.2, 0.2, 0.2);
        this.gridGround.material = gridMaterial;
        this.scene.fogMode = Scene.FOGMODE_LINEAR;
        this.scene.fogDensity = 1; // Adjust this value to control the fade distance
        this.scene.fogColor = new Color3(1, 1, 1); // Adjust the color to match your scene
        const activeCamera = this.scene.activeCamera;
        this.observer = activeCamera.onViewMatrixChangedObservable.add(() => {
            const cameraPosition = activeCamera.position;
            const targetPosition = activeCamera.target;
            const distance = Vector3.Distance(cameraPosition, targetPosition);
            this.scene.fogStart = distance;
            this.scene.fogEnd = distance + 50;
        });
        this.process();
    }
    suspend() { }
    process() {
        console.log('GridPost processed');
    }
    dispose() {
        console.debug('Dispose grid');
        const activeCamera = this.scene.activeCamera;
        activeCamera.onViewMatrixChangedObservable.remove(this.observer);
        this.scene.removeMesh(this.gridGround);
    }
}
export default GridPost;
