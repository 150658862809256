/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { Vector3, Quaternion, Matrix } from '@babylonjs/core/Maths/math.vector';
const radiansToDegrees = (radians) => radians * (180 / Math.PI);
/**
 * Convert a babylon matrix to an API matrix
 * @param matrix - The babylon matrix
 * @returns The API matrix
 */
export function matrixTransform(matrix) {
    const inputMatrix = Matrix.FromArray(matrix);
    const scale = Vector3.Zero();
    let quaternion = Quaternion.Zero();
    const translation = Vector3.Zero();
    inputMatrix.decompose(scale, quaternion, translation);
    const eulerAnglesVector = quaternion.toEulerAngles();
    const RAD_TO_DEG = 180 / Math.PI;
    const eulerAnglesVectorInDegrees = new Vector3(eulerAnglesVector.x * RAD_TO_DEG, eulerAnglesVector.y * RAD_TO_DEG, eulerAnglesVector.z * RAD_TO_DEG);
    const newWorldMatrix = Matrix.Compose(scale, quaternion, translation);
    return {
        // Matrix
        matrix: [...newWorldMatrix.asArray()],
        // Trs
        trs: {
            scale: [...scale.asArray()],
            eulerAngles: [...eulerAnglesVectorInDegrees.asArray()],
            quaternion: [...quaternion.asArray()],
            translation: [...translation.asArray()],
        },
    };
}
export function viewerToAltitudeAzimuth(cameraMatrix, target) {
    const transform = matrixTransform(cameraMatrix);
    const position = Vector3.FromArray(transform.trs?.translation);
    const direction = position.subtract(target);
    const radius = direction.length();
    const altitude = Math.asin(direction.y / radius);
    const azimuth = Math.atan2(direction.x, direction.z);
    return {
        azimuthAltitude: {
            altitude: -1 * radiansToDegrees(altitude - Math.PI / 2),
            azimuth: -1 * radiansToDegrees(azimuth - Math.PI / 2),
            radius,
            lookAt: target.asArray(),
        },
    };
}
/**
 * Convert a focal length from a field of view
 * @param verticalFov the vertical field of view in radians
 * @param width the scene width
 * @param height the scene height
 * @param sensorWidth the sensor width
 * @returns the focal length
 */
export function fovToFocal(verticalFov, width, height, sensorWidth = 36) {
    const aspectRatio = width / height;
    const horizontalFOVRadians = 2 * Math.atan(Math.tan(verticalFov / 2) * aspectRatio);
    const focal = sensorWidth / (2 * Math.tan(horizontalFOVRadians / 2));
    return focal;
}
