/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
const defaultConfig = {
    engineDefaultControl: true,
    defaultCameraConfig: {
        alpha: (4 * Math.PI) / 5,
        beta: Math.PI / 3,
        radius: 100,
        fov: 1,
        near: 0.001,
        far: 1000,
        min: 1.0,
        max: 1.5,
    },
};
export { defaultConfig };
