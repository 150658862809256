/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { SHADOWS } from '@a3d-viewer/core'
import { MeshInputAnimationStatus } from '@a3d-viewer/renderer-types'
import { atom } from 'jotai'

// pre react to avoid blinking
if (localStorage.theme === 'dark') {
  document.documentElement.classList.add('dark')
}

export enum Theme {
  light = 'light',
  dark = 'dark',
}

export let defaultAppTheme = Theme.dark

export const themeAtom = atom<Theme>(
  (localStorage.theme as Theme) || defaultAppTheme
)

export enum EnvSrc {
  studioHigh = 'https://cdn.substance3d.com/v2/files/public/studio.hdr',
  studioWhiteHdr = 'https://cdn.substance3d.com/v2/files/public/studio-white.hdr',
  studioWhiteEnv = 'https://cdn.substance3d.com/v2/files/public/studio-white.env',
  bridgeEnv = 'https://assets.babylonjs.com/environments/sanGiuseppeBridge.env',
  plazaEnv = 'https://assets.babylonjs.com/environments/ulmerMuenster.env',
  roomHdr = 'https://playground.babylonjs.com/textures/room.hdr',
  workshopEnv = 'https://cdn.substance3d.com/v2/files/public/Atelier1K.env',
  workshopHdr = 'https://cdn.substance3d.com/v2/files/public/Atelier1K.hdr',
  exteriorEnv = 'https://cdn.substance3d.com/v2/files/public/Exterior1K.env',
  exteriorHdr = 'https://cdn.substance3d.com/v2/files/public/Exterior1K.hdr',
}

export interface MeshTranslation {
  x: number
  y: number
  z: number
}

export interface MeshRotation {
  x: number
  y: number
  z: number
}

export interface ViewerConfig {
  // url of the environment map
  envSrc: EnvSrc
  // rotation in radians
  envRotation: number
  // intensity of the environment map
  envIntensity: number
  // visibility of the environment map
  envVisibility: boolean
  // blur level of the environment map
  envBlurLevel: number
  // color in hex format
  color: string
  // mesh node translation and rotation
  mesh: {
    translation: MeshTranslation
    rotation: MeshRotation
    animations?: string[]
    selectedAnimation?: string
    animationStatus?: MeshInputAnimationStatus
    disabledAnimations?: boolean
  }
  camera: {
    positionOn: boolean
    targetOn: boolean
    position: { x: number; y: number; z: number }
    target: { x: number; y: number; z: number }
    limitOff?: boolean
    limitMin?: number
    limitMax?: number
    selectedCamera?: string
  }
  hotspot: {
    addHotspotOnDblClick: boolean
    addHotspotOnNextClick: boolean
  }
  shadow?: SHADOWS
  grid: boolean
  // indicates if the mesh is imported
  meshImported: boolean
  sceneReady: boolean
  toolbar: {
    enable: boolean
  }
  info: {
    enable: boolean
  }
  passes: {
    depth: boolean
    antialiasing: boolean
  }
  fullscreen?: boolean
}

export const DefaultViewerConfig: ViewerConfig = {
  envSrc: EnvSrc.studioWhiteEnv,
  envRotation: 0,
  envIntensity: 1,
  envVisibility: false,
  envBlurLevel: 0,
  color: '#ffffff',
  mesh: {
    translation: { x: 0, y: 0, z: 0 },
    rotation: { x: 0, y: 0, z: 0 },
  },
  camera: {
    positionOn: false,
    targetOn: false,
    position: { x: 1, y: 0, z: 1 },
    target: { x: 0, y: 0, z: 0 },
    // limitOff: false,
    // limitMin: 1.1,
    // limitMax: 1.9,
  },
  hotspot: {
    addHotspotOnDblClick: false,
    addHotspotOnNextClick: false,
  },
  shadow: SHADOWS.PERFORMANCE,
  grid: false,
  meshImported: false,
  sceneReady: false,
  toolbar: {
    enable: true,
  },
  info: {
    enable: false,
  },
  passes: {
    depth: false,
    antialiasing: false,
  },
  fullscreen: false,
}

const viewerAtom = atom<ViewerConfig>(DefaultViewerConfig)

const cameraCurrentAtom = atom<{
  position: { x: number; y: number; z: number }
  target: { x: number; y: number; z: number }
}>({
  position: { x: 1, y: 0, z: 1 },
  target: { x: 0, y: 0, z: 0 },
})

export { viewerAtom, cameraCurrentAtom }
