/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { A3dElement, Group } from '@a3d-viewer/core';
import { customElement, property } from 'lit/decorators.js';
let A3dTransform = class A3dTransform extends A3dElement {
    constructor() {
        super(...arguments);
        /** @internal */
        this.group = Group.TRANSFORM;
    }
    /** @internal */
    exposedData() {
        return { position: this.position, rotation: this.rotation };
    }
};
__decorate([
    property({})
], A3dTransform.prototype, "position", void 0);
__decorate([
    property({})
], A3dTransform.prototype, "rotation", void 0);
A3dTransform = __decorate([
    customElement('a3d-transform')
], A3dTransform);
export { A3dTransform };
