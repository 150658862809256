/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, RenderLevel, } from '@a3d-viewer/renderer-types';
import { ImageProcessingConfiguration } from '@babylonjs/core/Materials/imageProcessingConfiguration';
import { Color4 } from '@babylonjs/core/Maths/math.color';
import { TransformNode } from '@babylonjs/core/Meshes/transformNode';
import { Scene } from '@babylonjs/core/scene';
import { BabylonResource } from './BabylonResource';
import { AnimationPropertiesOverride } from '@babylonjs/core';
export const ROOT_NODE = 'root-node';
class SceneResource extends BabylonResource {
    constructor(id, uuid, resolver) {
        super(id, uuid, resolver);
        this.renderLevel = RenderLevel.QUALITY;
        this.resource = new Scene(this.resolver.engine);
        this.resource.clearColor = new Color4(1, 1, 1, 1);
        this.rootNode = new TransformNode(ROOT_NODE, this.resource);
        this.resource.imageProcessingConfiguration.toneMappingEnabled = true;
        this.resource.imageProcessingConfiguration.toneMappingType =
            ImageProcessingConfiguration.TONEMAPPING_ACES;
        // smooth animations transition
        this.resource.animationPropertiesOverride =
            new AnimationPropertiesOverride();
        this.resource.animationPropertiesOverride.enableBlending = true;
        this.resource.animationPropertiesOverride.blendingSpeed = 0.05;
        this.resource.animationPropertiesOverride.loopMode = 1;
    }
    getObjectResource() {
        return this.resource;
    }
    async update(config) {
        if (!this.resource)
            return;
        if (config &&
            config.color &&
            config.color !== this.resource.clearColor.toHexString()) {
            this.resource.clearColor = Color4.FromHexString(config.color);
            this.resolver.sceneUpdated(this.state);
        }
        if (config?.renderLevel &&
            Object.values(RenderLevel).includes(config.renderLevel)) {
            this.renderLevel = config.renderLevel;
        }
        if (config?.pointerMode) {
            this.pointerMode = config.pointerMode;
        }
        this.resolver.sceneUpdated(this.state);
    }
    dispose() {
        this.rootNode?.dispose();
        this.resource?.dispose();
        this.resolver.sceneDisposed(this.id);
    }
    setRelation(parent) {
        this.rootNode?.setParent(parent.getObjectResource());
    }
    get state() {
        return {
            color: this.resource.clearColor.toHexString(),
            shadows: !!this.resolver.effects[POST_EFFECTS.SHADOWS_IBL],
            renderLevel: this.renderLevel,
            dimension: this.resolver.sceneDimension(),
            isReady: this.readyState,
            pointerMode: this.pointerMode,
        };
    }
    set isReady(value) {
        this.readyState = value;
        this.resolver.sceneUpdated(this.state);
    }
}
export { SceneResource };
