/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
/**
 * List of events that can be dispatched by the viewer
 */
var ViewerEvents;
(function (ViewerEvents) {
    // Event dispatched when the viewer is updated (camera, environment, ...)
    ViewerEvents["INITIALIZING"] = "scene-initializing";
    ViewerEvents["UPDATE"] = "scene-update";
    ViewerEvents["RESOURCE_PROGRESS"] = "resource-progress";
    ViewerEvents["RESOURCE_LOADED"] = "resource-loaded";
    ViewerEvents["READY"] = "scene-ready";
    ViewerEvents["CHANGE"] = "change";
    ViewerEvents["VIEWER_UPDATED"] = "viewer-updated";
    ViewerEvents["CAMERA_MOVED"] = "camera-moved";
    ViewerEvents["MESH_IMPORTED"] = "mesh-imported";
    ViewerEvents["MESH_FOCUS"] = "mesh-focus";
    ViewerEvents["RESET_CAMERA"] = "reset-camera";
    ViewerEvents["USER_PICK"] = "user-pick";
    ViewerEvents["HOTSPOT_ADD"] = "hotspot-add";
    ViewerEvents["HOTSPOT_FOCUS"] = "hotspot-focus";
    ViewerEvents["HOTSPOT_DELETE"] = "hotspot-delete";
    ViewerEvents["HOTSPOT_NEW"] = "hotspot-new";
})(ViewerEvents || (ViewerEvents = {}));
var A3dResolverErrorNames;
(function (A3dResolverErrorNames) {
    A3dResolverErrorNames["MESH_LOAD_ERROR"] = "A3dResolverMeshLoadingError";
    A3dResolverErrorNames["ENV_CREATE_ERROR"] = "A3dResolverEnvCreateError";
    A3dResolverErrorNames["ENV_UPDATE_ERROR"] = "A3dResolverEnvUpdateError";
})(A3dResolverErrorNames || (A3dResolverErrorNames = {}));
const A3dResolverErrorMessages = {
    [A3dResolverErrorNames.MESH_LOAD_ERROR]: 'Error while loading mesh',
    [A3dResolverErrorNames.ENV_CREATE_ERROR]: 'Error while creating an environment',
    [A3dResolverErrorNames.ENV_UPDATE_ERROR]: 'Error while updating an environment',
};
/**
 * Dispatches the viewer global state is updated
 */
function dispatchViewerUpdatedEvent(dispatcher, state) {
    if (!state.ready) {
        return;
    }
    const viewerUpdateEvent = new CustomEvent(ViewerEvents.VIEWER_UPDATED, {
        detail: state,
    });
    dispatcher.dispatchEvent(viewerUpdateEvent);
}
/**
 * Dispatched when the camera is moved
 */
function dispatchCameraMovedEvent(dispatcher, state) {
    if (!state.ready) {
        return;
    }
    const cameraMovedEvent = new CustomEvent(ViewerEvents.CAMERA_MOVED, {
        detail: state,
    });
    dispatcher.dispatchEvent(cameraMovedEvent);
}
/**
 * Dispatches a scene initialization event
 */
function dispatchSceneInitializing(dispatcher) {
    const event = new CustomEvent(ViewerEvents.INITIALIZING);
    dispatcher.dispatchEvent(event);
    dispatchChangeEvent(dispatcher, event);
}
/*
 * Dispatches when the scene is currently updating
 */
function dispatchSceneUpdate(dispatcher) {
    const event = new CustomEvent(ViewerEvents.UPDATE);
    dispatcher.dispatchEvent(event);
    dispatchChangeEvent(dispatcher, event);
}
/**
 * Dispatches when a loading progress is occuring
 * e.g. when loading a model, an environment, etc.
 * @param progress a number between 0 and 1
 */
function dispatchResourceProgress(dispatcher, id, resourceType, progress) {
    const event = new CustomEvent(ViewerEvents.RESOURCE_PROGRESS, {
        detail: { id, type: resourceType, progress },
    });
    dispatcher.dispatchEvent(event);
    dispatchChangeEvent(dispatcher, event);
}
/**
 * Dispatches when a loading progress is occuring
 * e.g. when loading a model, an environment, etc.
 * @param progress a number between 0 and 1
 */
function dispatchResourceLoaded(dispatcher, id, resourceType) {
    const event = new CustomEvent(ViewerEvents.RESOURCE_LOADED, {
        detail: { id, type: resourceType },
    });
    dispatcher.dispatchEvent(event);
    dispatchChangeEvent(dispatcher, event);
}
/**
 * Dispatches when the scene is ready.
 * When the model and environment are loaded.
 */
function dispatchSceneReady(dispatcher) {
    const event = new CustomEvent(ViewerEvents.READY);
    dispatcher.dispatchEvent(event);
    dispatchChangeEvent(dispatcher, event);
}
/**
 * Dispatches a change event.
 * This is a generic event that can be used to notify changes in the viewer.
 * @param customEvent the custom event that trigger the change
 */
function dispatchChangeEvent(dispatcher, customEvent) {
    const event = new CustomEvent(ViewerEvents.CHANGE, {
        detail: { type: customEvent.type, value: customEvent.detail },
    });
    dispatcher.dispatchEvent(event);
}
/**
 * Dispatch an error event when a mesh loading error occurs
 * @param dispatcher the dispatcher
 * @param e the error
 */
function dispatchErrorEvent(dispatcher, e) {
    console.error(e);
    const event = new CustomEvent('error', {
        detail: {
            name: e?.name,
            message: e?.message,
            ...(e?.stack && { stack: e.stack }),
        },
    });
    dispatcher.dispatchEvent(event);
}
function dispatchHotSpotDeleteEvent(dispatcher, e) {
    const event = new e.constructor(ViewerEvents.HOTSPOT_DELETE, e);
    dispatcher.dispatchEvent(event);
}
function dispatchHotSpotCreateEvent(dispatcher, data) {
    const event = new CustomEvent(ViewerEvents.HOTSPOT_NEW, { detail: data });
    dispatcher.dispatchEvent(event);
}
export { ViewerEvents, A3dResolverErrorNames, A3dResolverErrorMessages, dispatchViewerUpdatedEvent, dispatchCameraMovedEvent, dispatchSceneInitializing, dispatchSceneUpdate, dispatchResourceProgress, dispatchResourceLoaded, dispatchSceneReady, dispatchChangeEvent, dispatchErrorEvent, dispatchHotSpotDeleteEvent, dispatchHotSpotCreateEvent, };
