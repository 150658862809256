/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { LitElement } from 'lit';
import _uniqueId from 'lodash/uniqueId';
import omit from 'lodash/omit';
import { property } from 'lit/decorators.js';
export class A3dElement extends LitElement {
    constructor() {
        super();
        /**
         * Use to identify the same element at different places.
         * For example, a model can be used multiple times in a scene.
         * @internal
         */
        this.uuid = crypto.randomUUID?.();
        /**
         * Used to identify the element if no id  attribute is provided.
         * @internal
         */
        this.internalId = _uniqueId(`${this.constructor.name}-`);
        /**
         * Used as a watchdog to prevent the element of being registered by its children if it's disconnected.
         * This is because each children of the disconnected parent will call the unregister method of the parent.
         * which will trigger a registerChild method on the parent.
         * @internal
         */
        this.disconnected = false;
        /** @internal */
        this.type = this.constructor.name;
        /** @internal */
        this.parent = this.parentNode;
        /** @internal */
        this.elements = {};
        this.refreshUUID();
    }
    /**
     * Refresh the uuid on element's update.
     * @internal
     */
    refreshUUID() {
        this.uuid = crypto.randomUUID?.();
    }
    /**
     * Validate the element.
     * @returns {boolean} true if the element is valid, false otherwise
     * @internal
     */
    validate() {
        return true;
    }
    /**
     * Register the element to the parent.
     * @param child - The child to register in the list element
     * @param group - The group of the child
     * @internal
     */
    register() {
        if (this.disconnected)
            return;
        const parent = this.parent;
        parent.registerChild?.({
            type: this.type,
            data: this.exposedData(),
            uuid: this.uuid,
            id: this.id,
            assetId: this.assetId || null,
            assetRef: this.assetRef || null,
            ...this.elements,
        }, this.group);
    }
    /**
     * Unregister the element to the parent.
     * @internal
     */
    unregister() {
        const parent = this.parent;
        parent.unregisterChild?.(this.id, this.group);
    }
    /**
     * Register a new child in the elements' list.
     * @param child - The child to register in the elements' list
     * @param group - The group of the child
     * @internal
     */
    registerChild(child, group) {
        if (child)
            this.addElement(child, group);
        this.register();
    }
    /**
     * Unregister child from element's list.
     * @param id - The id of the element to unregister
     * @param group - The group of the element to unregister
     * @internal
     */
    unregisterChild(id, group) {
        this.removeElement(id, group);
        this.register?.(); // Update the type of this.parent to include the register method
    }
    /**
     * Dispose the element.
     * Will be called when the element is disconnected.
     * this method will be called up to the viewer to update viewer's state
     * @param id - The id of the element to dispose
     * @param group - The group of the element to dispose
     * @internal
     */
    disposeElement(id, group) {
        const parent = this.parent;
        parent?.disposeElement?.(id, group);
    }
    connectedCallback() {
        super.connectedCallback();
        this.parent = this.parentNode;
        if (!this.id) {
            this.setAttribute('id', this.internalId);
        }
    }
    disconnectedCallback() {
        const parent = this.parent;
        parent.unregisterChild?.(this.id, this.group);
        this.disposeElement(this.id, this.group);
        this.disconnected = true;
        super.disconnectedCallback();
    }
    updated(_changedProperties) {
        super.update(_changedProperties);
        if (_changedProperties.size !== 0) {
            this.refreshUUID();
        }
        if (this.validate()) {
            this.register();
        }
        else {
            this.unregister();
        }
    }
    /**
     * Add a child to the elements lists.
     * @param child - The child to add
     * @param group - The group of the child
     */
    addElement(child, group) {
        const existingGroup = this.elements[group];
        this.elements = {
            ...this.elements,
            [group]: {
                ...existingGroup,
                [child.id]: { ...child },
            },
        };
    }
    /**
     * Remove a child from the elements lists.
     * @param id - The id of the child to remove
     * @param group - The group of the child
     */
    removeElement(id, group) {
        let newGroup = this.elements[group];
        newGroup = omit(newGroup, id);
        this.elements = {
            ...this.elements,
            [group]: { ...newGroup },
        };
    }
    /**
     * @internal
     */
    dispatchErrorEvent(name, message) {
        console.error(message);
        this.dispatchEvent(new CustomEvent('error', {
            detail: {
                name: name,
                message: message,
            },
            bubbles: true,
            composed: true,
            cancelable: true,
        }));
    }
}
__decorate([
    property({ attribute: 'asset-id' })
], A3dElement.prototype, "assetId", void 0);
__decorate([
    property({ attribute: 'asset-ref' })
], A3dElement.prototype, "assetRef", void 0);
