/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

type LabelProps = {
  label: string
}

export default function Label({ label }: LabelProps) {
  return (
    <label
      style={{
        boxSizing: 'border-box',
        color:
          'var(--spectrum-fieldlabel-text-color, var(--spectrum-alias-label-text-color))',
        padding:
          'var(--spectrum-fieldlabel-padding-top, var(--spectrum-global-dimension-size-50)) 0 var(--spectrum-fieldlabel-padding-bottom, var(--spectrum-global-dimension-size-65))',
        fontSize:
          'var(--spectrum-fieldlabel-text-size, var(--spectrum-global-dimension-font-size-75))',
        fontWeight:
          'var(--spectrum-fieldlabel-text-font-weight, var(--spectrum-global-font-weight-regular))',
        lineHeight:
          'var(--spectrum-fieldlabel-text-line-height, var(--spectrum-global-font-line-height-small))',
        verticalAlign: 'top',
        fontSmooth: 'subpixel-antialiased',
        textAlign: 'start',
        cursor: 'default',
        display: 'flex',
      }}
    >
      {label}
    </label>
  )
}
