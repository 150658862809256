/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { A3dElement, Group } from '@a3d-viewer/core';
import { A3dNode } from '@a3d-viewer/node';
import { A3dScene } from '@a3d-viewer/scene';
import { customElement, property } from 'lit/decorators.js';
import _isString from 'lodash/isString';
import _isEmpty from 'lodash/isEmpty';
var A3dMeshErrorNames;
(function (A3dMeshErrorNames) {
    A3dMeshErrorNames["PARENT_ERROR"] = "A3dMeshParentError";
    A3dMeshErrorNames["NO_MESH_PROVIDED"] = "A3dMeshNoMeshProvided";
})(A3dMeshErrorNames || (A3dMeshErrorNames = {}));
const A3dMeshErrorMessages = {
    [A3dMeshErrorNames.PARENT_ERROR]: 'A3dMesh: A3dMesh should be a child of A3dScene or A3dNode',
    [A3dMeshErrorNames.NO_MESH_PROVIDED]: 'A3dMesh: src or asset-ref is required',
};
let A3dMesh = class A3dMesh extends A3dElement {
    constructor() {
        super(...arguments);
        /**
         * @internal
         */
        this.group = Group.MESHES;
        /**
         * Source (url) of the mesh.
         * @attr
         */
        this.src = '';
        /**
         * Disable animations.
         * @attr
         */
        this.disabledAnimations = false;
    }
    /**
     * @internal
     */
    exposedData() {
        return {
            src: this.src,
            loader: this.loader,
            selectedAnimation: this.selectedAnimation,
            animationStatus: this.animationStatus,
            disabledAnimations: this.disabledAnimations,
        };
    }
    /**
     * @internal
     */
    validateParent() {
        const isValid = this.parent?.constructor === A3dScene ||
            this.parent?.constructor === A3dNode;
        if (!isValid) {
            this.dispatchErrorEvent(A3dMeshErrorNames.PARENT_ERROR, A3dMeshErrorMessages[A3dMeshErrorNames.PARENT_ERROR]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validateProperties() {
        let isValid = false;
        if (_isString(this.src) && !_isEmpty(this.src)) {
            isValid = true;
        }
        if (_isString(this.assetRef) && !_isEmpty(this.assetRef)) {
            isValid = true;
        }
        if (!isValid) {
            this.dispatchErrorEvent(A3dMeshErrorNames.NO_MESH_PROVIDED, A3dMeshErrorMessages[A3dMeshErrorNames.NO_MESH_PROVIDED]);
        }
        return isValid;
    }
    /**
     * @internal
     */
    validate() {
        const isValidParent = this.validateParent();
        const isValidProperties = this.validateProperties();
        return isValidParent && isValidProperties;
    }
};
__decorate([
    property({ type: String })
], A3dMesh.prototype, "src", void 0);
__decorate([
    property({ type: String })
], A3dMesh.prototype, "loader", void 0);
__decorate([
    property({ type: String, attribute: 'selected-animation' })
], A3dMesh.prototype, "selectedAnimation", void 0);
__decorate([
    property({ type: String, attribute: 'animation-status' })
], A3dMesh.prototype, "animationStatus", void 0);
__decorate([
    property({ type: Boolean, attribute: 'disabled-animations', reflect: true })
], A3dMesh.prototype, "disabledAnimations", void 0);
A3dMesh = __decorate([
    customElement('a3d-mesh')
], A3dMesh);
export { A3dMesh };
export { A3dMeshErrorNames, A3dMeshErrorMessages };
