/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Heading, Item, Key, Picker, Switch } from '@adobe/react-spectrum'
import { useAtom } from 'jotai'
import { FormItem } from '../components/FormItem'
import { viewerAtom } from '../config'
import { useState } from 'react'
import { SHADOWS } from '@a3d-viewer/core'

function EffectsPanel() {
  const [atom, setAtom] = useAtom(viewerAtom)
  const [showShadow, setShowShadow] = useState(true)
  const [shadowQuality, setShadowQuality] = useState<SHADOWS>(
    atom.shadow || SHADOWS.PERFORMANCE
  )

  const handleGridChange = (value: boolean) => {
    setAtom({ ...atom, grid: value })
  }

  const handleShadowChange = (value: Key) => {
    setShadowQuality(value as SHADOWS)
    setAtom({ ...atom, shadow: value as SHADOWS })
  }

  return (
    <>
      <Heading level={1}>Effects</Heading>
      <FormItem>
        <Switch isSelected={atom.grid} onChange={handleGridChange}>
          Grid
        </Switch>
      </FormItem>
      <FormItem>
        <Switch
          isSelected={showShadow}
          onChange={() => {
            setShowShadow(!showShadow)
            if (!showShadow) {
              setAtom({ ...atom, shadow: shadowQuality })
            } else {
              setAtom({ ...atom, shadow: undefined })
            }
          }}
        >
          Shadow
        </Switch>
      </FormItem>
      <FormItem>
        <Picker
          label="Shadow Quality"
          onSelectionChange={handleShadowChange}
          selectedKey={shadowQuality}
          width={'100%'}
          isDisabled={!showShadow}
        >
          <Item key={SHADOWS.PERFORMANCE}>Performance</Item>
          <Item key={SHADOWS.QUALITY}>Quality</Item>
        </Picker>
      </FormItem>
    </>
  )
}

export { EffectsPanel }
