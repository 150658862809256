import { Quaternion } from '@babylonjs/core/Maths/math.vector';
/**
 * Get the transform object from a node
 * @param node the node to get the transform from
 * @returns the transform
 */
export function getTransformFromNode(node) {
    const quaternion = Quaternion.FromEulerVector(node.rotation);
    return {
        matrix: [...node.getWorldMatrix().asArray()],
        trs: {
            translation: node.position.asArray(),
            quaternion: quaternion.asArray(),
            eulerAngles: quaternion.toEulerAngles().asArray(),
            scaling: node.scaling.asArray(),
        },
    };
}
