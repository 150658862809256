/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { RendererEvents, } from './types';
import { defaultConfig } from './config';
/**
 * Resolver interface that every resolver must implements
 */
class A3dRenderEngine {
    /**
     * Create the resolver
     * @param canvas The canvas element
     */
    constructor(canvas, config = defaultConfig) {
        this.canvas = canvas;
        this.config = config;
    }
    cameraUpdated() {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.CAMERA_UPDATED));
    }
    cameraMatrixUpdated() {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.CAMERA_MATRIX_UPDATED));
    }
    cameraDisposed(camId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.CAMERA_DISPOSED, {
            detail: { id: camId },
        }));
    }
    envCreate(id) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.ENV_CREATE, {
            detail: { id },
        }));
    }
    envUpdated(partialEnvInfos) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.ENV_UPDATED, {
            detail: partialEnvInfos,
        }));
    }
    envDisposed(envId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.ENV_DISPOSED, {
            detail: { id: envId },
        }));
    }
    envReady(partialEnvInfos) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.ENV_READY, {
            detail: partialEnvInfos,
        }));
    }
    sceneUpdated(partialSceneInfos) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.SCENE_UPDATED, {
            detail: partialSceneInfos,
        }));
    }
    sceneDisposed(sceneId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.SCENE_DISPOSED, {
            detail: { id: sceneId },
        }));
    }
    nodeUpdated(nodeId, partialSceneInfos) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.NODE_UPDATED, {
            detail: { [nodeId]: partialSceneInfos },
        }));
    }
    nodeDisposed(nodeId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.NODE_DISPOSED, {
            detail: { id: nodeId },
        }));
    }
    transformDisposed(transformId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.TRANSFORM_DISPOSED, {
            detail: { id: transformId },
        }));
    }
    meshImported(meshId, hasAnimations) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.MESH_IMPORTED, {
            detail: { meshId, hasAnimations },
        }));
    }
    meshUpdated(meshId, partialMeshInfos) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.MESH_UPDATED, {
            detail: { [meshId]: partialMeshInfos },
        }));
    }
    meshDisposed(meshId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.MESH_DISPOSED, {
            detail: { id: meshId },
        }));
    }
    meshProgress(meshId, progress) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.MESH_PROGRESS, {
            detail: { meshId, progress },
        }));
    }
    hotspotUpdated(hotspotId, partialHotspotInfos) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.HOTSPOT_UPDATED, {
            detail: { [hotspotId]: partialHotspotInfos },
        }));
    }
    hotspotDeleted(hotspotId) {
        this.canvas.dispatchEvent(new CustomEvent(RendererEvents.HOTSPOT_DELETED, {
            detail: hotspotId,
        }));
    }
}
export { A3dRenderEngine };
