/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
  Content,
  Heading,
  IllustratedMessage,
  Link,
} from '@adobe/react-spectrum'
import UploadIcon from '@spectrum-icons/illustrations/Upload'
import { useAtom } from 'jotai'
import { ReactElement, useEffect, useRef, useState } from 'react'
import { MeshType } from '../App'
import { viewerAtom } from '../config'

type DropZoneProps = {
  children: ReactElement | null
  onDrop: ({ fileAsDataUrl, extension, file }: MeshType) => void
  showDraggingText?: boolean
}

export default function DropZone({
  children,
  onDrop,
  showDraggingText,
}: DropZoneProps) {
  const viewerContainerRef = useRef<HTMLDivElement>(null)
  const [viewer, setViewer] = useAtom(viewerAtom)
  const [dragging, setDragging] = useState<boolean>(showDraggingText || false)
  const inputRef = useRef<HTMLInputElement>(null)

  const handleDragEnter = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(true)
  }
  const handleDragLeave = (e: DragEvent) => {
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleDrop = async (e: DragEvent) => {
    setViewer({ ...viewer, meshImported: false })
    let files = e.dataTransfer?.files
    if (files && files.length > 0) {
      const file = files[0]
      const blob = URL.createObjectURL(file)
      const extension = file.name.split('.').pop()?.toLowerCase()
      onDrop({
        file,
        fileAsDataUrl: blob,
        extension: extension as MeshType['extension'],
      })
    }
    e.preventDefault()
    e.stopPropagation()
    setDragging(false)
  }

  const handleSelectFile = () => {
    inputRef.current?.click()
  }

  const handleFileSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setViewer({ ...viewer, meshImported: false })
    let files = e.target.files
    if (files && files.length > 0) {
      const file = files[0]
      const blob = URL.createObjectURL(file)
      const extension = file.name.split('.').pop()?.toLowerCase()
      onDrop({
        fileAsDataUrl: blob,
        extension: extension as MeshType['extension'],
      })
    }
  }

  useEffect(() => {
    if (!viewerContainerRef.current) return undefined

    const viewerContainerEl = viewerContainerRef.current

    viewerContainerEl.addEventListener('dragover', handleDragEnter)
    viewerContainerEl.addEventListener('dragleave', handleDragLeave)
    viewerContainerEl.addEventListener('drop', handleDrop)

    return () => {
      viewerContainerEl.removeEventListener('dragover', handleDragEnter)
      viewerContainerEl.removeEventListener('dragleave', handleDragLeave)
      viewerContainerEl.removeEventListener('drop', handleDrop)
    }
  }, [viewerContainerRef.current])

  useEffect(() => {
    setDragging(showDraggingText || false)
  }, [showDraggingText])

  return (
    <div ref={viewerContainerRef} className="dnd-zone h-full w-full">
      {children}
      {(dragging || !children) && (
        // TODO : fix color for SVG need to export to a new component
        <>
          <IllustratedMessage UNSAFE_className="fill-sky-400 text-sky-400 z-30 absolute  top-0 left-0 right-0 bottom-0">
            {<UploadIcon UNSAFE_className="upload-svg" />}
            <Heading UNSAFE_className="text-sky-400">Upload a file</Heading>
            <Content UNSAFE_className="text-sky-400">
              Drop a GLB or GLTF file or{' '}
              <Link onPress={handleSelectFile}>
                select a file on your computer
              </Link>
            </Content>
            <input
              hidden
              type="file"
              accept=".glb, .ply"
              ref={inputRef}
              onChange={handleFileSelect}
            />
          </IllustratedMessage>
          <div
            className="absolute top-0 left-0 right-0 bottom-0 bg-sky-400/30 border-dashed border-4 border-sky-400 text-sky-400 rounded-2xl z-40"
            onClick={handleSelectFile}
          ></div>
        </>
      )}
    </div>
  )
}
