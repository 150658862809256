/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { createRef, ref } from 'lit/directives/ref.js';
import { HotspotEvents, ViewerEvents } from '@a3d-viewer/core';
let A3dHotspotUi = class A3dHotspotUi extends LitElement {
    constructor() {
        super(...arguments);
        /**
         * The label of this hotspot.
         */
        this.label = null;
        /**
         * Whether this hotspot is hidden behind the mesh.
         */
        this.hidden = false;
        /** @internal */
        this._readonly = false;
        /** @internal */
        this.contentElementRef = createRef();
        /** @internal */
        this.inputTextRef = createRef();
    }
    /**
     * Whether this hotspot is editable.
     * If true, the label will not be editable and the hotspot will not be removable.
     */
    set readonly(readonly) {
        this._readonly = readonly;
        this.dispatchUpdateStateEvent();
    }
    get readonly() {
        return this._readonly;
    }
    /** @internal */
    handleMouse(opacity) {
        if (this.contentElementRef && this.contentElementRef.value) {
            this.contentElementRef.value.style.opacity = opacity;
            if (opacity === '0' && this.inputTextRef && this.inputTextRef.value) {
                this.inputTextRef.value.blur();
            }
        }
    }
    /** @internal */
    focusInput() {
        if (this.inputTextRef && this.inputTextRef.value) {
            this.inputTextRef.value.focus();
        }
    }
    /** @internal */
    handleInputChange(event) {
        const input = event.target;
        this.label = input.value;
        this.dispatchUpdateStateEvent();
    }
    /** @internal */
    handleMouseDown(event) {
        if (event.button === 2) {
            event.preventDefault();
            this.dispatchEvent(new CustomEvent(HotspotEvents.HOTSPOT_DELETE, {
                bubbles: true,
                composed: true,
            }));
        }
    }
    handleFocusOn(event) {
        if (event.altKey && event.button === 0) {
            event.preventDefault();
            event.stopImmediatePropagation();
            this.dispatchEvent(new CustomEvent(ViewerEvents.HOTSPOT_FOCUS, {
                bubbles: true,
                composed: true,
                detail: { id: this.parentElement?.id },
            }));
        }
    }
    dispatchUpdateStateEvent() {
        this.dispatchEvent(new CustomEvent(HotspotEvents.HOTSPOT_STATE_CHANGE, {
            detail: {
                ...(this.label && { label: this.label }),
                ...(this.readonly && { readonly: this.readonly }),
            },
            bubbles: true,
            composed: true,
        }));
    }
    buttonTemplate(readonly) {
        if (readonly) {
            return html `<button
        part="button"
        @mouseover=${() => this.handleMouse('1')}
        @mouseleave=${() => this.handleMouse('0')}
        @click=${this.handleFocusOn}
      ></button>`;
        }
        else {
            return html `<button
        part="button"
        @mouseover=${() => this.handleMouse('1')}
        @mouseleave=${() => this.handleMouse('0')}
        @click=${this.focusInput}
        @mousedown=${this.handleMouseDown}
      ></button>`;
        }
    }
    render() {
        const value = this.label || '';
        return html `${this.buttonTemplate(this.readonly)}
      <div part="content" ${ref(this.contentElementRef)}>
        <input
          part="input"
          type="text"
          ${ref(this.inputTextRef)}
          value=${value}
          ?readonly=${this.readonly}
          @change=${this.handleInputChange}
        />
      </div>`;
    }
};
A3dHotspotUi.styles = css `
    :host {
      --hotspot-button-size: 20px;
    }

    :host([hidden]) {
      /* counteract the "hidden" attribute that adds a "display:none" under the hood */
      display: block;
    }

    :host::part(button) {
      position: absolute;
      transform: translate(-50%, -50%);

      width: var(--hotspot-button-size);
      height: var(--hotspot-button-size);

      background-color: rgb(38, 38, 38);

      border-width: 2px;
      border-style: solid;
      border-color: rgb(235, 235, 235);
      border-radius: 50%;
      box-sizing: border-box;
      transition: border-color 150ms ease-out;

      cursor: pointer;
    }

    :host([hidden])::part(button) {
      opacity: 0.5;
      border-style: dashed;
    }

    :host::part(button):hover {
      border-color: #3892f3;
    }

    :host::part(content) {
      position: absolute;
      left: calc(var(--hotspot-button-size) / 2);
      bottom: calc(var(--hotspot-button-size) / 2);

      min-width: 120px;
      height: 40px;

      background-color: #ffffff;
      border: 1px solid rgb(177, 177, 177);
      border-radius: 5px;
      filter: drop-shadow(rgba(0, 0, 0, 0.15) 0px 1px 4px);

      transition: opacity 150ms ease-out;

      pointer-events: none;
    }

    :host::part(input) {
      height: 100%;
      padding: 0 10px;
      border: none;
      background-color: transparent;
      color: #000000;
      outline: none;

      pointer-events: none;
    }
  `;
__decorate([
    property({ type: String, reflect: true })
], A3dHotspotUi.prototype, "label", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], A3dHotspotUi.prototype, "hidden", void 0);
__decorate([
    property({ type: Boolean, reflect: true })
], A3dHotspotUi.prototype, "readonly", null);
A3dHotspotUi = __decorate([
    customElement('a3d-hotspot-ui')
], A3dHotspotUi);
export { A3dHotspotUi };
