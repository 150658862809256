/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import type {
  paths,
  SchemaModelConvertResponse,
  SchemaRawJobResponse,
  SchemaRenderModelResponse,
  SchemaSpace,
} from '../../clients/substance3dapi/schema'
import createClient, { Client } from 'openapi-fetch'

export type FormatsType = 'fbx' | 'glb' | 'usdz'
export type RequestStatusType = 'idle' | 'running' | 'success' | 'failed'

export const SERVER_URL = 'https://api-internal-dev.cw.substance3d.io'

const SPACE_PATH = '/v1beta/spaces/{namespace}'
const MODEL_RENDER_PATH = '/v1beta/3dmodels/render'
const JOB_PATH = '/v1beta/jobs/{namespace}/{id}'

/**
 * S3DApi class implementation
 */

export type ApiFileType = {
  name: string
  size: number
  url: string
}

class S3DApi {
  private _serverUrl: string
  private _namespace: string
  private _file: ApiFileType | null = null
  private _client: Client<paths, `${string}/${string}`> | null = null

  constructor(serverUrl: string = SERVER_URL, namespace: string = 'default') {
    this._namespace = namespace
    this._serverUrl = serverUrl
    this._client = createClient<paths>({ baseUrl: this._serverUrl })
  }

  async uploadFile(file: File, namespace: string = 'default') {
    if (!this._client) throw new Error('Client not initialized')
    return this._client
      .POST(SPACE_PATH, {
        body: {},
        bodySerializer: () => {
          const formData = new FormData()
          formData.append('filename', file)
          return formData
        },
        params: { path: { namespace } },
      })
      .then((response) => response)
  }

  private async getJobResult(id: string, namespace: string = 'default') {
    if (!this._client) throw new Error('Client not initialized')
    return this._client
      .GET(JOB_PATH, {
        params: {
          path: { namespace, id },
        },
      })
      .then((response) => response)
  }

  private async render(
    space: SchemaSpace,
    width: number,
    height: number,
    matrix: number[],
    namespace: string = 'default'
  ) {
    if (!this._client) throw new Error('Client not initialized')

    const file = space.files?.[0]
    return this._client
      .POST(MODEL_RENDER_PATH, {
        body: {
          namespace,
          scene: {
            modelFile: file?.name as string,
            camera: {
              focal: 50,
              sensorWidth: 36,
              transform: {
                matrix: matrix,
              },
            },
          },
          size: { width, height },
          sources: [
            {
              mountPoint: '/',
              space: {
                id: space.id,
                namespace: namespace,
              },
            },
          ],
        },
      })
      .then((response) => response)
  }

  private sleep(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  }

  set serverUrl(serverUrl: string) {
    this._serverUrl = serverUrl
    this._client = createClient<paths>({ baseUrl: this._serverUrl })
  }

  async uploadModel(file: File): Promise<ApiFileType> {
    const response = await this.uploadFile(file)

    if (response.error) {
      console.log(response.error)
      throw new Error(response.error.message)
    }
    if (!response.data.files) throw new Error('Unable to process the file')

    this._file = response.data.files[0]

    return this._file
  }

  async renderModel(
    space: SchemaSpace,
    width: number,
    height: number,
    matrix: number[]
  ): Promise<SchemaSpace> {
    const response = await this.render(
      space,
      width,
      height,
      matrix,
      this._namespace
    )
    if (response.error) {
      console.log(response.error)
      throw new Error(response.error.message)
    }

    const job = await this.waitForJob<Promise<SchemaRenderModelResponse>>(
      response.data.id
    )

    if (!job.result?.output) throw new Error('No output found')

    const renderResult = job.result.output as SchemaSpace
    return renderResult
  }

  async waitForJob<T>(
    id: string,
    options?: { checkInterval: number; callback: (status: boolean) => void }
  ): Promise<T> {
    let status: RequestStatusType = 'idle'
    let dataFromJobResult: SchemaModelConvertResponse | undefined

    do {
      const jobResult = await this.getJobResult(id, this._namespace)
      dataFromJobResult = jobResult.data as SchemaRawJobResponse

      status = dataFromJobResult.status as RequestStatusType

      if (jobResult.error) {
        console.log(jobResult.error)
        throw new Error(jobResult.error.message)
      }
      await this.sleep(options?.checkInterval || 1000)
    } while (status !== 'success' && status !== 'failed')

    if (dataFromJobResult.status === 'failed' && dataFromJobResult.error)
      throw new Error(dataFromJobResult.error)

    return dataFromJobResult as T
  }

  async getJobStatus(id: string) {
    const response = await this.getJobResult(id, this._namespace)

    if (response.error) {
      console.log(response.error)
      throw new Error(response.error.message)
    }

    return response.response.status
  }
}

export default S3DApi
export { S3DApi }
