/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
/**
 * Polyfill for Promise.withResolvers
 */
Promise.withResolvers ||
    (Promise.withResolvers = function withResolvers() {
        var a, b, c = new this(function (resolve, reject) {
            a = resolve;
            b = reject;
        });
        return { resolve: a, reject: b, promise: c };
    });
/**
 * Resolve a promise when an event is triggered
 * @param target the target on which the event is attached
 * @param event the event to listen to
 * @returns a promise that resolves when the event is triggered
 */
async function when(target, event) {
    const { promise, resolve } = Promise.withResolvers();
    target.addEventListener(event, () => {
        resolve();
    }, {
        once: true,
    });
    return promise;
}
/**
 * Resolve a promise when all events are triggered
 * @param target the target on which the event is attached
 * @param events the events to listen to
 * @returns a promise that resolves when all events are triggered
 */
async function whenAllSync(target, events) {
    const promises = events.map((event) => when(target, event));
    return await Promise.all(promises);
}
/**
 * Async version of whenAll
 * @param target the target on which the event is attached
 * @param events the events to listen to
 * @param callback the callback to call when all events are triggered
 */
function whenAll(target, events, callback) {
    whenAllSync(target, events).then(() => {
        callback();
    });
}
export { when, whenAll, whenAllSync };
