/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, PostEffect } from '@a3d-viewer/renderer-types';
import { Effect, PassPostProcess, PostProcess, PostProcessRenderEffect, PostProcessRenderPipeline, Texture, } from '@babylonjs/core';
Effect.ShadersStore.finalVertexShader = `
  precision highp float;
  attribute vec3 position;
  uniform mat4 worldViewProjection;

  void main() {
      gl_Position = worldViewProjection * vec4(position, 1.0);
  }`;
Effect.ShadersStore.finalFragmentShader = `
    #ifdef GL_ES
    precision mediump float;
    #endif

    varying vec2 vUV;
            
    uniform sampler2D depthTexture;

    void main(void)
    {
        vec4 depth = texture2D(depthTexture, vUV);

        float x = depth.r;
        gl_FragColor = vec4(x, x, x, 1.);
    }
`;
const DEPTH_PIPELINE = 'depth-pipeline';
class DepthPost extends PostEffect {
    constructor(scene, renderer) {
        super();
        this.type = POST_EFFECTS.DEPTH;
        this.scene = scene;
        this.depthRenderer = renderer;
        this.pipeline = new PostProcessRenderPipeline(this.scene.getEngine(), DEPTH_PIPELINE);
        var imagePass = new PassPostProcess('imagePass', 1.0, null, Texture.NEAREST_SAMPLINGMODE, this.scene.getEngine());
        var depthTexture = this.depthRenderer.getDepthMap();
        var finalPass = new PostProcess('Depth shader', 'final', null, ['depthTexture'], 1.0, null, Texture.BILINEAR_SAMPLINGMODE, this.scene.getEngine());
        finalPass.onApply = (effect) => {
            effect.setTexture('depthTexture', depthTexture);
        };
        var renderPasses = new PostProcessRenderEffect(this.scene.getEngine(), 'renderPasses', function () {
            return [imagePass, finalPass];
        });
        this.pipeline.addEffect(renderPasses);
        this.scene.postProcessRenderPipelineManager.addPipeline(this.pipeline);
        this.scene.postProcessRenderPipelineManager.attachCamerasToRenderPipeline(DEPTH_PIPELINE, this.scene.activeCamera);
        this.process();
    }
    suspend() { }
    process() {
        console.log('DepthPost processed');
    }
    dispose() {
        console.debug('Default dispose');
        this.scene.postProcessRenderPipelineManager.detachCamerasFromRenderPipeline(DEPTH_PIPELINE, this.scene.activeCamera);
        this.scene.postProcessRenderPipelineManager.removePipeline(DEPTH_PIPELINE);
        this.pipeline.dispose();
    }
}
export default DepthPost;
