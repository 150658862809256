/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
  Flex,
  Heading,
  Item,
  Key,
  Picker,
  Slider,
  Switch,
} from '@adobe/react-spectrum'
import { ColorField } from '@react-spectrum/color'
import { Color } from '@react-types/color'
import { useAtom } from 'jotai'
import ColorBox from '../components/ColorBox'
import { FormItem } from '../components/FormItem'
import { DefaultViewerConfig, EnvSrc, viewerAtom } from '../config'

function EnvironmentPanel() {
  const [atom, setAtom] = useAtom(viewerAtom)

  const handleEnvSelected = (key: Key) => {
    setAtom({ ...atom, envSrc: key as EnvSrc })
  }

  const handleEnvRotationChange = (value: number) => {
    setAtom({ ...atom, envRotation: value })
  }

  const handleLevelChange = (value: number) => {
    setAtom({ ...atom, envIntensity: value / 100 })
  }

  const handleBlurLevelChange = (value: number) => {
    setAtom({ ...atom, envBlurLevel: value / 100 })
  }

  const handleEnvVisibilityChange = (value: boolean) => {
    setAtom({ ...atom, envVisibility: value })
  }

  const handleColorChange = (color: Color | null) => {
    if (color === null) return
    handleChooseColor(color.toString('hex'))
  }

  const handleChooseColor = (color: string) => {
    setAtom({ ...atom, color: color })
  }

  return (
    <>
      <Heading level={1}>Lighting</Heading>
      <Heading level={2}>Environment map</Heading>
      <FormItem>
        <Picker
          label="Source"
          onSelectionChange={handleEnvSelected}
          selectedKey={atom.envSrc}
          width={'100%'}
        >
          <Item key={EnvSrc.studioWhiteEnv}>Studio white env</Item>
          <Item key={EnvSrc.studioWhiteHdr}>Studio white hdr</Item>
          <Item key={EnvSrc.studioHigh}>Studio.hdr</Item>
          <Item key={EnvSrc.bridgeEnv}>Bridge.env</Item>
          <Item key={EnvSrc.plazaEnv}>Plaza.env</Item>
          <Item key={EnvSrc.roomHdr}>Room.hdr</Item>
          <Item key={EnvSrc.workshopEnv}>Workshop.env</Item>
          <Item key={EnvSrc.workshopHdr}>Workshop.hdr</Item>
          <Item key={EnvSrc.exteriorEnv}>Exterior.env</Item>
          <Item key={EnvSrc.exteriorHdr}>Exterior.hdr</Item>
        </Picker>
      </FormItem>
      <FormItem>
        <Slider
          label="Intensity (%)"
          defaultValue={DefaultViewerConfig.envIntensity * 100}
          value={atom.envIntensity * 100}
          step={1}
          // value of 0 is not allowed
          minValue={1}
          maxValue={200}
          onChange={handleLevelChange}
          width={'100%'}
        />
      </FormItem>
      <FormItem>
        <Slider
          label="Rotation"
          defaultValue={DefaultViewerConfig.envRotation}
          value={atom.envRotation}
          step={0.01}
          minValue={0.0}
          maxValue={Math.PI * 2}
          onChange={handleEnvRotationChange}
          showValueLabel={false}
          width={'100%'}
        />
      </FormItem>

      <Heading level={2}>Background</Heading>
      <FormItem>
        <Switch
          isSelected={atom.envVisibility}
          onChange={handleEnvVisibilityChange}
        >
          Env. map as background
        </Switch>
      </FormItem>
      {atom.envVisibility && (
        <FormItem>
          <Slider
            label="Blur Intensity (%)"
            defaultValue={DefaultViewerConfig.envBlurLevel * 100}
            value={atom.envBlurLevel * 100}
            step={1}
            minValue={0}
            maxValue={100}
            onChange={handleBlurLevelChange}
            width={'100%'}
          />
        </FormItem>
      )}
      {!atom.envVisibility && (
        <>
          <Heading level={4}>Color</Heading>
          <FormItem>
            <Flex direction="row" gap="size-10">
              <ColorBox color="#F3F3F3" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#d3d3d3" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#66666f" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#000000" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#FFFFFF" onClick={handleChooseColor}></ColorBox>
            </Flex>
          </FormItem>
          <FormItem>
            <Flex direction="row" gap="size-10">
              <ColorBox color="#DEE4F2" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#DAF1F0" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#EEEDD8" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#F5E3E0" onClick={handleChooseColor}></ColorBox>
              <ColorBox color="#EEE1F4" onClick={handleChooseColor}></ColorBox>
            </Flex>
          </FormItem>
          <FormItem>
            <Flex direction="row" gap="size-10" alignItems="end">
              <ColorField
                label="Background color"
                value={atom.color}
                onChange={handleColorChange}
              />
              {atom.color && <ColorBox color={atom.color} />}
            </Flex>
          </FormItem>
        </>
      )}
    </>
  )
}

export { EnvironmentPanel }
