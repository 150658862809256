/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { Item, ActionGroup, Key } from '@adobe/react-spectrum'
import { HTMLAttributes } from 'react'

export enum Quality {
  Low = 0.25,
  Medium = 0.5,
  High = 0.75,
  Extreme = 1,
}

type RenderQualityProps = {
  selected?: Quality
  onQualityChange: (quality: Quality) => void
}

export default function RenderQuality({
  selected,
  onQualityChange,
}: RenderQualityProps & HTMLAttributes<HTMLButtonElement>) {
  return (
    <ActionGroup
      selectionMode="single"
      onAction={(key: Key) => {
        console.log('key', key)
        onQualityChange(key as Quality)
      }}
      {...(selected && { selectedKeys: [selected.toString()] })}
    >
      <Item key={Quality.Low}>25%</Item>
      <Item key={Quality.Medium}>50%</Item>
      <Item key={Quality.High}>75%</Item>
      <Item key={Quality.Extreme}>100%</Item>
    </ActionGroup>
  )
}
