/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
export var HotspotEvents;
(function (HotspotEvents) {
    // Event dispatched when a hotspot has been removed
    HotspotEvents["HOTSPOT_REMOVED"] = "a3d-hotspot-removed";
    // Event to dispatched by a hot spot to request its deletion
    HotspotEvents["HOTSPOT_DELETE"] = "a3d-hotspot-delete";
    // Event dispatched by a a3d-hotspot to the viewer to request its deletion
    HotspotEvents["HOTSPOT_DELETE_CONTAINER"] = "a3d-hotspot-delete-container";
    // Event dispatched when a hotspot state has changed
    HotspotEvents["HOTSPOT_STATE_CHANGE"] = "a3d-hotspot-state-change";
})(HotspotEvents || (HotspotEvents = {}));
export class Hotspot {
    constructor(id) {
        this.width = 1;
        this.height = 1;
        this.private = () => { };
        this.listener = this.onHotspotRemovedEvent.bind(this);
        const element = document.querySelector('a3d-hotspot#' + id);
        this.element = element;
        this.element.addEventListener(HotspotEvents.HOTSPOT_REMOVED, this.listener);
    }
    setPosition(x, y, z) {
        this.element.setAttribute('position', `${x} ${y} ${z}`);
    }
    move(x, y) {
        const px = x - this.width / 2;
        const py = y - this.height;
        // (use 0z translate to force GPU rendering)
        this.element.style.transform = `translate3D(${px}px, ${py}px, 0)`;
    }
    setVisibility(visible) {
        this.element.setVisibility(visible);
    }
    getHtmlElement() {
        return this.element;
    }
    remove() {
        if (!this.element) {
            return;
        }
        this.element.removeEventListener(HotspotEvents.HOTSPOT_REMOVED, this.listener);
        this.element.remove();
    }
    onHotspotRemovedEvent() {
        this.remove();
    }
}
