/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import classNames from 'classnames'
import { HTMLAttributes } from 'react'

/**
 * The main content layout.
 * Made to contain every main content which should not include Header and Footer
 */
function MainContent({ children, className }: HTMLAttributes<HTMLDivElement>) {
  return <main className={classNames(`flex`, className)}>{children}</main>
}

export default MainContent
