/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import { A3dViewer } from '@a3d-viewer/viewer'
import { useEffect, useRef, useState } from 'react'
import { MeshType } from '../App'
import ErrorDialog from '../ErrorDialog'
import DropZone from './DropZone'

import { MeshLoadingError } from '@a3d-viewer/core'
import { useAtom } from 'jotai'
import { DefaultViewerConfig, viewerAtom } from '../config'
import useReadQueryParams from '../hooks/useReadQueryParams'
import ViewerWC from './ViewerWC'

type ViewerProps = {
  onDomChange?: (viewerElement: A3dViewer) => void
  onStateChange?: (state: string) => void
}

export default function Viewer({ onDomChange, onStateChange }: ViewerProps) {
  const [viewer, setViewer] = useAtom(viewerAtom)
  const [mesh, setMesh] = useState<MeshType | null>(null)
  const viewerMainContainerRef = useRef<HTMLDivElement>(null)
  const [error, setError] = useState<boolean>(false)
  const { modelUrl } = useReadQueryParams()

  const handleDrop = ({ fileAsDataUrl, extension }: MeshType) => {
    setMesh({ fileAsDataUrl, extension })
  }

  useEffect(() => {
    if (modelUrl) {
      setMesh({ fileAsDataUrl: modelUrl })
    }
  }, [modelUrl])

  const handleError = (e: Event) => {
    const { detail } = e as CustomEvent

    switch (detail.name) {
      case MeshLoadingError.name:
        setViewer(DefaultViewerConfig)
        setMesh(null)
        setError(true)
        break
    }
  }

  const toggleFullscreen = () => {
    if (viewer.fullscreen) {
      setViewer({
        ...viewer,
        fullscreen: false,
      })
      document.exitFullscreen()
    } else {
      setViewer({
        ...viewer,
        fullscreen: true,
      })
      viewerMainContainerRef.current?.requestFullscreen()
    }
  }

  return (
    <div
      className="relative h-[80svh] sm:h-auto sm:aspect-video sm:max-h-[70vh]"
      ref={viewerMainContainerRef}
    >
      <DropZone onDrop={handleDrop} showDraggingText={mesh === null}>
        <ViewerWC
          key={mesh?.fileAsDataUrl}
          mesh={mesh}
          toggleFullscreen={toggleFullscreen}
          onDomChange={onDomChange}
          onStateChange={onStateChange}
          onError={handleError}
        />
      </DropZone>

      <ErrorDialog isOpen={error} onDismiss={() => setError(false)} />
    </div>
  )
}
