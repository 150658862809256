/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
/**
 * CameraManager is responsible for managing cameras in the scene.
 * It keeps track of the camera used for the viewport.
 */
export class CameraManager {
    constructor(renderer) {
        this.renderer = renderer;
    }
    setActiveCamera(camera) {
        if (!camera) {
            return;
        }
        // Camera from imported meshes are condisered locked (non-controllable).
        // When a camera from a mesh is selected we directly match the viewport camera to the selected camera.
        // Then, when the camera is dragged the viewport camera is already in the right position.
        if (this.viewportCamera && !this.isViewportCamera(camera)) {
            // Match the min/max Z of the viewport camera to the selected camera
            // This is to be sure the camera is not clipping the scene if the glb is not correctly built
            const minMaxZ = this.renderer.getActiveCameraMinMaxZ();
            if (minMaxZ) {
                this.renderer.setCameraMinMaxZ(camera, minMaxZ);
            }
            this.renderer.copyCamera(camera, this.viewportCamera);
        }
        this.renderer.setActiveCamera(camera);
    }
    isViewportCamera(camera) {
        return this.viewportCamera?.uuid === camera.uuid;
    }
    hasViewportCamera() {
        return this.getViewportCamera() !== undefined;
    }
    setViewportCamera(camera) {
        if (!this.viewportCamera) {
            this.viewportCamera = camera;
            this.renderer.enableCamera(camera);
            this.renderer.setActiveCamera(camera);
        }
    }
    getViewportCamera() {
        return this.viewportCamera;
    }
}
