/***************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/
import { POST_EFFECTS, PostEffect } from '@a3d-viewer/renderer-types';
import { DefaultRenderingPipeline } from '@babylonjs/core/PostProcesses/RenderPipeline/Pipelines/defaultRenderingPipeline';
import { BabylonSetup } from '../BabylonSetup';
class DefaultPost extends PostEffect {
    constructor(scene) {
        super();
        this.type = POST_EFFECTS.ANTIALIASING;
        this.scene = scene;
        this.pipeline = new DefaultRenderingPipeline('defaultPipeline', true, this.scene, [this.scene.activeCamera]);
        // TODO : Add a way to enable/disable the different post process in the default one
        this.pipeline.samples = BabylonSetup.DefaultPipelineSamples;
        this.pipeline.fxaaEnabled = BabylonSetup.DefaultPipelineFxaaEnabled;
        this.pipeline.imageProcessingEnabled =
            BabylonSetup.DefaultPipelineImageProcessingEnabled;
        this.process();
    }
    suspend() { }
    process() {
        console.log('Default Post processed');
    }
    dispose() {
        console.debug('Default dispose');
        this.pipeline.dispose();
    }
}
export default DefaultPost;
