/*! **************************************************************************
 *
 * Copyright 2024 Adobe
 * All Rights Reserved.
 *
 * NOTICE: All information contained herein is, and remains
 * the property of Adobe and its suppliers, if any. The intellectual
 * and technical concepts contained herein are proprietary to Adobe
 * and its suppliers and are protected by all applicable intellectual
 * property laws, including trade secret and copyright laws.
 * Dissemination of this information or reproduction of this material
 * is strictly forbidden unless prior written permission is obtained
 * from Adobe.
 ***************************************************************************/

import {
  ActionButton,
  defaultTheme,
  Header,
  ProgressCircle,
  Provider,
  TextField,
} from '@adobe/react-spectrum'
import { ToastContainer, ToastQueue } from '@react-spectrum/toast'
import MoonIcon from '@spectrum-icons/workflow/Moon'
import { useAtom } from 'jotai'
import { useEffect, useMemo, useRef, useState } from 'react'

import '@a3d-ui/button'
import '@a3d-ui/info'
import '@a3d-ui/separator'
import '@a3d-ui/toolbar'
import '@a3d-viewer/assets'
import '@a3d-viewer/camera'
import '@a3d-viewer/core'
import '@a3d-viewer/environment'
import '@a3d-viewer/hotspot'
import '@a3d-viewer/light'
import '@a3d-viewer/loading-screen'
import '@a3d-viewer/mesh'
import { A3dMesh } from '@a3d-viewer/mesh'
import '@a3d-viewer/node'
import '@a3d-viewer/scene'
import '@a3d-viewer/transform'
import '@a3d-viewer/viewer'
import { A3dViewer } from '@a3d-viewer/viewer'

import { matrixTransform, Transform } from '@a3d-lib/camera-transform'
import { ViewerEvents } from '@a3d-viewer/core'
import { SchemaSpace } from '../../clients/substance3dapi/schema'
import { MeshType } from '../App'
import substanceLogo from '../assets/substance.svg'
import DropZone from '../components/DropZone'
import { FormItem } from '../components/FormItem'
import { Theme, themeAtom, viewerAtom } from '../config'
import Label from './Label'
import RenderCard from './RenderCard'
import RenderQuality, { Quality } from './RenderQuality'
import S3DApi, { SERVER_URL } from './S3DApi'

function Render() {
  const [viewer] = useAtom(viewerAtom)
  const [theme, setTheme] = useAtom(themeAtom)
  const [mesh, setMesh] = useState<MeshType | null>(null)
  const meshRef = useRef<A3dMesh>(null)
  const viewerRef = useRef<A3dViewer>(null)
  const [renderedList, setRenderedList] = useState<string[]>([])
  const [state, setState] = useState<string | undefined>()
  const [rendering, setRendering] = useState(false)
  const [serverUrl, setServerUrl] = useState(
    'https://api-internal-dev.cw.substance3d.io'
  )
  const [quality, setQuality] = useState(Quality.Medium)
  let api = new S3DApi()

  useEffect(() => {
    if (
      theme === Theme.dark ||
      (!('theme' in localStorage) &&
        window.matchMedia('(prefers-color-scheme: dark)').matches)
    ) {
      localStorage.theme = Theme.dark
      document.documentElement.classList.add('dark')
    } else {
      localStorage.theme = Theme.light
      document.documentElement.classList.remove('dark')
    }
  }, [theme])

  useEffect(() => {
    api.serverUrl = serverUrl
  }, [serverUrl])

  const modelUrl = useMemo(() => {
    const search = window.location.search
    const queryParams = new URLSearchParams(search)
    return queryParams.get('modelUrl')
  }, [window.location.search])

  const color = useMemo(() => {
    return theme === Theme.dark ? '#3e3e3e' : '#F3F3F3'
  }, [viewer.color, theme])

  const viewerUpdated = async (e: Event) => {
    const { detail } = e as CustomEvent

    const json = JSON.stringify(detail, null, 2)

    setState(json)
  }

  useEffect(() => {
    if (modelUrl) {
      setMesh({ fileAsDataUrl: modelUrl })
    }
  }, [modelUrl])

  useEffect(() => {
    if (!mesh || !viewerRef.current) return undefined
    const viewerEle = viewerRef.current

    viewerEle.addEventListener(ViewerEvents.VIEWER_UPDATED, viewerUpdated)

    return () => {
      viewerEle.removeEventListener(ViewerEvents.VIEWER_UPDATED, viewerUpdated)
    }
  }, [mesh, viewerRef.current])

  async function onGenerate() {
    if (!state || !viewerRef.current) {
      return
    }
    const viewerEle = viewerRef.current
    if (!viewerEle) {
      return
    }
    const viewerState = JSON.parse(state)

    try {
      setRendering(true)
      const fileUploadResult = await api.uploadFile(mesh?.file as File)

      const transform: Transform = matrixTransform(viewerState.camera.matrix)

      if (!transform.matrix) {
        return
      }

      const renderModel = await api.renderModel(
        fileUploadResult.data as SchemaSpace,
        Math.floor(viewerEle.clientWidth * quality),
        Math.floor(viewerEle.clientHeight * quality),
        viewerState.camera.matrix
      )
      const resultUrl = renderModel.files?.[0].url
      setRenderedList([resultUrl!, ...renderedList])
    } catch (e) {
      console.error(e)
      ToastQueue.negative('Rendering failed.')
    } finally {
      setRendering(false)
    }
  }

  const handleDrop = ({ fileAsDataUrl, extension, file }: MeshType) => {
    setMesh({ file, fileAsDataUrl, extension })
  }

  return (
    <Provider
      theme={defaultTheme}
      colorScheme={theme}
      id="provider"
      UNSAFE_className={`flex flex-1 flex-col ${theme === 'light' ? 'bg-white' : 'bg-black'}`}
      scale="medium"
    >
      <ToastContainer />
      <Header
        height={'56px'}
        UNSAFE_className="flex flex-row justify-between items-center w-full box-border px-4"
      >
        <div className="flex items-center">
          <img src={substanceLogo} className="h-8 mr-3" alt="Substance logo" />
          <h2
            className="font-bold text-base"
            style={{ color: 'var(--spectrum-gray-900)' }}
          >
            Adobe S3DS Api Render - Demo
          </h2>
        </div>
        <div>
          <ActionButton
            onPress={() => {
              setTheme(theme === Theme.light ? Theme.dark : Theme.light)
            }}
          >
            <MoonIcon />
          </ActionButton>
        </div>
      </Header>
      <div className="fixed top-[56px] bottom-0 left-0 w-[20px]"></div>
      <div className="fixed top-[56px] bottom-0 right-0 w-[320px] overflow-y-auto">
        <div className="m-4 flex flex-col ">
          <h3>Render Settings</h3>
          <FormItem>
            <Label label="Rendering size" />
            <RenderQuality
              onQualityChange={setQuality}
              selected={quality}
            ></RenderQuality>
          </FormItem>
          <FormItem>
            <TextField
              label="API Server Url"
              width={'100%'}
              defaultValue={SERVER_URL}
              onChange={setServerUrl}
            ></TextField>
          </FormItem>
          <ActionButton onPress={onGenerate} isDisabled={!mesh || rendering}>
            {rendering ? (
              <ProgressCircle aria-label="Loading…" size="S" isIndeterminate />
            ) : (
              'Render Scene'
            )}
          </ActionButton>
          {renderedList.length > 0 && (
            <div className="flex flex-col mt-4">
              <h3>Rendered Scenes</h3>
              {renderedList.map((url, idx) => (
                <RenderCard key={idx} url={url} className={'my-2'} />
              ))}
            </div>
          )}
        </div>
      </div>
      <div className="rounded-t-2xl absolute top-[56px] bottom-0 left-[20px] right-[320px] overflow-hidden flex">
        <DropZone onDrop={handleDrop} showDraggingText={mesh === null}>
          {mesh && (
            <a3d-viewer
              ref={viewerRef}
              {...(viewer.hotspot.addHotspotOnNextClick && {
                class: 'cursor-crosshair',
              })}
            >
              <a3d-scene id="test" color={color}>
                <a3d-camera-perspective
                  yFov={1}
                  limit-disabled="true"
                  active
                ></a3d-camera-perspective>
                <a3d-environment src={viewer.envSrc}></a3d-environment>
                <a3d-node>
                  <a3d-transform
                    position={`${viewer.mesh.translation.x} ${viewer.mesh.translation.y} ${viewer.mesh.translation.z}`}
                    rotation={`${viewer.mesh.rotation.x} ${viewer.mesh.rotation.y} ${viewer.mesh.rotation.z}`}
                  ></a3d-transform>
                  <a3d-mesh
                    id={'render-id'}
                    ref={meshRef}
                    src={mesh?.fileAsDataUrl}
                    loader={mesh?.extension}
                  ></a3d-mesh>
                </a3d-node>
              </a3d-scene>
            </a3d-viewer>
          )}
        </DropZone>
      </div>
    </Provider>
  )
}

export default Render
